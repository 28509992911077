@use '~Sass/mixins';

.container {
  width: 500px;
  max-width: 100%;
  padding: 50px;
  background-color: #fff;
  border-radius: var(--border-radius-large);
}

.header {
  position: relative;
  padding-bottom: 30px;
}

.title {
  @include mixins.title('h2');
  text-align: center;
  line-height: 1.35;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.intro {
  @include mixins.title(h5);
  margin-bottom: 45px;
  text-align: center;
  line-height: 1.5;
  font-weight: 400;
}

.auth_link {
  display: flex;
  justify-content: center;
  width: 100%;
}

.button {
  @include mixins.button('dark-green', 'large');
  flex-shrink: 0;
  font-weight: 400;
  margin-left: 10px;

  svg {
    height: 10px;
    width: 10px;
    margin-left: 10px;
    margin-right: 0;
  }

  @media all and (max-width: 600px) {
    display: none;
  }
}

.hint {
  padding: 30px 20px;
  border-radius: var(--border-radius-large);
  margin-top: 50px;
  text-align: center;
  line-height: 1.5;
  color: var(--color-faded-text);
  background-color: var(--color-green-grey-dark);

  a {
    font-weight: 600;
    text-decoration: underline;
    color: var(--color-dark-green);
  }
}

.copy_text {
  width: 100%;
  max-width: 360px;
}
