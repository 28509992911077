@use '~Sass/mixins';

.container {
  width: 100%;
  max-width: var(--layout-width);
  container-type: inline-size;
}

.inner {
  padding: 50px;
  margin-top: 50px;
  background-color: var(--color-green-grey);
  border-radius: var(--border-radius-xlarge);
  line-height: 1.25;

  a {
    text-decoration: underline;
  }

  @container (max-width: 600px) {
    margin-top: 20px;
  }
}
