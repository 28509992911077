@use '~Sass/mixins';

.container {
  @include mixins.focusable('light', 'focus-within');
  display: grid;
  grid-template-columns: repeat(var(--num-columns), 1fr);
  gap: 10px;
}

.block {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90px;
  font-size: 18px;
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    user-select: none;
  }

  .small & {
    height: 80px;
  }

  .large & {
    height: 130px;
  }

  &:hover .block__inner {
    background-color: var(--color-green-grey-darker);
  }

  &.selected .block__inner {
    background-color: var(--color-dark-green);
    cursor: default;
    color: #fff;

    svg {
      path {
        fill: #fff;
      }
    }
  }

  :global(.keys) &:focus-within .block__inner {
    box-shadow: 0 0 0 1px var(--color-text);
  }
}

.block__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 30px;
  background-color: var(--color-green-grey-dark);
  border-radius: var(--border-radius-xlarge);
  transition: all 0.3s var(--ease);

  .small & {
    padding: 0 20px;
  }

  .disabled & {
    opacity: 0.65;
  }

  > svg {
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: calc(100% - 55px);

    path {
      fill: var(--color-dark-green);
    }
  }
}

.input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.badge {
  @include mixins.label('small');
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 7px;
  right: 7px;
  padding: 5px 7px;
  border-radius: 100px;
  background-color: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(3px);
  box-shadow: var(--shadow1);
  color: var(--color-text);

  svg {
    height: 15px;
    width: 15px;
    margin-right: 5px;

    path {
      fill: var(--color-text);
    }
  }
}

.selected .badge {
  background-color: var(--color-algae);
}
