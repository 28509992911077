@use 'Sass/mixins';

.container {
  position: relative;
  z-index: 203;
  height: calc(30px + 30px + 60px);
  padding: 30px 30px 0;
}

.inner {
  position: sticky;
  top: 15px;
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}

.logo {
  height: 30px;
  width: 30px;
}

.menu_toggle {
  position: relative;
  z-index: 13;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  margin-left: auto;

  &::before {
    content: '';
    height: 80px;
    width: 80px;
    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
  }
}

.menu_icon {
  position: relative;
  height: 1px;
  width: 20px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 2px;
    width: 20px;
    background-color: var(--color-text);
    transform-origin: center center;
    transition: transform 0.45s ease;
  }

  &::before {
    margin-top: -3px;
  }

  &::after {
    margin-top: 3px;
  }

  .menu_toggle_open & {
    &::before,
    &::after {
      margin: 0;
    }

    &::before {
      transform: rotate(-135deg);
    }
    &::after {
      transform: rotate(135deg);
    }
  }
}

.menu {
  position: absolute;
  z-index: 12;
  top: -10px;
  right: -10px;
  width: 200px;
  padding-top: 40px;
  border-radius: var(--border-radius-large);
  background-color: #fff;
  transform: translate(10px, -10px);
  transition: all 0.1s var(--ease);
  will-change: clip-path;
  pointer-events: none;
  box-shadow: var(--shadow2);
  opacity: 0;

  .open & {
    transform: none;
    opacity: 1;
    transition: all 0.4s var(--ease-out-back);
    pointer-events: all;
  }
}

.account {
  display: flex;
  height: 70px;
  padding: 0 20px;
  width: 100%;
  border-radius: 0;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  line-height: 1;

  svg {
    flex-shrink: 0;
    margin: 2px 0 0 10px;
  }
}

.avatar {
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: var(--color-blue);
  text-transform: uppercase;
  font-size: 15px;
  color: #fff;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.account__email {
  @include mixins.clippedText;
}

.nav {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  border-top: 2px solid var(--color-borders);
}

.nav__item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 14px 30px;

  &.current {
    background-color: var(--color-green-grey);
  }

  svg {
    height: 18px;
    width: 18px;
    margin-right: 12px;

    path {
      fill: var(--color-text);
    }
  }
}

.nav__button {
  margin-left: 30px;
}

.account_nav {
  margin-top: 20px;
  padding: 20px 0;
  border-top: 2px solid var(--color-borders);
}

.mask {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  pointer-events: none;
  transform: translateY(-100%);
  opacity: 0;
  transition: opacity 0.45s var(--ease);

  &.open {
    pointer-events: all;
    opacity: 1;
    transform: none;
  }
}
