@use '~Sass/mixins';

.form {
  display: block;
  overflow: hidden;
}

.container {
  display: block;
  height: 120px;
  width: 100%;
  padding: 4px 8px;
  box-shadow:
    0 0 0 1px var(--color-input-border),
    0 0 0 2px var(--color-input-border-shadow);
  border-radius: var(--border-radius-small);
  cursor: text;
  overflow: auto;
  transition: box-shadow 0.3s var(--ease);
  background-color: #fff;

  &:hover {
    box-shadow:
      0 0 0 1px #d5d5d5,
      0 0 0 1px #d5d5d5;
  }

  &.focused {
    box-shadow:
      0 0 0 1px #326647,
      0 0 0 4px rgba(0, 0, 0, 0.035);
  }

  &.large {
    height: 200px;
  }

  &.small {
    height: auto;
  }

  @media all and (max-width: 600px) {
    height: auto;
    min-height: 44px;
    max-height: 80px;
    padding: 8px 8px 0;
  }
}

.input {
  display: inline-block;
  flex-grow: 1;
  flex-shrink: 1;
  width: 150px;
  height: calc(var(--input-height) - 10px);
  padding: 0;
  margin-bottom: 2px;
  line-height: 36px;
  outline: none;
  border: none;
  vertical-align: middle;
  -webkit-appearance: none;
  box-shadow: none;
  text-indent: 0;
  font-size: 14px;

  &:disabled {
    background: transparent;
  }

  &:focus {
    border: none;
  }

  &::-ms-clear {
    display: none;
  }

  @media all and (max-width: 600px) {
    padding: 0 0 10px 0;
    margin-bottom: 0;
  }
}

.tag {
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  padding: 6px 6px 6px 12px;
  margin: 4px 7px 4px 0;
  background-color: var(--color-green-grey-dark);
  border-radius: var(--border-radius-small);
  font-size: 14px;

  @media all and (max-width: 600px) {
    margin: 0px 7px 8px 0;
  }
}

.tag__remove {
  @include mixins.focusable;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 24px;
  width: 24px;
  margin: 2px 0 0 2px;
  cursor: pointer;
  border-radius: var(--border-radius-small);
  transition: all 0.2s var(--ease);

  &:hover {
    background-color: var(--color-terracotta);

    svg {
      opacity: 1;

      path {
        fill: #fff;
      }
    }
  }

  svg {
    height: 10px;
    width: 10px;
    opacity: 0.65;
    transition: all 0.2s var(--ease);

    path {
      transition: all 0.2s var(--ease);
      fill: var(--color-text);
    }
  }
}
