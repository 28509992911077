@use '~Sass/mixins';

.container {
  width: 100%;
  max-width: var(--layout-width);
}

.inner {
  display: grid;
  gap: 50px 0;
  margin-top: 30px;

  @media all and (max-width: 1000px) {
    gap: 40px;
  }

  @media all and (max-width: 600px) {
    margin-top: 20px;
  }
}

.label {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 600;

  @media all and (max-width: 600px) {
    margin-bottom: 10px;
  }
}

.text {
  display: flex;
  align-items: center;
}

.login_providers {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.login_provider {
  display: flex;
  align-items: center;
  border-radius: var(--border-radius-large);
  line-height: 16px;
}

.login_provider__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  margin: -1px 7px -3px 0;

  svg {
    height: 100%;
    width: 100%;
  }
}

.login_provider__icon_email {
  height: 21px;
  width: 21px;
}

.login_provider__email {
  padding-left: 3px;
}

.text__password {
  height: 16px;
  padding-top: 4px;
}

.info {
  display: flex;
  padding: 30px;
  border-radius: var(--border-radius-large);
  border: 2px solid var(--color-borders);

  > div + div {
    margin-left: 100px;
  }

  @media all and (max-width: 1100px) {
    > div + div {
      margin-left: 60px;
    }
  }

  @media all and (max-width: 900px) {
    flex-direction: column;

    > div + div {
      margin-left: 0;
      margin-top: 30px;
    }
  }
}

.edit_button {
  @include mixins.focusable;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  margin: -14px 0 0 8px;
  transform: translateY(7px);
  background-color: transparent;
  border-radius: var(--border-radius-small);
  transition: all 0.3s var(--ease);

  &:hover,
  :global(.keys) &:focus {
    background-color: var(--color-algae);
  }

  svg {
    height: 16px;
    width: 16px;
  }
}

.plan_loader {
  @include mixins.content-placeholder;
  height: 100px;
  padding: 30px;
  background-color: #fff;
  border-radius: var(--border-radius-large);
  box-shadow: var(--shadow1);
}

.plan {
  min-width: 0;
}

.plan__details {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  border-radius: var(--border-radius-large);
  background-color: #fff;
  box-shadow: var(--shadow1);

  @media all and (max-width: 1080px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px 30px;
  }

  @media all and (max-width: 700px) {
    gap: 30px 0px;
  }

  @media all and (max-width: 700px) and (min-width: 601px) {
    grid-template-columns: 150px 1fr;
  }

  @media all and (max-width: 400px) {
    grid-template-columns: 150px 1fr;
  }

  @media all and (max-width: 380px) {
    grid-template-columns: 125px 1fr;
  }
}

.plan__detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .label {
    font-size: 16px;
  }
}

.plan__text {
  white-space: nowrap;

  @media all and (max-width: 380px) {
    font-size: 0.9em;
  }
}

.plan__button {
  white-space: nowrap;

  @media all and (max-width: 1080px) {
    margin-left: 0;
    justify-self: left;
  }
}

.add_card_button {
  @include mixins.button;

  svg {
    height: 26px;
    width: 26px;
    transform: translateY(-2px);
    opacity: 0.75;
    transition: opacity 0.3s var(--ease);
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
}

.credit__text {
  padding: 20px;
  border-radius: var(--border-radius-large);
  border: 2px solid var(--color-borders);
  font-size: 20px;
}

.payment {
  min-width: 0;
}

.payment__header {
  display: flex;
  justify-content: space-between;
}

.add_card_link {
  @include mixins.focusable;
  position: relative;
  padding: 0 10px;
  margin-bottom: 15px;
  color: var(--color-text-light);
  font-size: 15px;
  font-weight: normal;
  transition: all 0.3s var(--ease);

  span {
    position: relative;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: calc(100% + 16px);
    width: 100%;
    transform: translate(-50%, -50%) scale(0.9);
    opacity: 0;
    border-radius: var(--border-radius-small);
    background-color: var(--color-algae);
    transition: all 0.3s var(--ease);
    transform-origin: center center;
  }

  &:hover {
    color: var(--color-text);

    &::after {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
}

.payment_method {
  position: relative;
  display: flex;
  align-items: center;
  padding: 30px;
  background-color: #fff;
  border-radius: var(--border-radius-large);
  box-shadow: var(--shadow1);

  @media all and (max-width: 600px) {
    padding: 20px;
  }

  @media all and (max-width: 500px) {
    flex-direction: column;
  }
}

.payment_method + .payment_method {
  margin-top: 15px;
}

.payment_method__logo {
  margin-top: 1px;
  height: 30px;
  width: 50px;
  flex-shrink: 0;
  align-self: flex-start;
  background-size: contain;
  background-position: center center;
  box-shadow: var(--shadow0);
  border-radius: 5px;

  @media all and (max-width: 700px) {
    height: 24px;
    width: 40px;
  }

  .brand_visa & {
    background-image: url(~Images/credit-cards/visa.svg);
  }

  .brand_mastercard & {
    background-image: url(~Images/credit-cards/mastercard.svg);
  }

  .brand_amex & {
    background-image: url(~Images/credit-cards/amex.svg);
  }

  .brand_discover & {
    background-image: url(~Images/credit-cards/discover.svg);
  }

  .brand_diners & {
    background-image: url(~Images/credit-cards/diners-club.svg);
  }

  .brand_jcb & {
    background-image: url(~Images/credit-cards/jcb.svg);
  }

  .brand_unionpay & {
    background-image: url(~Images/credit-cards/unionpay.svg);
  }
}

.payment_method__text {
  max-width: 100%;
  min-width: 0;
  padding-left: 30px;
  align-self: flex-start;

  @media all and (max-width: 725px) and (min-width: 601px) {
    padding-left: 20px;
  }

  @media all and (max-width: 600px) {
    padding-left: 20px;
  }

  @media all and (max-width: 500px) {
    padding-left: 0;
    padding-top: 20px;
  }
}

.payment_method__name {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  font-size: 18px;
  line-height: 1;

  @media all and (max-width: 600px) {
    font-size: 16px;
  }
}

.payment_method__name_text {
  @include mixins.clippedText;
  align-self: center;
}

.payment_method__default {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-large);
  height: 20px;
  padding: 0 8px 1px;
  margin-left: 20px;
  background-color: var(--color-text-light);
  color: #fff;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.025em;

  @media all and (max-width: 600px) {
    height: 16px;
    padding: 0 5px 0;
    font-size: 10px;
  }

  @media all and (max-width: 500px) {
    position: absolute;
    top: 20px;
    right: 60px;
  }
}

.payment_method__details {
  color: #326647;
  font-size: 15px;

  @media all and (max-width: 600px) {
    font-size: 12px;
  }
}

.payment_method__right {
  margin-left: auto;
  flex-shrink: 0;
}

.payment_method__menu {
  margin-left: 20px;

  @media all and (max-width: 500px) {
    position: absolute;
    top: 5px;
    right: 10px;
  }
}

.payment_method__loader{
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 1;
  background-color: #fff;
  border-radius: var(--border-radius-large);
}

.tooltip_text {
  @include mixins.tooltip;
}

.tooltip_small {
  @include mixins.tooltip('small');
  text-align: center;
}

.receipts__footer {
  display: flex;
  justify-content: flex-start;
  margin-top: 40px;
}

.receipts__show_all {
  @include mixins.button('grey', 'medium');
}

.receipt {
  display: flex;
  height: 55px;
  border-bottom: 2px solid var(--color-borders);
  align-items: center;

  @media all and (max-width: 725px) and (min-width: 601px) {
    height: auto;
    padding: 15px 0;
  }

  @media all and (max-width: 600px) {
    height: 40px;
  }
}

.receipt__left {
  display: flex;
  align-items: center;

  @media all and (max-width: 725px) and (min-width: 601px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.receipt__right {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.receipt__card {
  margin-left: 40px;
  color: var(--color-text-light);

  @media all and (max-width: 900px) {
    margin-left: 20px;
  }

  @media all and (max-width: 725px) and (min-width: 601px) {
    margin-left: 0;
    margin-top: 5px;
  }

  @media all and (max-width: 475px) {
    display: none;
  }
}

.receipt__amount {
  padding-left: 20px;

  .refunded & {
    text-decoration: line-through;
    color: var(--color-text-light);
  }
}

.receipt__refund_label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-large);
  height: 20px;
  padding: 0 8px 1px;
  margin-left: 40px;
  border: 1px solid var(--color-blue);
  color: var(--color-blue);
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.025em;
  white-space: nowrap;

  @media all and (max-width: 900px) {
    height: 16px;
    margin-left: 20px;
    padding: 0 5px 0;
    font-size: 10px;
  }

  @media all and (max-width: 725px) and (min-width: 601px) {
    margin-left: 0;
    margin-top: 5px;
  }

  @media all and (max-width: 400px) {
    margin-left: 10px;
  }
}

.receipt__refund {
  margin-left: 20px;

  @media all and (max-width: 900px) {
    margin-left: 10px;
  }
}

.receipt__button {
  @include mixins.button('grey', 'small');
  margin-left: 10px;

  svg {
    height: 16px;
    width: 16px;

    path {
      fill: var(--color-text);
    }
  }

  @media all and (max-width: 1000px) {
    svg {
      margin-right: 0;
    }
    span {
      display: none;
    }
  }
}

.delete__inner {
  padding: 40px;
  border: 2px solid var(--color-borders);
  border-radius: var(--border-radius-large);
}

.delete__button {
  @include mixins.button('light-terracotta');
}
