@use 'Sass/mixins';

.preview {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 60px 60px;

  @media all and (max-width: 1100px) {
    padding: 0 20px 20px;
  }

  @media all and (max-width: 700px) {
    padding: 0;
  }
}

.preview__tabs {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  padding: 20px 0 60px;
}

.preview__tab {
  @include mixins.focusable;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  transition: background 0.3s var(--ease);
  background-color: rgba(244, 244, 240, 0.3);
  border-radius: var(--border-radius-small);

  &.current,
  &:hover {
    background-color: rgba(244, 244, 240, 1);

    svg {
      opacity: 1;
    }
  }

  svg {
    height: 24px;
    vertical-align: middle;
    opacity: 0.5;
    transition: opacity 0.3s var(--ease);

    path {
      fill: var(--color-text);
    }
  }
}

.preview__tab + .preview__tab {
  margin-left: 10px;
}

.preview__posts {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 0px;
  max-width: calc(var(--preview-width) + 0px);
  // background-color: var(--color-background);
}

.preview__posts_inner {
  display: grid;
  width: 100%;
  max-height: 100%;
  overflow: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0 !important;
  }

  img,
  video {
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: center center;
  }

  @media all and (max-width: 700px) {
    max-height: unset;
    max-width: 100%;
  }
}

.post {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  overflow: hidden;
  isolation: isolate;
  transition: all 0.3s var(--ease);
}

.video,
.photo {
  transition: all 0.3s var(--ease);
}

.is_previewing_loading_colors {
  .video,
  .photo {
    opacity: 0;
  }
}
