@use '~Sass/mixins';

.container {
  width: 450px;
  max-width: 100%;
  background-color: #fff;
  border-radius: var(--border-radius-large);
}

.header {
  padding: 40px;

  @media all and (max-width: 400px) {
    padding: 30px;
  }
}

.title {
  @include mixins.title('h2');
  display: flex;
  align-items: center;

  svg {
    height: 30px;
    margin-right: 15px;

    path {
      fill: var(--color-text-light);
    }
  }
}

.note {
  position: relative;
  display: flex;
  padding: 20px 40px;
  background-color: var(--color-green-grey);
  overflow: hidden;

  @media all and (max-width: 400px) {
    padding: 20px 30px;
  }
}

.note__icon {
  flex-shrink: 0;
  height: 40px;
  width: 27px;

  @media all and (max-width: 400px) {
    flex-shrink: 0;
    height: 30px;
    width: 20px;
  }
}

.note__title {
  font-size: 15px;
  margin-bottom: 7px;
  font-weight: 600;

  svg {
    height: 16px;
    vertical-align: middle;
    margin-top: -1px;
  }
}

.note__text {
  position: relative;
  z-index: 1;
  font-size: 14px;
  line-height: 1.35;
  color: var(--color-text-light);
}

.note__background {
  position: absolute;
  top: -10px;
  left: calc(100% - 165px);
  height: 650px;
  width: auto;
  transform: translateY(-50%);

  path {
    stroke: #ecece3;
    stroke-width: 4px;
    fill: none;
  }

  @media all and (max-width: 1000px) {
    display: none;
  }
}

.inner {
  padding: 40px;

  @media all and (max-width: 400px) {
    padding: 30px;
  }
}

.form_item {
  input {
    margin-top: 10px;
  }
}

.card_wrapper {
  display: flex;
  justify-content: stretch;
  align-items: center;
  height: 45px;
  padding: 0 0 0 10px;
  box-shadow: 0 0 0 1px var(--color-input-border),
    0 0 0 2px var(--color-input-border-shadow);
  border-radius: var(--border-radius-small);
  transition: box-shadow 0.3s var(--ease);

  * {
    width: 100%;
  }

  &.focused {
    box-shadow: 0 0 0 1px #326647, 0 0 0 4px rgba(0, 0, 0, 0.035);
  }
}

.success_message {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: var(--border-radius-large);
  transition: all 0.3s var(--ease);
  opacity: 0;
  z-index: -1;
  pointer-events: none;

  .successful & {
    opacity: 1;
    z-index: 1;
    pointer-events: initial;
  }
}

.success_icon {
  position: relative;
  margin-bottom: 30px;
  transition: all 0.7s var(--ease);

  .successful & {
    transform: none;
  }

  svg {
    height: 35px;
    width: auto;
    transform: scale(1.5);
    transition: all 0.5s var(--ease);

    path {
      fill: var(--color-green);
    }

    .successful & {
      transform: none;
    }
  }
}

.success_title {
  @include mixins.title('h2');
  margin-bottom: 20px;
  transition: all 0.7s var(--ease);
  transform: translateY(10px);

  .successful & {
    transform: none;
  }
}

.success_text {
  margin-bottom: 30px;
  color: var(--color-text-light);
  transition: all 0.7s var(--ease);
  transform: translateY(10px);

  .successful & {
    transform: none;
  }
}

.success_text__brand {
  text-transform: capitalize;
}

.success_button_container {
  margin-top: 20px;
  transition: all 0.7s var(--ease);
  transform: translateY(-10px);

  .successful & {
    transform: none;
  }
}

.loader {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: var(--border-radius-large);
}
