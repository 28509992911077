@use '~Sass/mixins';

.container {
  display: none;
  flex-direction: column;
  max-width: var(--layout-width);

  &.is_active {
    display: flex;

    @media all and (min-width: 601px) {
      padding-top: 20px;
    }

    @media all and (max-width: 600px) {
      padding-bottom: 20px;
    }
  }
}

.notice_button {
  @include mixins.button('green');
  flex-shrink: 0;
  box-shadow: var(--shadow1);

  @media all and (max-width: 1000px) {
    margin-top: 15px;
  }

  @media all and (max-width: 600px) {
    @include mixins.button('green', 'medium');
  }
}

.countdown {
  width: 20px;
  margin-left: 7px;
}
