@use '~Sass/mixins';

.container {
  --connector-color: #bfbfbf;
  --icon-color: var(--color-text);

  position: relative;
  width: 100%;
  min-height: 45px;
  transition: all 0.35s var(--ease);
  border-radius: var(--border-radius-large);
  margin-top: 5px;
  background-color: #fff;
  border-radius: var(--border-radius-large);
  box-shadow: var(--shadow1);

  &.first {
    margin-top: 0px;
  }
}

.compact {
  margin-top: 0;
  border-radius: 0;
  box-shadow: none;
}

.compact + .compact {
  border-top: 1px solid var(--color-borders);
}

.inner {
  display: grid;
  grid-template-columns: 230px 150px 1fr 45px;

  @media all and (max-width: 1005px) and (min-width: 601px) {
    grid-template-columns: 230px 150px 1fr;
  }

  @media all and (max-width: 850px) and (min-width: 601px) {
    grid-template-columns: 180px 120px 1fr;
  }

  @media all and (max-width: 750px) {
    grid-template-columns: 180px 1fr 45px;
  }

  @media all and (max-width: 350px) {
    grid-template-columns: 150px 1fr 45px;
  }
}

.loader {
  height: 16px;
  width: 16px;
}

.cell {
  position: relative;
  display: flex;
  align-items: center;
  height: 55px;
  width: 100%;
  min-width: 0;

  a {
    text-decoration: underline;
  }

  @media all and (max-width: 900px) {
    font-size: 13px;
  }

  @media all and (max-width: 750px) {
    font-size: 12px;
  }
}

.compact .cell {
  height: 50px;
}

.cell__inner {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.5;
}

.menu_wrapper {
  display: flex;
  justify-content: flex-end;
  padding-right: 7px;
}

.icon {
  height: 18px;
  width: 18px;
  vertical-align: middle;
  margin: 0 10px 1px 0;

  path {
    fill: var(--icon-color);
  }
}

.profile_picture {
  height: 24px;
  width: 24px;
  margin-right: 10px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  background-color: var(--color-green-grey-dark);
}

.advanced_dot {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  margin-right: 10px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  background-color: #fff;
  box-shadow: 0 0 0 3px #fff;
  font-family: var(--font-titles);

  svg {
    height: 18px;
    width: 18px;

    path {
      fill: var(--color-text-light);
    }
  }
}

.username {
  display: flex;
  align-items: center;
  padding: 0 20px 0 15px;
  font-size: 16px;

  @media all and (max-width: 900px) {
    font-size: 14px;
  }

  @media all and (max-width: 750px) {
    font-size: 13px;
  }
}

.type {
  @media all and (max-width: 1005px) {
    display: none;
  }
}

.expires_date {
  @include mixins.focusable;
}

.reauth_message {
  display: flex;
  align-items: center;
  color: var(--color-terracotta);
}

.reauth_button {
  @include mixins.button('grey', 'small');
  margin-left: 20px;

  @media all and (max-width: 750px) {
    display: none;
  }
}

.cell_right {
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding-right: 10px;
  z-index: 2;
  overflow: visible;
  flex-shrink: 0;
  margin-left: auto;
}

.loading_button__text {
  display: flex;
  height: 36px;
  flex-direction: column;
  overflow: hidden;
}

.loading_button__text_inner {
  height: 300%;
  transition: transform 0.3s var(--ease);

  .loading & {
    transform: translateY(-33.333%);
    transition: transform 0.3s var(--ease);
  }
  .success & {
    transform: translateY(-66.666%);
    transition: transform 0.3s var(--ease);
  }
}

.loading_button__message {
  display: flex;
  align-items: center;
  height: 36px;
  opacity: 0;
  transform: scale(0.9);
  transition: all 0.3s var(--ease);

  &:nth-child(1) {
    opacity: 1;
    transform: none;
  }

  .loading &:nth-child(1),
  .success &:nth-child(1) {
    opacity: 0;
    transform: scale(0.75);
  }

  .loading &:nth-child(2) {
    opacity: 1;
    transform: none;
  }
  .success &:nth-child(3) {
    opacity: 1;
    transform: none;
  }
}

.tooltip {
  @include mixins.tooltip;
}
.tooltip_small {
  @include mixins.tooltip('small');
  text-align: center;
}

.pro_accounts {
  position: relative;
  padding: 0 0 15px 5px;
  margin-top: -5px;
  grid-column: 1/-1;

  &::before {
    content: '';
    height: calc(100% - 10px);
    width: 1px;
    position: absolute;
    top: -19px;
    left: 26px;
    background-color: var(--connector-color);
  }
}

.pro_account {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  height: 26px;
  width: calc(100% - 28px);
  padding: 0 20px 0 12px;
  margin: 0 0 2px 28px;
  font-size: 15px;

  .profile_picture {
    height: 20px;
    width: 20px;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .cell {
    height: 36px;

    a {
      margin-left: 5px;
    }
  }

  &::after {
    content: '';
    height: 1px;
    width: 11px;
    position: absolute;
    top: 50%;
    left: -6px;
    background-color: var(--connector-color);
  }
}

.pro_account__loader {
  margin-right: 10px;
}

.pro_username {
  @include mixins.clippedText;
}

.menu_icon {
  path {
    fill: #fff;
  }
}
