@use 'Sass/mixins';

.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 0;
  margin: 9px;
  container-type: inline-size;
}

.pagination {
  @container (max-width: 480px) {
    width: 100%;
    justify-content: center;
    text-align: center;
    order: 2;
  }
}

.buttons {
  display: flex;
  align-items: center;
  gap: 4px;

  @container (max-width: 480px) {
    width: 100%;
    justify-content: center;
    order: 1;
  }
}

.page {
  height: 32px;
  width: 32px;
  background-color: var(--color-borders);
  border-radius: var(--border-radius-small);
  transition: all 0.3s var(--ease);
  font-size: 15px;

  &:hover:not(:disabled):not(.current),
  :global(.keys) &:focus:not(:disabled) {
    background-color: hsl(0, 0%, 87%);
  }

  &.current {
    background-color: var(--color-text);
    color: #fff;
  }
}

.separator {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  height: 32px;
  width: 32px;
  padding-bottom: 5px;
}
