@use 'Sass/mixins';

.container {
  position: relative;
  display: flex;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: flex-start;
}

.inner {
  position: relative;
  padding-top: 100px;
  max-width: calc(100vw - 60px);

  @media all and (max-height: 750px) {
    padding-top: 60px;
  }

  @media all and (max-width: 600px) {
    padding-top: 40px;
  }
}

.logo {
  margin-bottom: 80px;
  text-align: center;

  svg {
    height: 36px;
    vertical-align: middle;
  }

  @media all and (max-width: 600px) {
    margin-top: 10px;
    margin-bottom: 60px;
    text-align: left;

    svg {
      height: 30px;
    }
  }

  @media all and (max-height: 650px) {
    margin-top: 0;
    margin-bottom: 40px;

    svg {
      height: 30px;
    }
  }
}

.section_title {
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 600;

  @media all and (max-width: 600px) {
    margin-bottom: 30px;
  }
}

.form {
  display: flex;
  background-color: #fff;
  border-radius: var(--border-radius-large);
  box-shadow: var(--shadow1);

  @media all and (max-width: 600px) {
    flex-direction: column;
    background-color: transparent;
    box-shadow: none;
  }
}

.email {
  width: 350px;
  max-width: 100%;
  padding: 40px;

  @media all and (max-width: 600px) {
    padding: 0;
    width: 400px;
  }
}

.oauth {
  width: 260px;
  padding: 40px 40px 30px;
  border-radius: 0 12px 12px 0;
  box-shadow:
    inset 1px 0 0 0 #f2f2f2,
    -1px 0 0 0 #f2f2f2;

  .section_title {
    margin-bottom: 30px;
  }

  @media all and (max-width: 600px) {
    padding: 50px 0 0 0;
    width: 100%;
    box-shadow: none;
  }

  @media all and (max-height: 600px) {
    .section_title {
      margin-bottom: 20px;
    }
  }
}

.oauth__buttons {
  @media all and (max-width: 600px) {
    display: flex;
    justify-content: space-between;
  }
}

.oauth_button {
  @include mixins.focusable;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
  background-color: var(--color-green-grey);
  border-radius: var(--border-radius-small);
  font-weight: 600;
  font-size: 16px;
  transition: all 0.3s var(--ease);

  &:hover,
  &:focus {
    background-color: var(--color-algae);
  }

  svg {
    margin-right: 15px;
    height: 30px;
    width: 30px;
  }

  @media all and (max-width: 600px) {
    width: calc(50% - 8px);
    height: 50px;
    font-size: 16px;
    background-color: #eee;

    svg {
      height: 20px;
      width: 20px;
      margin-right: 10px;
    }
  }

  @media all and (max-width: 380px) {
    height: 45px;
    font-size: 15px;

    svg {
      height: 18px;
      width: 18px;
      margin-right: 7px;
    }
  }
}

.oauth_button + .oauth_button {
  @media all and (min-width: 601px) {
    margin-top: 15px;
  }
}

.footer {
  margin-top: 40px;
  padding-bottom: 40px;
  text-align: center;
  font-size: 18px;

  a {
    @include mixins.focusable;
    color: var(--color-text-green);
    font-weight: normal;
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  button {
    &:hover {
      text-decoration: underline;
    }
  }

  svg {
    height: 10px;
    margin-right: 15px;
  }

  @media all and (max-width: 600px) {
    margin-top: 35px;
    font-size: 16px;
  }
}

.last_login {
  margin-top: 30px;
  font-size: 15px;
  text-align: center;
  line-height: 1.35;
  color: var(--color-terracotta);
  opacity: 0;
  transform: translateY(-15px);
  transition: all 0.6s var(--ease);

  &.visible {
    opacity: 1;
    transform: none;
  }

  @media all and (max-width: 600px) {
    font-size: 14px;
  }
}

.success {
  padding: 40px;
  text-align: center;
  line-height: 1.5;

  .section_title {
    margin-bottom: 0;
  }
}
