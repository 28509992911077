.container{
  position: relative;
  z-index: 1;
  display: flex;
  align-items: stretch;
  height: 48px;
  padding: 7px;
  border-radius: var(--border-radius-large);
  border: 2px solid var(--color-borders);
  width: 100%;
  overflow: hidden;
  gap: 7px;
}

.link{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  border-radius: var(--border-radius-small);
  color: var(--color-text-light);
  text-align: center;
  letter-spacing: .006em;
  transition: all .3s var(--ease);
      

  &.current{
    background-color: var(--color-green-grey-dark);
    color: var(--color-text);
    font-weight: bold;
    letter-spacing: 0em;
  }

  &:hover:not(.current){
    color: var(--color-text);
    background-color: hsl(60, 12%, 95%);
  }
}