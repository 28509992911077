@use '~Sass/mixins';

.container {
  width: 400px;
  max-width: 100%;
  background-color: #fff;
  border-radius: var(--border-radius-large);
}

.header {
  padding: 40px 40px 10px;

  @media all and (max-width: 400px) {
    padding: 30px 30px 10px;
  }
}

.title {
  @include mixins.title('h2');
  display: flex;
  align-items: center;

  svg {
    height: 22px;
    margin-right: 15px;

    path {
      fill: var(--color-text-light);
    }
  }
}

.form {
  padding: 40px;

  @media all and (max-width: 400px) {
    padding: 30px;
  }
}
