@use '~Sass/mixins';

.container {
  display: flex;
  --height: var(--input-height);

  &.size_small {
    --height: 36px;
  }

  position: relative;

  &:focus-within .picker {
    opacity: 1;
    transform: none;
    pointer-events: all;
  }
}

.picker {
  position: absolute;
  z-index: 99;
  bottom: calc(100% + 5px);
  left: 0;
  width: 100%;
  min-width: 160px;
  opacity: 0;
  transform: scale(0.9);
  transition: all 0.3s var(--ease-out-back);
  pointer-events: none;
  box-shadow: var(--shadow0);
  border-radius: var(--border-radius-large);
}

.container :global(.react-colorful) {
  width: 100%;
  border-radius: var(--border-radius-large);
}

.input {
  height: var(--height);
  width: 100%;
  box-shadow:
    0 0 0 1px var(--color-input-border),
    0 0 0 2px var(--color-input-border-shadow);
  background-color: #fff;
  transition: box-shadow 0.3s var(--ease);
  border-radius: var(--border-radius-small);

  &:hover {
    box-shadow:
      0 0 0 1px var(--color-input-border-hover),
      0 0 0 2px var(--color-input-border-shadow);
  }

  &:focus {
    box-shadow:
      0 0 0 1px #326647,
      0 0 0 4px rgba(0, 0, 0, 0.035);
  }
}

.preview {
  position: absolute;
  top: 4px;
  right: 4px;
  flex-shrink: 0;
  height: calc(var(--height) - 8px);
  width: calc(var(--height) - 18px);
  min-height: 0;
  border-radius: var(--border-radius-small);
  pointer-events: none;
}
