@use '~Sass/mixins';

.container {
  --compact-delay: 0.25s;
  position: relative;
  border-radius: var(--border-radius-xlarge);
  overflow: hidden;
  background-color: var(--color-text);
  color: #fff;

  &.is_animated {
    opacity: 0;
    transform: scale(0.98);
  }

  &.is_loaded.is_animated {
    opacity: 1;
    transform: none;
    transition:
      height 0.6s var(--ease),
      margin 0.6s var(--ease),
      opacity 0.5s 0.3s var(--ease),
      transform 0.5s 0.3s var(--ease);
  }
}

.container + .container {
  margin-top: 10px;

  &.is_animated {
    margin-top: 0;
  }

  &.is_loaded.is_animated {
    margin-top: 10px;
  }
}

.inner {
  display: flex;
  padding: 27px 40px 30px 35px;
  align-items: center;
  justify-content: space-between;
  transition: padding 0.3s var(--compact-delay) var(--ease);

  .is_compact & {
    padding: 20px 30px 20px 20px;
  }

  @media all and (max-width: 1000px) {
    padding: 20px 25px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .is_compact & {
      padding: 15px 15px;
    }
  }
}

.title {
  @include mixins.title('h4');
  display: flex;
  align-items: center;
  color: rgb(153, 215, 179);
  line-height: 1;
  margin-bottom: 10px;
  transition: font-size 0.3s var(--compact-delay) var(--ease);

  svg {
    height: 20px;
    width: 20px;
    margin-right: 12px;

    path {
      fill: rgb(153, 215, 179);
    }
  }

  .is_compact & {
    @include mixins.title('h5');

    svg {
      height: 18px;
      width: 18px;
      transition: all 0.3s var(--compact-delay) var(--ease);
    }
  }
}

.text {
  position: relative;
  z-index: 1;
  padding-right: 60px;
  line-height: 1.35;

  a {
    color: var(--color-algae-darker);

    &:hover {
      text-decoration: underline;
    }
  }

  @media all and (max-width: 1000px) {
    padding-right: 0;
  }
}

.subtitle {
  max-width: 610px;
}

.title + .subtitle {
  margin-top: 5px;
}
