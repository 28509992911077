.container {
  position: relative;
  z-index: 2;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  height: 36px;
  padding: 0 10px;
  position: relative;
  overflow: hidden;
  font-size: 14px;
  color: var(--color-text);
  transition: all 0.2s var(--ease);
  overflow: hidden;
  border-radius: 8px;

  &:hover,
  :global(.keys) &:focus {
    background-color: var(--color-green-grey-dark);
    outline: none;

    svg {
      opacity: 1;
    }
  }

  &.red:hover,
  :global(.keys) &.red:focus {
    background-color: var(--color-light-terracotta);
  }

  &.full_width {
    width: 100%;
  }

  > svg {
    width: 16px;
    height: 16px;
    margin-right: 12px;
    transition: all 0.3s var(--ease);
    opacity: 0.85;

    path {
      fill: var(--color-text);
    }
  }

  &.loading {
    pointer-events: none;
  }

  &.loading,
  &.success,
  &.error {
    background-color: var(--color-green-grey);
    cursor: default;

    > svg {
      opacity: 0;
    }

    :global(.keys) &:focus {
      background-color: var(--color-green-grey);
      cursor: default;

      > svg {
        opacity: 0;
      }
    }
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }
}

.confirm {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: var(--color-text);
  color: #fff;
  clip-path: circle(0% at 50% 50%);
  will-change: clip-path;

  &.visible {
    transition: all 0.3s var(--ease);
    clip-path: circle(71% at 50% 50%);
  }

  .red & {
    background-color: var(--color-terracotta);
  }
}

.loading_el {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  padding: 0 10px;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  color: #fff;
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s var(--ease);
  overflow: hidden;
  text-indent: -1000px;

  .loading & {
    opacity: 1;
    transform: none;

    .loading_graphic {
      transform: none;
    }
  }
}

.loading_graphic {
  height: 18px;
  width: 18px;
  transform: scale(0);
  transition: all 0.4s var(--ease);
}

.success_el {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  padding: 0 10px;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s var(--ease);

  .loading & svg {
    transform: rotate(-70deg);
  }

  .success & {
    opacity: 1;
    transform: none;
  }

  svg {
    position: relative;
    height: 12px;
    width: 12px;
    transition: all 0.4s var(--ease);

    path {
      fill: var(--color-text);
    }
  }
}
