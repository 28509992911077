@use '~Sass/mixins';

// Error
.error {
  --dur: 0.3s;
  --delay: 0.1s;

  display: block;
  height: 0px;
  border-radius: var(--border-radius-small);
  opacity: 0;
  overflow: hidden;
  background-color: var(--color-light-yellow);
  transition: height var(--dur) var(--delay) var(--ease),
    margin var(--dur) var(--delay) var(--ease),
    opacity var(--dur) var(--delay) var(--ease);
  will-change: opacity, height, margin;
  line-height: 1.35;

  svg {
    height: 22px;
    width: 22px;
    margin-right: 15px;
    vertical-align: middle;
    flex-shrink: 0;

    path {
      fill: var(--color-text);
    }
  }

  &.visible {
    opacity: 1;
    margin-bottom: 30px;
    transition: height var(--dur) var(--ease), margin var(--dur) var(--ease),
      opacity var(--dur) var(--delay) var(--ease);
  }

  @media all and (max-width: 600px) {
    font-size: 16px;
  }
}

.error__inner {
  padding: 20px;
  transform: scale(0.95);
  transition: transform 0.3s var(--ease);

  .visible & {
    transform: none;
  }
}

// Form items
.form_item {
  display: block;
  margin-bottom: 30px;
}

.form_item__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.label {
  font-size: 16px;
}

.note {
  display: flex;
  align-items: flex-end;
  text-align: right;
  font-size: 12px;

  button,
  a {
    @include mixins.focusable;

    &:hover {
      text-decoration: underline;
    }
  }
}

.footer {
  display: flex;

  &.align_right {
    justify-content: flex-end;
  }
}

// Submit Button
.submit_button {
  *:first-child svg {
    height: 10px;
    width: 20px;
    margin-top: 2px;
    margin-right: 0;
    margin-left: 10px;
    transition: all 0.3s var(--ease);
  }
}

.cancel_button {
  margin-right: 20px;
}
