@use 'Sass/mixins';

.header {
  position: sticky;
  z-index: 10;
  top: 0;
  width: 100%;
  margin: 30px 0 0 0;
  padding: 30px 0;

  &.bottom_margin {
    margin: 30px 0 30px 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -40px;
    height: 100%;
    width: calc(100% + 80px);
    background-color: var(--color-background);

    @media all and (max-width: 800px) {
      width: calc(100% + 40px);
      left: -20px;
    }
  }

  @media all and (max-width: 1200px) {
    margin: 25px 0 0;
    padding: 20px 0;

    &.bottom_margin {
      margin: 25px 0 20px;
    }
  }

  @media all and (max-width: 600px) {
    margin: 0;
    padding: 0 0 30px;

    &::before {
      width: 100%;
    }

    &.bottom_margin {
      margin: 0;
    }
  }
}

.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  max-width: var(--layout-width);

  .stacked & {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.title {
  @include mixins.focusable;
  @include mixins.title('h1');

  .stacked & {
    margin-bottom: 30px;
  }
}

.button {
  @media all and (max-width: 600px) {
    margin-top: 0;
  }
}

.breadcrumbs {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 20px;
  margin-left: -5px;
  font-size: 13px;

  @media all and (max-width: 600px) {
    padding: 7px 10px;
    background-color: var(--color-green-grey);
    border-radius: var(--border-radius-small);
  }
}

.breadcrumb_current {
  margin-left: 7px;
  color: var(--color-text);
}

.breadcrumb {
  display: inline-flex;
  padding: 5px 7px;
  align-items: center;
  color: var(--color-text-green);
  border-radius: var(--border-radius-small);
  transition: all 0.3s var(--ease);

  &:hover {
    background-color: var(--color-borders);
  }
}

.breadcrumbs__divider {
  padding: 0 5px;
  color: var(--color-faded-text);

  @media all and (max-width: 600px) {
    padding: 0;
  }
}
