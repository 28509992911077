@use 'Sass/mixins';

.skip_link {
  @include mixins.button('blue');
  position: fixed;
  z-index: 200;
  top: 10px;
  left: 50%;
  transform: translate(-50%, -120%);
  transition: all 0.3s var(--ease);
  opacity: 0;
  box-shadow: 0px 8px 35px rgba(0, 0, 0, 0.2);

  :global(.keys) &:focus {
    transform: translate(-50%, 0%);
    opacity: 1;
    outline: none;
  }
}

.nav_container {
  display: flex;

  @media all and (max-width: 600px) {
    display: block;
  }
}

.layout {
  --padding: 0;
  --left-padding: 0px;
  position: relative;
  height: 100vh;
  width: 100%;
  padding: 0 var(--padding) var(--padding) var(--left-padding);
  overflow: hidden;

  &.has_sidebar {
    --padding: 60px;
    --left-padding: calc(75px + var(--sidebar-width));
    overflow: auto;

    @media all and (max-width: 1200px) {
      --padding: 45px;
      --left-padding: calc(45px + var(--sidebar-width));
    }

    @media all and (max-width: 800px) {
      --padding: 20px;
      --left-padding: calc(20px + var(--sidebar-width));
    }

    @media all and (max-width: 600px) {
      padding: 0 30px 80px;
    }
  }

  @media all and (max-width: 600px) {
    height: auto;
    min-height: 100vh;
  }
}

.unverified_message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;

  h1 {
    @include mixins.title(h2);
    margin-bottom: 30px;
  }
}
