@use '~Sass/mixins';

.container {
  position: relative;
  display: flex;
  max-height: 100%;
  border-radius: var(--border-radius-large);
  overflow: hidden;
}

.pre {
  width: 100%;
  max-height: 100%;
  min-height: 0%;
  padding: 30px;
  margin: 0;
  overflow: auto;
  border-radius: var(--border-radius-large);
  transition: box-shadow 0.3s var(--ease);
  background-color: var(--color-text);
  color: #f8f8f2;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0 !important;
  }
}

.code {
  padding: 0;
  margin: 0;
  font-family: monospace, sans-serif;
  font-size: 12px;
}

.copy {
  @include mixins.focusable('dark');
  @include mixins.label;
  position: absolute;
  bottom: 5px;
  right: 5px;
  display: flex;
  align-items: center;
  padding: 7px 10px;
  border-radius: 6px;
  background-color: var(--color-green-grey);
  transition: all 0.3s var(--ease);

  :global(.keys) &:focus {
    background-color: var(--color-focus-outline-light);
    color: #fff;
  }

  svg {
    height: 15px;
    width: 15px;
    margin-left: 5px;
  }

  &:hover {
    background-color: var(--color-algae);
  }
}
