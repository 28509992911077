@use '~Sass/mixins';

.container {
  position: relative;
  z-index: 1;
  width: 100%;
}

.hashtags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 10px;
  margin: 10px 0 35px;
}

.hashtag {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  height: 34px;
  padding: 0 0 0 10px;
  border-radius: 17px;
  background-color: var(--color-text);
  color: #fff;
  font-size: 16px;
  letter-spacing: 0.015em;
  opacity: 0;
  transform: scale(0.75);
  transition: all 0.4s var(--ease-out-back);
  will-change: opacity, transform;

  &::before {
    content: '#';
    color: var(--color-green);
    margin-right: 1px;
  }

  &.visible {
    opacity: 1;
    transform: none;
  }
}

.hashtag__remove {
  @include mixins.focusable;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px 2px 2px 5px;
  height: 30px;
  width: 30px;
  border-radius: 17px;
  transition: all 0.3s var(--ease);
  opacity: 0.65;

  &:hover,
  :global(.keys) &:focus {
    opacity: 1;
    background-color: #8f443a;

    svg {
      path {
        fill: #fff;
      }
    }
  }

  svg {
    height: 11px;
    width: 11px;

    path {
      fill: #fff;
      transition: all 0.3s var(--ease);
    }
  }
}

.search_interface {
  position: relative;
  display: flex;
  align-items: center;

  > * {
    flex-grow: 1;
  }
}

.query_counter {
  position: absolute;
  bottom: 100%;
  right: 0;
  margin-bottom: 7px;
  font-size: 12px;
  color: var(--color-text-light);
}

.at_limit {
  color: var(--color-terracotta);
}

.suggestions {
  position: absolute;
  top: calc(100% + 6px);
  left: -2px;
  width: calc(100% + 4px);
  padding: 10px;
  border-radius: var(--border-radius-large);
  background-color: #fff;
  box-shadow: var(--shadow2);
}

.results {
  overflow: auto;
  max-height: 250px;
}

.results + .results {
  margin-top: 5px;
}

.results__label {
  @include mixins.label;
  position: sticky;
  z-index: 1;
  top: 0;
  padding: 15px 0 10px 10px;
  color: var(--color-text-green);
  background-color: #fff;
  text-align: left;
}

.result {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 35px;
  width: 100%;
  padding: 0 10px;
  color: var(--color-text);
  border-radius: var(--border-radius-small);
  transition: all 0.3s var(--ease);
  letter-spacing: 0.015em;
  font-size: 15px;

  &::before {
    content: '#';
    color: var(--color-green);
    margin-right: 1px;
  }

  &::after {
    content: '+';
    margin: 0 0 0 auto;
    font-size: 18px;
    opacity: 0;
    transform: scale(0);
    transition: all 0.3s var(--ease);
    color: var(--color-text-green);
    font-weight: 600;
  }

  &:hover,
  &:focus {
    background-color: var(--color-algae);
    outline: none;

    &::after {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.result_large {
  font-size: 15px;
}

.error {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 45px;
  width: 100%;
  padding: 0 10px;
  color: var(--color-text);
  border-radius: var(--border-radius-small);
  transition: all 0.3s var(--ease);
  letter-spacing: 0.015em;
  line-height: 1.25;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.tooltip {
  @include mixins.tooltip;
}
