@use '~Sass/mixins';

.container {
  z-index: 101;
  display: flex;
  align-items: flex-start;
  padding: 20px;
  line-height: 1.4;
  width: 100%;
  max-width: var(--layout-width);
  background-color: var(--color-green-grey);
  border-radius: 0 0 var(--border-radius-large) var(--border-radius-large);

  svg {
    flex-shrink: 0;
    height: 24px;
    width: 24px;
    margin-right: 15px;

    path {
      fill: var(--color-text);
    }
  }

  @media all and (max-width: 600px) {
    border-radius: var(--border-radius-small);
    margin-bottom: 20px;
  }
}

.text {
  margin-top: -3px;
}

.title {
  @include mixins.title('h5');
}
