@use 'Sass/mixins';

.resizeable {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 80px);
  max-height: 100%;
}

.resizeable__scroll_container {
  max-height: 100%;
  width: 100%;
  overflow: auto;
  scrollbar-width: none;
  padding-bottom: 40px;
  opacity: 0;
  transition: opacity 0.3s var(--ease);

  &.visible {
    opacity: 1;
  }

  &::-webkit-scrollbar {
    width: 0 !important;
  }
}

.mobile_scroll_container {
  max-height: 100%;
  width: 100%;
  overflow: auto;
  scrollbar-width: none;
  transition: opacity 0.3s var(--ease);

  &::-webkit-scrollbar {
    width: 0 !important;
  }
}

.notices {
  position: absolute;
  z-index: 1;
  bottom: 20px;
  left: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
  width: calc(100% - var(--margin) * 2);
}

.breakpoint_notice {
  padding: 10px 15px;
  border-radius: var(--border-radius-large);
  background-color: var(--color-algae);
  opacity: 0;
  transform: translateY(5px) scale(0.8);
  transition: all 0.2s var(--ease);
  transform-origin: bottom left;
  visibility: hidden;

  &:hover {
    text-decoration: underline;
  }

  &.visible {
    visibility: visible;
    opacity: 1;
    transform: none;
    transition: all 0.3s var(--ease-out-back);
  }
}

.max_width_notice {
  padding: 10px 15px;
  margin-left: 10px;
  border-radius: var(--border-radius-large);
  background-color: var(--color-light-yellow);
  opacity: 0;
  transform: translateY(5px) scale(0.8);
  transition: all 0.2s var(--ease);
  transform-origin: bottom left;
  visibility: hidden;

  &.visible {
    visibility: visible;
    opacity: 1;
    transform: none;
    transition: all 0.3s var(--ease-out-back);
  }
}

.tooltip {
  @include mixins.tooltip;
}
