@use 'Sass/mixins';

.container {
  width: 100%;
  max-width: var(--layout-width);
}

.intro {
  margin-bottom: 40px;
  font-size: 18px;

  a {
    font-weight: 600;
    color: var(--color-text-green);

    &:hover {
      text-decoration: underline;
    }
  }
}

.form {
  max-width: 500px;
}

.button {
  margin-top: 40px;
}
