@use '~Sass/mixins';

.container {
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  padding: 30px;
  overflow: hidden;

  @media all and (max-width: 650px) {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.left {
  order: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 40%;
  padding: 75px 100px 0 40px;
  background-color: var(--color-algae);
  border-radius: var(--border-radius-large);

  @media all and (max-width: 1080px) {
    padding: 70px 60px 0 40px;
  }

  @media all and (max-width: 750px) {
    padding: 70px 40px 0 40px;
  }

  @media all and (max-width: 650px) {
    width: 100%;
    padding: 40px 0;
    align-items: center;
  }
}

.logo {
  @include mixins.focusable;

  svg {
    height: 29px;
    vertical-align: middle;
  }

  @media all and (max-width: 650px) {
    align-items: center;
  }
}

.left__footer {
  width: 100%;
  margin-bottom: 70px;
  text-align: right;
  line-height: 1.35;
  font-weight: 600;

  a {
    @include mixins.focusable;
    color: var(--color-text-green);
    outline: none;
    text-decoration: underline;
    white-space: nowrap;
  }

  @media all and (max-width: 650px) {
    text-align: center;
    max-width: 100%;
    margin-top: 30px;
    margin-bottom: 0;
    font-weight: 400;
  }
}

.left__footer_links {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
  font-size: 16px;

  a {
    @include mixins.focusable;
    outline: none;
    font-size: 14px;
    color: var(--color-text);
    text-decoration: none;
    font-weight: 400;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  a + a {
    margin-left: 30px;
  }

  @media all and (max-width: 650px) {
    display: none;
  }
}

.right {
  order: 2;
  display: flex;
  justify-content: flex-start;
  width: 60%;
  padding: 80px 0 0 80px;

  @media all and (max-width: 1080px) {
    padding: 80px 0 0 60px;
  }

  @media all and (max-width: 750px) {
    padding: 80px 0 0 40px;
  }

  @media all and (max-width: 650px) {
    width: 100%;
    padding: 40px 0;
    justify-content: center;
  }
}

.section_title {
  margin-bottom: 60px;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.015em;

  @media all and (max-width: 1080px) {
    margin-bottom: 30px;
    font-size: 20px;
  }
}

.form {
  width: 100%;
  max-width: 320px;
  border-radius: var(--border-radius-small);
}

.form_item {
  display: block;
  margin-bottom: 30px;
}

.form_item__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.form_item__note {
  font-size: 11px;
  color: var(--color-text-light);
  letter-spacing: 0em;
  font-weight: normal;
}

.label {
  @include mixins.label;
  font-size: 12px;
}

.oauth__buttons {
  display: flex;
  justify-content: space-between;
}

.oauth {
  margin-top: 60px;

  @media all and (max-width: 1080px) {
    margin-top: 40px;
  }
}

.oauth_title {
  margin-bottom: 30px;
  font-size: 18px;

  @media all and (max-width: 650px) {
    margin-bottom: 20px;
    font-size: 16px;
  }
}

.oauth_button {
  @include mixins.focusable;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: calc(50% - 8px);
  background-color: #eee;
  border-radius: var(--border-radius-small);
  font-weight: 600;
  font-size: 16px;
  transition: all 0.3s var(--ease);

  &:hover,
  &:focus {
    background-color: var(--color-algae);
  }

  svg {
    margin-right: 15px;
    height: 30px;
    width: 30px;
  }

  @media all and (max-width: 600px) {
    height: 50px;
    font-size: 16px;

    svg {
      height: 20px;
      width: 20px;
      margin-right: 10px;
    }
  }

  @media all and (max-width: 380px) {
    height: 45px;
    font-size: 15px;

    svg {
      height: 18px;
      width: 18px;
      margin-right: 7px;
    }
  }
}

.error {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  --dur: 0.3s;
  --delay: 0.1s;
  height: 0;
  padding: 0 20px;
  margin-bottom: 0px;
  border-radius: var(--border-radius-small);
  opacity: 0;
  overflow: hidden;
  transform: scale(0.95);
  background-color: var(--color-light-yellow);
  transition: height var(--dur) var(--delay) var(--ease),
    margin var(--dur) var(--delay) var(--ease),
    padding var(--dur) var(--delay) var(--ease), opacity var(--dur) var(--ease),
    transform var(--dur) var(--ease);
  will-change: opacity, height, padding, margin;
  line-height: 1.35;
  font-size: 15px;

  svg {
    display: none;
    flex-shrink: 0;
    height: 22px;
    width: 22px;
    margin-right: 15px;
    vertical-align: middle;

    path {
      fill: var(--color-text);
    }
  }

  &.visible {
    opacity: 1;
    padding: 20px;
    margin-bottom: 30px;
    transform: none;
    transition: height var(--dur) var(--ease), margin var(--dur) var(--ease),
      padding var(--dur) var(--ease),
      opacity var(--dur) var(--delay) var(--ease),
      transform var(--dur) var(--delay) var(--ease);
  }
}
