@use '~Sass/mixins';

.container {
  width: 100%;
  max-width: var(--layout-width);
}

.feeds_loading {
  position: relative;
  z-index: 1;
  display: grid;
  width: 100%;
  height: 280px;
  border-radius: var(--border-radius-large);
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 280px;
  gap: 30px;

  @media all and (max-width: 1200px), (max-height: 850px) {
    gap: 15px;
  }

  @media all and (max-width: 1080px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media all and (max-width: 750px) {
    grid-template-columns: 1fr;
    gap: 20px 0;
  }

  @media all and (max-width: 400px) {
    grid-auto-rows: 230px;
  }
}

.feeds_loading__loader {
  @include mixins.content-placeholder(var(--color-green-grey));
  height: 100%;
  width: 100%;
}

.feeds_grid {
  position: relative;
  z-index: 2;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 280px;
  gap: 30px;
  width: 100%;

  @media all and (max-width: 1200px), (max-height: 850px) {
    gap: 15px;
  }

  @media all and (max-width: 1080px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media all and (max-width: 750px) {
    grid-template-columns: 1fr;
    gap: 20px 0;
  }

  @media all and (max-width: 400px) {
    grid-auto-rows: 230px;
  }
}

.feeds_list {
  display: block;
  background-color: #fff;
  box-shadow: var(--shadow1);
  border-radius: var(--border-radius-large);
  overflow: hidden;
  margin-bottom: 30px;
}

.feeds_list_labels {
  display: grid;
  grid-template-columns: 1.4fr 1.4fr 0.6fr 1.1fr 0.5fr 40px;
  padding: 0 7px 0 15px;

  @media all and (max-width: 1000px) {
    grid-template-columns: 1.25fr 1.25fr 0.65fr 40px;
  }
  @media all and (max-width: 900px) {
    grid-template-columns: 1fr 1fr 40px;
  }
}

.feeds_list_label {
  @include mixins.focusable;
  display: flex;
  padding: 0 0 15px;
  font-weight: bold;

  &:hover .feeds_list_label__caret {
    opacity: 1;
  }

  svg {
    height: 16px;
    width: 16px;
    margin-right: 5px;
  }
}

.feeds_list_label__caret {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  margin: 1px 0 0 5px;
  border-radius: 4px;
  opacity: 0;
  transition:
    opacity 0.1s var(--ease),
    background 0.2s var(--ease),
    box-shadow 0.2s var(--ease);

  svg {
    height: 13px;
    width: 13px;
    margin-right: 0;

    path {
      fill: var(--color-text-light);
    }
  }

  &.sort_asc {
    transform: rotateX(180deg);
  }

  &.current {
    opacity: 1;
    background-color: hsl(60, 12%, 92%);
  }
}

.feeds_list_label__centered {
  text-align: center;
  justify-content: center;
}

.feeds_list_label__status,
.feeds_list_label__output {
  @media all and (max-width: 1000px) {
    display: none;
  }
}

.feeds_list_label__source_views {
  @media all and (max-width: 900px) {
    display: none;
  }
}

.tooltip_text {
  @include mixins.tooltip;
}

.add_feed_header {
  @include mixins.button('grey', 'large');
}

.add_feed {
  @include mixins.focusable;
  @include mixins.dashedBorder;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  min-width: 0px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transform-origin: center center;
  color: var(--color-text);
  border-radius: var(--border-radius-large);
  transition:
    box-shadow 0.35s var(--ease),
    background-color 0.35s var(--ease);

  @media (hover: hover) {
    &:hover {
      @include mixins.dashedBorder(#9e9e9e);
      background-color: #fff;
      box-shadow: var(--shadow1);

      .add_feed__inner {
        transform: scale(1.05);
      }
    }
  }

  svg {
    height: 20px;
    width: 20px;
    margin-right: 10px;

    path {
      fill: var(--color-text);
      transition: all 0.35s var(--ease);
    }
  }
}

.add_feed__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.35s var(--ease);
  will-change: transform;
}

.add_feed_wide {
  height: 200px;
  width: 100%;
  grid-column: span 3;
}

.loading_feed {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 0px;
  border-radius: var(--border-radius-large);
  background-color: var(--color-green-grey);
}

.loading_feed__spinner {
  height: 30px;
  width: 30px;
}

.empty_message {
  @include mixins.focusable;
  @include mixins.dashedBorder;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
  width: 100%;
  padding: 0 40px;
  font-size: 20px;
  cursor: pointer;
  background-color: transparent;
  transition:
    transform 0.35s var(--ease),
    box-shadow 0.35s var(--ease),
    background 0.35s var(--ease);

  svg {
    width: 48px;
    height: auto;
    margin-right: 20px;
  }

  u {
    text-decoration: underline;
  }

  @media (hover: hover) {
    &:hover {
      background-color: #fff;
      box-shadow: var(--shadow1);

      .empty_message__inner {
        transform: scale(1.02);
      }
    }
  }

  @media all and (max-width: 1080px) {
    height: auto;
    padding: 60px 40px;
    font-size: 16px;

    svg {
      margin-right: 15px;
    }
  }
}

.empty_message__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  transition: all 0.35s var(--ease);
  will-change: transform;
  text-align: center;
  line-height: 1.5;

  @media all and (max-width: 1080px) {
    flex-direction: column;
    gap: 20px;
  }
}

.empty_message__feeds {
  font-size: 18px;

  svg {
    height: 22px;
    width: 22px;
  }
}

.empty_message__button {
  display: inline-block;
  margin-left: 10px;

  @media all and (max-width: 1080px) {
    margin: 0 20px;
  }
}

.empty_message__button_inner {
  @include mixins.button('dark-green', 'medium');
}

.tooltip {
  @include mixins.tooltip('small');
  text-align: center;
}
