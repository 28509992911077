@use 'Sass/mixins';

.empty_message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.empty_message__title {
  @include mixins.title('h2');
}

.empty_message__link {
  @include mixins.button('algae');
  margin-top: 30px;
}

.container {
  --margin: 20px;
  height: 100vh;
  width: 100vw;
  display: flex;

  @media all and (max-width: 700px) {
    flex-direction: column;
  }
}

.sidebar {
  position: relative;
  z-index: 0;
  width: auto;
  flex-shrink: 0;
  flex-grow: 1;
  max-height: 100%;

  @media all and (max-width: 700px) {
    width: 100%;
    flex-shrink: 1;
  }
}

.sidebar__back {
  @include mixins.focusable;
  @include mixins.label('large');
  position: relative;
  display: flex;
  align-items: center;
  height: 60px;
  width: calc(100% - 30px);
  margin: 20px 0 10px;
  padding-left: 30px;
  color: var(--color-text);
  border-radius: 0 var(--border-radius-large) var(--border-radius-large) 0;
  transition:
    all 0.3s var(--ease),
    opacity 0.2s var(--ease);

  @media all and (max-width: 700px) {
    padding-left: 30px;
  }

  &:hover {
    background-color: var(--color-green-grey-dark);

    .back_button__back {
      transform: translateX(-3px);

      &::before {
        transform: scale(1.1);
        background-color: var(--color-green-grey-darker);
      }
    }
  }
}

.back_button__back {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding: 0 4px;
  position: relative;
  z-index: 1;
  transition: all 0.3s var(--ease);

  &::before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: var(--border-radius-small);
    transition: all 0.3s var(--ease);
  }
}

.back_button__arrow {
  position: relative;
  z-index: 1;
  height: 10px;
  width: auto;
  margin: 0 5px;
  transition: all 0.3s var(--ease);

  path {
    fill: var(--color-text);
  }
}

.sidebar__back_text {
  @include mixins.label('large');
  color: var(--color-text);
  margin-left: 10px;
  transition: all 0.2s var(--ease);
}

.sidebar__meta {
  padding: 17px 10px 30px 30px;
  margin: 0 30px 0 20px;
  border-radius: var(--border-radius-large);
  background-color: #efefef;

  @media all and (max-width: 700px) {
    padding: 17px 10px 30px 30px;
  }
}

.sidebar__meta_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebar__title {
  min-width: 0;
  font-weight: 600;
  font-size: 18px;
}

.sidebar__title_inner {
  @include mixins.clippedText;
}

.sidebar__title_input {
  height: 34px;
  margin: 0px 0 0 -10px;
  background-color: transparent;
  overflow: hidden;
  transition: all 0.3s var(--ease);

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:focus {
    background-color: #fff;
    box-shadow:
      0 0 0 1px #326647,
      0 0 0 4px rgba(0, 0, 0, 0.035);
  }
}

.panel_nav {
  display: flex;
  flex-direction: column;
  margin: 20px 0;

  @media all and (max-width: 700px) {
    margin: 0 0 30px;
  }
}

.panel_nav__button {
  @include mixins.focusable;
  position: relative;
  display: flex;
  align-items: center;
  height: 60px;
  padding-left: 40px;

  @media all and (max-width: 700px) {
    height: 55px;
  }

  &::after {
    content: '';
    height: 100%;
    width: calc(100% - 50px);
    position: absolute;
    top: 0;
    left: 20px;
    border-radius: var(--border-radius-large);
    background-color: var(--color-green-grey-dark);
    transform: translateX(-5px);
    opacity: 0;
    transition: all 0.3s var(--ease);
    transform-origin: center left;
  }

  &:hover {
    &::after {
      transform: none;
      opacity: 1;
    }
    .panel_nav__button_arrow {
      transform: translateX(3px);
      opacity: 1;
    }
  }

  svg {
    position: relative;
    z-index: 1;
    height: 20px;
    width: 20px;
    margin-right: 20px;

    path {
      fill: var(--color-text);
    }
  }

  .panel_nav__button_arrow {
    height: 10px;
    width: 10px;
    margin-left: auto;
    margin-right: 50px;
    transition: all 0.3s var(--ease);
    opacity: 0;
  }
}

.panel_nav__button_title {
  position: relative;
  z-index: 1;
}

.sidebar__views,
.sidebar__type,
.sidebar__source {
  display: flex;
  margin-top: 12px;
  align-items: center;
  font-size: 0.875em;

  svg {
    height: 16px;
    width: 16px;
    margin-right: 10px;
  }

  @media all and (max-width: 700px) {
    font-size: 1em;
  }
}

.preview_loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0 20px;

  p {
    margin-top: 10px;
    line-height: 1.5;
    text-align: center;
    font-size: 18px;

    &:first-of-type {
      margin-top: 40px;
    }
  }
}

.api_version {
  margin: 20px 30px 0 20px;
  border-radius: var(--border-radius-large);
  background-color: var(--color-lighter-blue);
  overflow: hidden;
}

.api_version__title {
  @include mixins.title('h4');
  @include mixins.focusable;
  display: flex;
  align-items: center;
  height: 80px;
  width: 100%;
  padding: 0 0 0 30px;

  svg {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }
}

.api_version__text {
  padding: 0 30px 30px;

  line-height: 1.5;

  a {
    font-weight: 600;
    color: var(--color-text-blue);
    text-decoration: underline;
  }
}

.api_verion__caret {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 60px;
  padding-right: 15px;
  margin-left: auto;

  svg {
    margin-right: 0;
  }
}

.api_version__footer {
  display: flex;
  align-items: center;
  margin: 30px 0 0 0;

  @media all and (max-width: 999px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.api_version_toggle {
  display: flex;
  height: 50px;
  width: 100%;
  padding: 0 20px;
  align-items: center;
  border-radius: var(--border-radius-large);
  background-color: #fff;
  box-shadow: var(--shadow0);
  flex-grow: 1;

  .switch_input__input {
    flex-grow: 1;
  }

  .switch_input__switch {
    flex-grow: 0;
    width: auto;
  }
}

.api_version_toggle__label {
  font-size: 16px;

  @media all and (max-width: 350px) {
    font-size: 14px;
  }
}

.api_version__button {
  flex-shrink: 0;
  margin-left: 10px;

  @media all and (max-width: 999px) {
    margin-top: 10px;
    margin-left: 0;
  }
}

.hashtag_load_warning {
  margin-top: 20px;
  padding: 15px 20px;
  border-radius: var(--border-radius-large);
  background-color: var(--color-green-grey);
  color: var(--color-text-light);
  text-align: center;
  line-height: 1.35;
}

.no_images {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 600px;
  padding: 20px;
  text-align: center;
  line-height: 1.35;
  font-size: 20px;

  @media all and (max-width: 500px) {
    font-size: 16px;
  }

  code {
    display: inline-block;
    white-space: no-wrap;
    padding: 1px 3px;
    background-color: var(--color-lighter-terracotta);
    border-radius: var(--border-radius-small);
    font-size: 0.875em;
  }

  a:not(.no_images__button):not(.no_images__reauth_button) {
    color: var(--color-text-green);
    font-weight: 600;
    text-decoration: underline;
  }
}

.no_images__icon {
  height: auto;
  width: 70px;
  margin-bottom: 20px;

  path {
    fill: var(--color-text);
  }
}

.no_images__title {
  @include mixins.title('h2');
  margin-bottom: 20px;
}

.no_images__button {
  @include mixins.button('subtle', 'large');
  margin-top: 15px;
}

.no_images__reauth_button {
  @include mixins.button('grey', 'large');
  margin-top: 10px;
}

.preview {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - var(--margin) * 2);
  min-width: 0;
  margin: var(--margin) var(--margin) var(--margin) 0;
  background-color: #fff;
  box-shadow:
    0px -1px 0px rgba(117, 69, 69, 0.05),
    1px 0px 0px rgba(117, 69, 69, 0.05),
    -1px 0px 0px rgba(117, 69, 69, 0.05),
    3px 60px 90px rgba(92, 87, 67, 0.075),
    3px 25.0154px 37.7819px rgba(92, 87, 67, 0.054074),
    3px 13.5802px 20.982px rgba(92, 87, 67, 0.0435718),
    3px 7.9006px 11.4136px rgba(92, 87, 67, 0.038),
    3px 4.51362px 5.9037px rgba(92, 87, 67, 0.0304282),
    3px 1.79304px 2.95339px rgba(92, 87, 67, 0.020926);
  overflow: hidden;
  border-radius: var(--border-radius-large);

  @media all and (max-width: 1000px) {
    padding: 20px 20px 0;
  }

  @media all and (max-width: 700px) {
    height: 100%;
    width: calc(100% - 40px);
    margin: 0 20px 20px;
    padding: 20px;
    justify-self: stretch;
  }
}

.api_url {
  padding: 30px 30px 0 40px;

  @media all and (max-width: 700px) {
    padding: 10px 30px 20px 35px;
  }
}

.api_url__title {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  svg {
    height: 20px;
    width: 20px;
    margin-right: 20px;

    path {
      fill: var(--color-text);
    }
  }
}

.api_url__section + .api_url__section {
  margin-top: 30px;
}

.description {
  position: relative;
  padding: 30px;
  margin: 0 30px;
  line-height: 1.5;
  border-radius: var(--border-radius-large);
  background: var(--color-green-grey-dark);

  a {
    text-decoration: underline;
    color: var(--color-text-green);
    font-weight: 600;
  }
}

.mobile_view__tabs {
  flex-shrink: 0;
  display: flex;
  justify-content: flex-start;
  padding: 20px 30px 20px 20px;
}

.mobile_view__tab {
  @include mixins.focusable;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 50%;
  padding: 0 20px;
  background-color: var(--color-green-grey);
  pointer-events: initial;
  border-radius: var(--border-radius-small);
  font-weight: 600;
  transition: all 0.2s var(--ease);

  &.current {
    background-color: var(--color-green-grey-darker);
  }

  svg {
    height: 22px;
    vertical-align: middle;
    margin-right: 10px;

    path {
      fill: var(--color-text);
      transition: all 0.2s var(--ease);
    }
  }
}

.mobile_view__tab + .mobile_view__tab {
  margin-left: 10px;
}

.upgrade_notice {
  margin: 20px 30px 0 20px;
  padding: 25px;
  border-radius: var(--border-radius-large);
  line-height: 1.5;
  background-color: var(--color-text);
  color: #fff;

  a {
    @include mixins.button('green', 'medium');
  }

  b {
    font-size: 1.125em;
  }

  @media all and (max-width: 700px) {
    margin: 10px 30px 20px 35px;
  }
}

.klaviyo_plan_notice {
  margin: 20px 30px 0 20px;
  padding: 25px;
  border-radius: var(--border-radius-large);
  line-height: 1.5;
  background-color: var(--color-text);
  color: #fff;

  a {
    @include mixins.button('green', 'medium');
  }

  b {
    color: hsl(145, 33%, 55%);
  }

  @media all and (max-width: 700px) {
    margin: 10px 30px 20px 35px;
  }
}
