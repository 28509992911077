@use '~Sass/mixins';

.container {
  width: 400px;
  max-width: 100%;
  background-color: #fff;
  border-radius: var(--border-radius-large);
}

.header {
  padding: 40px;

  @media all and (max-width: 400px) {
    padding: 30px;
  }
}

.title {
  @include mixins.title('h2');
  display: flex;
  align-items: center;

  svg {
    height: 22px;
    margin-right: 15px;

    path {
      fill: var(--color-text-light);
    }
  }
}

.intro {
  padding: 20px 40px;
  background-color: var(--color-lighter-terracotta);
  line-height: 1.35;

  a {
    font-weight: 600;
    text-decoration: underline;
    color: var(--color-text-blue);
  }

  @media all and (max-width: 400px) {
    padding: 20px 30px;
  }
}

.error {
  padding: 20px 40px;
  margin-top: 10px;
  background-color: var(--color-light-yellow);
  line-height: 1.35;

  a {
    font-weight: 600;
    text-decoration: underline;
  }
}

.buttons {
  display: flex;
  gap: 20px;
  padding: 40px;

  @media all and (max-width: 400px) {
    padding: 30px;
  }
}

.cancel_button {
  @include mixins.button('grey');
}
