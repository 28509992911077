@use '~Sass/mixins';

.container {
  display: inline-flex;
  position: relative;
  transition: all 0.3s var(--ease);
  border-radius: var(--border-radius-large);

  &.size_xlarge {
    &.color_algae {
      @include mixins.button('algae', 'xlarge');
    }

    &.color_green {
      @include mixins.button('green', 'xlarge');
    }

    &.color_dark_green {
      @include mixins.button('dark-green', 'xlarge');
    }

    &.color_blue {
      @include mixins.button('blue', 'xlarge');
    }

    &.color_light_blue {
      @include mixins.button('light-blue', 'xlarge');
    }

    &.color_subtle {
      @include mixins.button('subtle', 'xlarge');
    }

    &.color_grey {
      @include mixins.button('grey', 'xlarge');
    }

    &.color_dark {
      @include mixins.button('dark', 'xlarge');
    }

    &.color_light_terracotta {
      @include mixins.button('light-terracotta', 'xlarge');
    }
  }

  &.size_large {
    &.color_algae {
      @include mixins.button('algae', 'large');
    }

    &.color_green {
      @include mixins.button('green', 'large');
    }

    &.color_dark_green {
      @include mixins.button('dark-green', 'large');
    }

    &.color_blue {
      @include mixins.button('blue', 'large');
    }
    &.color_light_blue {
      @include mixins.button('light-blue', 'large');
    }

    &.color_subtle {
      @include mixins.button('subtle', 'large');
    }

    &.color_grey {
      @include mixins.button('grey', 'large');
    }

    &.color_dark {
      @include mixins.button('dark', 'large');
    }

    &.color_light_terracotta {
      @include mixins.button('light-terracotta', 'large');
    }
  }

  &.size_medium {
    &.color_algae {
      @include mixins.button('algae', 'medium');
    }

    &.color_green {
      @include mixins.button('green', 'medium');
    }

    &.color_dark_green {
      @include mixins.button('dark-green', 'medium');
    }

    &.color_blue {
      @include mixins.button('blue', 'medium');
    }

    &.color_light_blue {
      @include mixins.button('light-blue', 'medium');
    }

    &.color_subtle {
      @include mixins.button('subtle', 'medium');
    }

    &.color_grey {
      @include mixins.button('grey', 'medium');
    }

    &.color_dark {
      @include mixins.button('dark', 'medium');
    }

    &.color_light_terracotta {
      @include mixins.button('light-terracotta', 'medium');
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }

  &.loading {
    pointer-events: none;
    cursor: default;
  }

  &.full_width {
    display: flex;
    width: 100%;
  }
}

.loader {
  position: absolute;
  z-index: 2;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: opacity 0.3s var(--ease);
  pointer-events: none;
  border-radius: var(--border-radius-large);
  text-indent: -1000px;
  overflow: hidden;

  .color_algae & {
    background-color: var(--color-algae);
  }

  .color_green & {
    background-color: var(--color-green);
  }

  .color_blue & {
    background-color: var(--color-blue);
  }

  .color_subtle & {
    background-color: #e6e6e6;
  }

  .color_grey & {
    background-color: #e6e6e6;
  }

  .color_light_terracotta & {
    background-color: var(--color-light-terracotta);
  }

  .color_light_blue & {
    background-color: var(--color-light-blue);
  }

  .color_dark & {
    background-color: var(--color-text);
  }

  .loading & {
    opacity: 1;
  }
}

.confirm {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-weight: 600;
  font-size: 17px;
  background-color: var(--confirm-background);
  border-radius: var(--border-radius-large);
  color: #fff;
  clip-path: circle(0% at 50% 50%);
  will-change: clip-path;
  overflow: hidden;

  &.visible {
    transition: all 0.3s var(--ease);
    clip-path: circle(71% at 50% 50%);
  }

  .color_light_terracotta & {
    background-color: var(--color-terracotta);
  }
}
