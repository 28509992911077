@use 'Sass/mixins';

.container {
  position: relative;
  display: grid;
  grid-template-columns: 1.4fr 1.4fr 0.6fr 1.1fr 0.5fr 40px;
  height: 50px;
  padding: 0 7px 0 15px;
  align-items: center;

  @media all and (max-width: 1000px) {
    grid-template-columns: 1.25fr 1.25fr 0.65fr 40px;
  }
  @media all and (max-width: 900px) {
    grid-template-columns: 1fr 1fr 40px;
  }
}

.status_container {
  height: 100%;
  @media all and (max-width: 1000px) {
    display: none;
  }
}

.status {
  display: flex;
  justify-content: center;
  text-align: center;

  &::before {
    content: '!';
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    font-weight: strong;
    font-size: 14px;
    color: var(--color-terracotta);
    border-radius: 50%;
    box-shadow: 0 0 0 1px var(--color-terracotta);
  }

  &.active {
    &::before {
      content: '';
      height: 8px;
      width: 8px;
      background-color: hsl(143, 100%, 35%);
      box-shadow: none;
    }
  }
}

.type {
  display: flex;
  align-items: center;

  svg {
    height: 16px;
    width: 16px;
    margin-right: 7px;
  }

  @media all and (max-width: 1000px) {
    display: none;
  }
}

.usage,
.source_type {
  text-align: center;
}

.container + .container {
  border-top: 1px solid var(--color-borders);
}

.title {
  max-width: calc(100% - 35px);
  font-size: 16px;
  line-height: 1.25;
  word-wrap: break-word;
}

.title__inner {
  @include mixins.clippedText;
  padding-left: 4px;
}

.username {
  max-width: calc(100% - 35px);
  font-size: 16px;
  line-height: 1.25;
  word-wrap: break-word;
}

.username__inner {
  @include mixins.clippedText;
}

.title__input {
  height: 34px;
  margin: 0 0 0 -6px;
  resize: none;
  background-color: var(--color-green-grey);
  overflow: hidden;
  transition: all 0.3s var(--ease);
  pointer-events: all;

  :global(.keys) &:focus {
    box-shadow: 0 0 0 2px var(--color-focus-outline-light);
  }
}

.usage {
  @media all and (max-width: 900px) {
    display: none;
  }
}

.menu {
  margin-left: auto;
}

.status_popup {
  font-size: 14px;
  padding: 10px;
  line-height: 1.5;
  background-color: var(--color-text);
  color: #fff;
  text-align: center;

  p {
    margin: 10px 0;
  }

  &.small {
    padding: 7px;
  }
}
