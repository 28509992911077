@use 'Sass/mixins';

.container {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 100px 25px 0;
  will-change: max-width;
}

.handle {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-top: 60px;
  width: 65px;
  cursor: grab;
  pointer-events: all;
  transition: all 0.3s var(--ease);

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    height: 100%;
    width: 50vw;
    transform: translateY(-50%);
    opacity: 0;
    transition: all 0.6s var(--ease);
  }

  &:active {
    cursor: grabbing;
  }

  .handles_hovered & {
    &::before {
      opacity: 1;
    }
    > svg {
      transform: scale(1.1);
      opacity: 1;
    }
  }

  > svg {
    position: relative;
    z-index: 1;
    height: 20px;
    width: 20px;
    opacity: 0.75;
    transition: all 0.3s var(--ease);
    pointer-events: none;

    path {
      fill: var(--color-text);
    }
  }
}

.handle_left {
  right: calc(100% - 25px);

  &::before {
    right: 0;
    background-image: linear-gradient(
      to left,
      rgba(244, 244, 240, 0.75) 0%,
      rgba(244, 244, 240, 0) 12%,
      rgba(244, 244, 240, 0) 100%
    );
  }
}

.handle_right {
  left: calc(100% - 25px);

  &::before {
    left: 0;
    background-image: linear-gradient(
      to right,
      rgba(244, 244, 240, 0.75) 0%,
      rgba(244, 244, 240, 0) 12%,
      rgba(244, 244, 240, 0) 100%
    );
  }
}

@keyframes hover {
  0%,
  100% {
    transform: translate(50%, 0px);
  }
  50% {
    transform: translate(50%, -7px);
  }
}

@keyframes hoverHorizontal {
  0%,
  100% {
    transform: translate(0px, -50%);
  }
  50% {
    transform: translate(-7px, -50%);
  }
}

.handle__hint {
  position: absolute;
  z-index: 2;
  top: 42%;
  right: 50%;
  width: 210px;
  padding: 15px;
  background-color: var(--color-text);
  text-align: center;
  line-height: 1.25;
  backface-visibility: hidden;
  transition: all 0.2s var(--ease);
  pointer-events: none;
  transform: translateX(50%);
  border-radius: var(--border-radius-large);
  animation: hover 2.5s ease-in-out infinite;
  color: #fff;

  &::after {
    content: '';
    height: 10px;
    width: 10px;
    position: absolute;
    left: calc(50% - 5px);
    top: calc(100% - 6px);
    transform: rotate(45deg);
    border-radius: 2px;
    background: var(--color-text);
  }

  .hide_hint & {
    opacity: 0;
  }

  svg {
    height: 39px;
    width: auto;
    margin: 10px 0 0 95px;

    path {
      fill: var(--color-text-green);
    }
  }

  @media all and (max-width: 1610px) {
    top: calc(50% + 30px);
    right: 100%;
    transform: translateY(-50%);
    animation: hoverHorizontal 2.5s ease-in-out infinite;

    &::after {
      top: calc(50% - 5px);
      left: calc(100% - 6px);
    }
  }
}

.width_display {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 50px);
  position: absolute;
  bottom: calc(100% - 60px);
  left: 50%;
  transform: translate(-50%, 50%);
}

.width_display__arrow {
  display: flex;
  align-items: center;
  height: 8px;
  width: 50%;
  position: absolute;
  z-index: 1;
  top: calc(50% + 1px);
  transform: translateY(-50%);
  pointer-events: none;

  &:nth-child(1) {
    left: 0;

    &::before {
      content: '';
      display: block;
      height: 8px;
      width: 8px;
      background: url(~Images/icons/caret-left.svg) center center no-repeat;
    }

    &::after {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      border-top: 1px dashed #a9a9a9;
    }
  }

  &:nth-child(2) {
    right: 0;

    &::before {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      border-top: 1px dashed #a9a9a9;
    }

    &::after {
      content: '';
      display: block;
      height: 8px;
      width: 8px;
      background: url(~Images/icons/caret-right.svg) center center no-repeat;
    }
  }
}

.width_display__number_container {
  position: relative;
  z-index: 3;
  width: 120px;
  padding: 0 10px;
  background-color: #fff;
}

.width_display__number {
  @include mixins.focusable;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 100%;
  position: relative;
  text-align: center;
  background-color: #fff;
  border-radius: var(--border-radius-small);
  transition: all 0.3s var(--ease);

  &:hover {
    background-color: var(--color-green-grey);
  }
}

.width_display__number_input {
  width: 100%;
  text-align: center;
}

.width_display__marker {
  position: absolute;
  z-index: 2;
  top: calc(50% - 14px);
  height: 30px;
  transition: all 0.3s var(--ease);
  border-radius: var(--border-radius-small);

  :global(.keys) &:focus {
    outline: none;
    border: 2px solid var(--color-focus-outline);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &::before,
  &:after {
    content: '';
    height: 12px;
    width: 1px;
    position: absolute;
    background-color: var(--color-text);
  }

  &::before {
    top: calc(50% - 6px);
    left: -1px;
  }

  &::after {
    top: calc(50% - 6px);
    right: -1px;
  }
}
