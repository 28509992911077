@use '~Sass/mixins';

.live_chat {
  @include mixins.focusable;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 80px;
  padding: 0 30px;
  border-radius: var(--border-radius-large);
  border: 2px solid var(--color-borders);
  font-size: 18px;

  @media all and (max-width: 650px) {
    padding: 0 20px;
  }

  &.live_chat__online {
    border: 1px solid var(--color-algae-darker);
    cursor: pointer;
    transition: all 0.3s var(--ease);
    background-color: #f4f5ed;

    &:hover {
      box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.035);
      border: 1px solid var(--color-green);
      background-color: #fff;

      .live_chat__cta {
        svg {
          transform: translateX(3px);
        }
      }
    }
  }
}

.live_chat__indicator {
  display: block;
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-radius: 50%;
  background-color: #bfbfbf;

  .live_chat__online & {
    @include mixins.shadowPulse(2.75, 2.75, 1, 2.25s);
    background-color: #3ca566;
  }
}

.live_chat__status {
  display: flex;
  align-items: center;

  svg {
    height: 20px;
    width: 20px;
    margin: 0 10px 0 5px;

    path {
      fill: var(--color-text-light);
    }

    @media all and (max-width: 380px) {
      display: none;
    }
  }
}

.live_chat__cta {
  margin-left: auto;
  font-weight: 600;

  svg {
    height: 10px;
    width: 10px;
    margin-left: 10px;
    transition: all 0.3s var(--ease);
  }
}
