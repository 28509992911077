@use '~Sass/mixins';

.container {
  width: 100%;
  max-width: var(--layout-width);
}

.save_note {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  padding: 0 10px;
  margin: 0 30px 0 auto;
  font-size: 13px;
  font-weight: 600;
  border-radius: 12px;
  background-color: var(--color-light-yellow);

  @media all and (max-width: 850px) {
    display: none;
  }
}

.interval_switch {
  align-self: center;
  height: 30px;
  width: auto;
  flex-grow: 0;

  @media all and (max-width: 700px) {
    margin-left: 0;
    align-self: flex-start;
  }
}

.switch_label {
  transition: all 0.4s var(--ease);
  color: var(--color-text-light);

  &:hover {
    color: var(--color-text);
    text-decoration: underline;
  }
}

.switch_label_current {
  color: var(--color-text);
  text-decoration: underline;
}

.inner {
  display: flex;
  align-items: flex-start;
}

.sidebar {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-shrink: 0;
  margin-right: 60px;

  @media all and (max-width: 1100px) {
    display: none;
  }
}

.plans {
  width: 100%;
}

.plan {
  width: 100%;
}

.plan + .plan {
  margin-top: 10px;
}

.plan__radio {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.plan__inner {
  position: relative;
  display: flex;
  align-items: center;
  height: 90px;
  width: 100%;
  padding: 0 30px;
  background-color: var(--color-green-grey-dark);
  border-radius: var(--border-radius-large);
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.3s var(--ease);
  font-size: 20px;

  .small & {
    height: 60px;
  }

  &::before {
    content: '';
    height: 16px;
    width: 16px;
    margin-right: 30px;
    border-radius: 50%;
    background-color: #d8d8ce;
    transition: all 0.3s var(--ease);

    @media all and (max-width: 600px) {
      height: 12px;
      width: 12px;
    }

    @media all and (max-width: 450px) {
      display: none;
    }
  }

  &::after {
    content: '';
    height: 12px;
    width: 12px;
    position: absolute;
    top: 50%;
    left: 32px;
    transform: translateY(-50%) scale(0);
    border-radius: 50%;
    background-color: var(--color-dark-green);
    transition: all 0.3s var(--ease);

    @media all and (max-width: 600px) {
      height: 8px;
      width: 8px;
    }

    @media all and (max-width: 450px) {
      display: none;
    }
  }

  &:hover {
    background-color: var(--color-green-grey-darker);
    &::before {
      background-color: #c9c9bd;
    }
  }

  @media all and (max-width: 900px) {
    height: 70px;
    padding: 0 20px;
    font-size: 18px;

    &::before {
      margin-right: 20px;
    }

    &::after {
      left: 22px;
    }
  }

  @media all and (max-width: 600px) {
    font-size: 16px;

    &::before {
      margin-right: 15px;
    }
  }
}

.plan__radio:checked + .plan__inner {
  box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.035);
  border: 1px solid var(--color-dark-green);
  background-color: #fff;

  &::before {
    box-shadow: 0 0 0 1px var(--color-dark-green);
    background-color: transparent;
  }

  &::after {
    transform: translateY(-50%);
  }
}

:global(.keys) .plan__radio:focus + .plan__inner {
  box-shadow: 0 0 0 2px var(--color-focus-outline);
}

.plan__title {
  line-height: 1;
}

.plan__title_interval {
  text-transform: capitalize;
  font-size: 0.75em;
  margin-left: 10px;
  color: var(--color-text-light);

  @media all and (max-width: 700px) and (min-width: 601px), (max-width: 380px) {
    display: none;
  }
}

.plan__current {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  height: 24px;
  padding: 0 12px 1px;
  margin-left: 30px;
  background-color: var(--color-text);
  color: #fff;
  font-size: 11px;

  @media all and (max-width: 900px) {
    margin-left: 20px;
  }

  @media all and (max-width: 800px) {
    height: 20px;
    padding: 0 8px;
    font-size: 10px;
  }
  @media all and (max-width: 450px) {
    margin-left: 10px;
  }
}

.plan__price {
  margin-left: auto;
  font-size: 24px;

  @media all and (max-width: 900px) {
    font-size: 20px;
  }
}

.plan__price_interval {
  color: var(--color-text-light);
  font-size: 0.75em;
}

.plan__annual_price {
  font-size: 14px;
  margin-top: 7px;
  color: var(--color-text-light);

  @media all and (max-width: 900px) {
    font-size: 13px;
    margin-top: 3px;
  }
}

.plan_loader {
  @include mixins.content-placeholder;
  height: 90px;
  width: 100%;
  margin-bottom: 20px;
  border-radius: var(--border-radius-large);

  @media all and (max-width: 900px) {
    height: 70px;
  }
}

.plans__footer {
  margin-top: 40px;

  svg {
    margin-left: 10px;
    margin-right: 0px !important;
  }
}
