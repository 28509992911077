.popover {
  --border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  opacity: 0;
  transform: none;
  will-change: opacity, transform;
  contain: layout;

  &.fixed {
    position: fixed;
  }

  &.visible {
    opacity: 1;
  }

  &.transition {
    transition: transform 0.3s var(--ease);
  }

  &.hidden {
    transition: all 0.1s var(--ease);
    opacity: 0;
    pointer-events: none;
  }
}

.popover__inner {
  position: relative;
  overflow: hidden;
  transition:
    all 0.1s ease,
    opacity 0.1s ease,
    transform 0.3s var(--ease-out-back);
  transform: scale(0.95) translate(-6px, 8px);
  box-shadow: var(--shadow2);
  backface-visibility: hidden;
  border-radius: var(--border-radius-large);

  &.x_center {
    transform: scale(0.95) translate(0px, 8px);
  }

  &.x_left {
    transform: scale(0.95) translate(6px, 8px);
  }

  .visible & {
    transform: none;
  }
}

.mask {
  position: fixed;
  z-index: 201;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(34, 36, 68, 0.05);
  opacity: 0;
  transition: all 0.25s var(--ease);
  pointer-events: none;
  will-change: opacity;
  backface-visibility: hidden;
  contain: strict;

  &.visible {
    transition: all 0.4s var(--ease);
    opacity: 1;
    pointer-events: initial;
  }

  &.hidden {
    transition: all 0.3s var(--ease);
    opacity: 0;
    pointer-events: none;
  }
}
