@use '~Sass/mixins';

.container {
  --height: var(--input-height);

  &.size_small {
    --height: 36px;
  }
}

.button {
  @include mixins.focusable;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--height);
  width: 100%;
  padding: 0 12px;
  box-shadow:
    0 0 0 1px var(--color-input-border),
    0 0 0 2px var(--color-input-border-shadow);
  border-radius: var(--border-radius-small);
  transition: box-shadow 0.3s var(--ease);
  background-color: #fff;

  &:hover {
    box-shadow:
      0 0 0 1px var(--color-input-border-hover),
      0 0 0 2px var(--color-input-border-shadow);
  }

  &:hover .caret {
    box-shadow: -1px 0 0 var(--color-input-border-hover);
  }

  &:focus,
  .open & {
    box-shadow:
      0 0 0 1px #326647,
      0 0 0 4px rgba(0, 0, 0, 0.035);
  }

  &:focus .caret,
  .open & .caret {
    box-shadow:
      -1px 0 0 #326647,
      -1px 0 0 rgba(0, 0, 0, 0.035);
  }
}

.button__text {
  @include mixins.clippedText;
}

.popover {
  display: grid;
  grid-template-columns: repeat(var(--num-columns), 1fr);
  grid-template-rows: 1fr;
  gap: 10px;
  padding: 25px;
  background-color: #fff;
}

.option {
  @include mixins.focusable;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 0 20px;
  background-color: var(--color-green-grey);
  border-radius: var(--border-radius-small);
  transition: all 0.3s var(--ease);
  text-align: center;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  svg {
    max-height: calc(100% - 40px);
  }

  &.large {
    height: 80px;
  }

  &:hover:not(.current):not(:disabled) {
    background-color: hsl(60, 13%, 92%);
  }

  &.current {
    box-shadow: inset 0 0 0 1px var(--color-text);
  }
}

.caret {
  position: absolute;
  top: 0px;
  right: 0;
  display: inline-flex;
  height: 100%;
  width: calc(var(--height) - 9px);
  padding: 1px 0 0 0;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  border-radius: 0 var(--border-radius-small) var(--border-radius-small) 0;
  box-shadow:
    -1px 0 0 var(--color-input-border),
    -1px 0 0 var(--color-input-border);
  pointer-events: none;
  transition: box-shadow 0.3s var(--ease);

  svg {
    height: 14px;
    width: 14px;
    vertical-align: middle;

    path {
      fill: var(--color-text);
    }
  }
}
