@mixin title($rank: 'h2') {
  --font-size: var(--base-font-size);
  font-weight: 500;
  font-family: var(--font-titles);
  font-size: var(--font-size);

  @if $rank == 'h1' {
    --base-font-size: 38px;
  }

  @if $rank == 'h2' {
    --base-font-size: 27px;
  }

  @if $rank == 'h3' {
    --base-font-size: 22px;
  }

  @if $rank == 'h4' {
    --base-font-size: 20px;
    font-weight: 600;
    font-family: var(--font-body);
  }

  @if $rank == 'h5' {
    --base-font-size: 18px;
    font-weight: 600;
    font-family: var(--font-body);
  }

  @media all and (max-width: 800px) {
    --font-size: calc(var(--base-font-size) * 0.875);
  }

  @media all and (max-width: 350px) {
    --font-size: calc(var(--base-font-size) * 0.8125);
  }
}

@mixin clippedText {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.2;
}

@mixin center-column($size: 'medium') {
  width: var(--layout-width);
  max-width: calc(100vw - 60px);
  margin-left: auto;
  margin-right: auto;

  @if $size == 'small' {
    width: 900px;
  }
  @if $size == 'large' {
    width: 1200px;
  }

  @media all and (max-width: 400px) {
    max-width: calc(100vw - 40px);
  }
}

@mixin label($size: 'medium') {
  font-size: 11px;
  font-weight: 600;
  color: var(--color-text-light);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 1;

  @media all and (max-width: 900px) {
    font-size: 10px;
  }

  @if $size == 'small' {
    font-size: 9px;

    @media all and (max-width: 900px) {
      font-size: 8px;
    }
  }
  @if $size == 'large' {
    font-size: 13px;

    @media all and (max-width: 900px) {
      font-size: 12px;
    }
  }
}

@mixin button($color: 'algae', $size: 'large') {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.01em;
  line-height: 1;
  border-radius: var(--border-radius-large);
  transition: all 0.3s var(--ease);

  > svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    vertical-align: middle;
  }

  @if $size == 'xlarge' {
    height: 60px;
    padding: 0 25px 0px;
    font-size: 18px;
  }

  @if $size == 'large' {
    height: 50px;
  }

  @if $size == 'input' {
    height: 48px;
  }

  @if $size == 'medium' {
    height: 40px;
    padding: 0 15px;
    font-size: 15px;

    > svg {
      height: 18px;
      width: 18px;
    }
  }

  @if $size == 'small' {
    height: 32px;
    padding: 0 12px;
    font-size: 14px;
    border-radius: calc(var(--border-radius-large) * 0.75);

    > svg {
      height: 16px;
      width: 16px;
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  :global(.keys) &:focus {
    outline: none;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -5px;
      left: -5px;
      height: calc(100% + 10px);
      width: calc(100% + 10px);
      border: 2px solid var(--color-focus-outline);
      border-radius: var(--border-radius-small);
    }
  }

  // Algae
  @if $color == 'algae' {
    background-color: var(--color-algae);
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);

    > svg {
      path {
        fill: var(--color-text);
      }
    }

    &:hover:not(:disabled),
    :global(.keys) &:focus:not(:disabled) {
      background-color: var(--color-algae-dark);
    }
  }

  // Green
  @if $color == 'green' {
    background-color: var(--color-green);
    color: #fff;

    > svg {
      path {
        fill: #fff;
      }
    }

    &:hover:not(:disabled),
    :global(.keys) &:focus:not(:disabled) {
      background-color: #3d835a;
    }
  }

  // Dark green
  @if $color == 'dark-green' {
    background-color: var(--color-dark-green);
    color: #fff;

    > svg {
      path {
        fill: #fff;
      }
    }

    &:hover:not(:disabled),
    :global(.keys) &:focus:not(:disabled) {
      background-color: hsl(144, 45%, 20%);
    }
  }

  // Blue
  @if $color == 'blue' {
    background-color: var(--color-blue);
    color: #fff;

    > svg {
      path {
        fill: #fff;
      }
    }

    &:hover:not(:disabled),
    :global(.keys) &:focus:not(:disabled) {
      background-color: #152a3d;
    }
  }

  // Light blue
  @if $color == 'light-blue' {
    background-color: var(--color-light-blue);
    color: var(--color-text-blue);

    > svg {
      path {
        fill: var(--color-text-blue);
      }
    }

    &:hover:not(:disabled),
    :global(.keys) &:focus:not(:disabled) {
      background-color: #d4dce4;
    }
  }

  // Light terracotta
  @if $color == 'light-terracotta' {
    background-color: var(--color-light-terracotta);
    color: var(--color-terracotta);

    > svg {
      path {
        fill: var(--color-terracotta);
      }
    }

    &:hover:not(:disabled),
    :global(.keys) &:focus:not(:disabled) {
      background-color: #e8d6c3;
    }
  }

  // Dark
  @if $color == 'dark' {
    background-color: var(--color-text);
    color: #fff;

    > svg {
      path {
        fill: #fff;
      }
    }

    &:hover:not(:disabled),
    :global(.keys) &:focus:not(:disabled) {
      background-color: var(--color-dark-green);
    }
  }

  // Dark outline
  @if $color == 'dark-outline' {
    background-color: transparent;
    color: var(--color-text);
    border: 1px solid var(--color-text);

    > svg {
      path {
        color: var(--color-text);
      }
    }

    &:hover:not(:disabled),
    :global(.keys) &:focus:not(:disabled) {
      background-color: var(--color-text);
      color: #fff;

      > svg {
        path {
          fill: #fff;
        }
      }
    }
  }

  // Grey
  @if $color == 'grey' {
    background-color: var(--color-borders);

    &:hover:not(:disabled),
    :global(.keys) &:focus:not(:disabled) {
      background-color: #e6e6e6;
    }
  }

  @if $color == 'subtle' {
    background-color: transparent;
    border: 2px solid var(--color-borders);
    font-weight: 400;

    &:hover:not(:disabled),
    :global(.keys) &:focus:not(:disabled) {
      background-color: var(--color-borders);
    }
  }
}

@keyframes raceBy {
  0% {
    transform: translateX(-60%);
  }
  100% {
    transform: translateX(100%);
  }
}

@mixin content-placeholder($bgColor: var(--color-background)) {
  position: relative;
  overflow: hidden;
  background-color: $bgColor;
  border-radius: var(--border-radius-large);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      90deg,
      rgba(8, 28, 16, 0),
      rgba(8, 28, 16, 0.1) 30%,
      rgba(8, 28, 16, 0.1) 25%,
      rgba(8, 28, 16, 0) 60%,
      rgba(8, 28, 16, 0)
    );
    animation: raceBy 1.25s ease infinite;
  }
}

@mixin shadowPulse($scaleX: 2, $scaleY: 2, $opacity: 1, $duration: 2.75s) {
  position: relative;

  > * {
    position: relative;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: inherit;
    animation-name: shadowPulse;
    animation-iteration-count: infinite;
    animation-duration: $duration;
    border-radius: inherit;
    opacity: $opacity;
  }

  @keyframes shadowPulse {
    0% {
      transform: scale(1);
      opacity: $opacity;
    }
    50% {
      transform: scale($scaleX, $scaleY);
      opacity: 0;
    }
    100% {
      transform: scale($scaleX, $scaleY);
      opacity: 0;
    }
  }
}

@function url-friendly-colour($colour) {
  @return '%23' + str-slice('#{$colour}', 2, -1);
}

@mixin dashedBorder($color: #bfbfbf) {
  border-radius: var(--border-radius-large);
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='#{url-friendly-colour($color)}' stroke-width='2' stroke-dasharray='3%2c6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

@mixin focusable(
  $context: 'light',
  $focus-type: 'focus',
  $position: 'outside'
) {
  $selectors: (
    'focus-within': ':global(.keys) &:focus-within',
    'focus': ':global(.keys) &:focus',
  );

  display: inline-block;
  position: relative;
  outline: none;

  #{map-get($selectors, $focus-type)} {
    outline: none;

    &::before {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      top: -5px;
      left: -5px;
      height: calc(100% + 10px);
      width: calc(100% + 10px);
      border: 2px solid var(--color-focus-outline);
      border-radius: var(--border-radius-small);
      background-color: transparent;
      transition: none;

      @if $position == 'inside' {
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }

    @if $context == 'dark' {
      &::before {
        border-color: var(--color-focus-outline-light);
      }
    }
  }
}

@mixin only-keyboard {
  body:not(:global(.keys)) & {
    display: none;
  }
}

@mixin hotkey($hide: false) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  min-width: 24px;
  padding: 6px;
  font-size: 12px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow:
    inset 1px 1px 0 white,
    inset 0 0 4px rgba(0, 0, 0, 0.05),
    2px 3px 4px 0 rgba(0, 0, 0, 0.075),
    0 0 8px rgba(0, 0, 0, 0.075);
  color: var(--color-text);

  @if $hide {
    @include only-keyboard;
  }

  @media (pointer: coarse) {
    display: none;
  }
}

@mixin tooltip($size: 'regular') {
  padding: 25px;
  font-size: 15px;
  line-height: 1.5;
  background-color: var(--color-text);
  color: #fff;

  @if ($size == 'small') {
    font-size: 14px;
    padding: 7px;
  }

  h1 {
    margin-bottom: 0.7em;
    font-size: 17px;
    line-height: 1;
    font-weight: 600;
  }

  a,
  button {
    @include focusable;
    font-weight: normal;
    color: var(--color-algae-darker);
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }

  em {
    display: inline-block;
    backface-visibility: hidden;
    padding: 3px 7px;
    line-height: 1;
    border-radius: var(--border-radius-small);
    background-color: var(--color-dark-green);
    font-style: normal;
  }

  code {
    display: inline-block;
    backface-visibility: hidden;
    padding: 2px 5px;
    line-height: 1;
    font-size: 13px;
    border-radius: var(--border-radius-small);
    background-color: var(--color-text-light);
  }
}
