@use '~Sass/mixins';

.container {
  @include mixins.focusable('light', 'focus-within');
  display: flex;
  align-items: center;

  &:hover .handle {
    transform: scale(1.1);
  }
}

.range_container {
  position: relative;
  flex-grow: 1;

  &::before {
    content: '';
    position: absolute;
    top: calc(50% - 3px);
    left: 0;
    height: 6px;
    width: 100%;
    border-radius: 3px;
    background-color: #dcdccf;
    pointer-events: none;
  }
}

.progress {
  position: absolute;
  top: calc(50% - 3px);
  left: 0;
  height: 6px;
  width: 100%;
  border-radius: 3px;
  background-color: var(--color-dark-green);
  pointer-events: none;
}

.handle_container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: calc(100% - 16px);
  pointer-events: none;
}

.handle {
  position: absolute;
  z-index: 1;
  top: calc(50% - 11px);
  left: 0;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background-color: var(--color-green);
  transition: transform 0.3s var(--ease);
}

.input {
  width: calc(100% + 22px);
  margin-left: -11px;
  height: 30px;
  opacity: 0;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.suffix {
  min-width: 50px;
  flex-shrink: 0;
  padding-left: 15px;
  line-height: 1;
  text-align: right;
}
