@use '~Sass/mixins';

.inner {
  display: grid;
  align-content: flex-start;
  gap: 30px;
  grid-template-columns: repeat(6, 1fr);
  width: 100%;
  padding: 23px 30px 100px;

  hr {
    grid-column: span 6;
    height: 2px;
    width: calc(100% + 60px);
    margin: 0 -30px;
    background-color: var(--color-borders);
  }

  @media all and (max-width: 700px) {
    gap: 20px;
  }

  @media all and (max-width: 600px) {
    min-height: 100%;
  }
}

.header {
  padding: 20px 30px 15px 0;
  background-color: var(--color-background);

  @media all and (min-width: 701px) {
    position: sticky;
    z-index: 2;
    top: 0;
    left: 0;

    &.has_shadow {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    }
  }
}

.back_button {
  @include mixins.focusable;
  @include mixins.label('large');
  position: relative;
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  padding-left: 30px;
  color: var(--color-text);
  border-radius: 0 var(--border-radius-large) var(--border-radius-large) 0;
  transition:
    all 0.3s var(--ease),
    opacity 0.2s var(--ease);

  .has_changes & {
    opacity: 0;
  }

  &:hover {
    background-color: var(--color-green-grey-dark);

    .back_button__back {
      transform: translateX(-3px);

      &::before {
        transform: scale(1.1);
        background-color: var(--color-green-grey-darker);
      }
    }
  }
}

.back_button__back {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding: 0 4px;
  margin: 0 10px 0 -5px;
  position: relative;
  z-index: 1;
  transition: all 0.3s var(--ease);

  &::before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: var(--border-radius-small);
    transition: all 0.3s var(--ease);
  }
}

.back_button__arrow {
  position: relative;
  z-index: 1;
  height: 10px;
  width: auto;
  margin: 0 5px;
  transition: all 0.3s var(--ease);

  path {
    fill: var(--color-text);
  }
}

.title {
  display: flex;
  align-items: center;
  padding: 0 0 15px 30px;
  font-family: var(--font-titles);
  font-weight: 500;
  font-size: 24px;
  transition: all 0.2s var(--ease);
}

.title__icon {
  margin: 5px 15px 0 0;
  transition: all 0.2s var(--ease);

  svg {
    height: 24px;
    width: 24px;

    path {
      fill: var(--color-text-light);
    }
  }
}

.section_title {
  @include mixins.label;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: span 6;

  &::before,
  &::after {
    content: '';
    height: 1px;
    background-color: var(--color-borders-dark);
    flex-grow: 1;
    margin: 0 20px 0 0;
  }

  &::after {
    margin: 0 0 0 20px;
  }
}

.section_subtitle {
  @include mixins.label;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: span 6;
  padding: 10px 0;

  &::before,
  &::after {
    content: '';
    height: 1px;
    background-color: var(--color-borders-dark);
    flex-grow: 1;
    margin: 0 20px 0 0;
  }

  &::after {
    margin: 0 0 0 20px;
  }
}

.actions {
  position: absolute;
  z-index: 1;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: calc(100% - 30px);
  padding-left: 25px;
  padding-right: 30px;
  border-radius: 0 var(--border-radius-large) var(--border-radius-large) 0;
  background-color: var(--color-algae);
  font-weight: 600;
  transition: all 0.2s var(--ease);
  opacity: 0;
  pointer-events: none;

  .has_changes & {
    opacity: 1;
    pointer-events: all;
  }
}

.action_button {
  @include mixins.focusable;
  position: relative;
  padding: 10px 15px;
  font-weight: 400;
  transition:
    all 0.3s var(--ease),
    opacity 0s;
  font-size: 18px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: var(--border-radius-small);
    background-color: var(--color-algae-darker);
    opacity: 0;
    transform: scale(0.75);
    transition: all 0.3s var(--ease);
  }

  svg {
    width: 15px;
    height: auto;
    margin-right: 10px;

    path {
      transition: all 0.3s var(--ease);
    }
  }

  &:hover {
    &::after {
      opacity: 1;
      transform: none;
    }
  }
}

.action_button__inner {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
}

.action_button + .action_button {
  margin-left: 20px;
}

.fieldset {
  display: grid;
  flex-direction: column;
  grid-template-columns: repeat(6, 1fr);
  grid-column: span 6;
  padding: 25px;
  border-radius: var(--border-radius-large);
  background-color: var(--color-green-grey);
  gap: 0 25px;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  > *:not(:first-child):not(.setting_half + .setting_half:nth-child(2)) {
    margin-top: 25px;
  }

  @media all and (max-width: 700px) {
    gap: 0 20px;

    > *:not(:first-child):not(.setting_half + .setting_half:nth-child(2)) {
      margin-top: 20px;
    }
  }
}

.fieldset_invisible {
  padding: 0;
  border-color: transparent;
  background-color: transparent;
}

.transition_wrapper,
.transition_wrapper_switch {
  position: relative;
  grid-column: span 6;
  min-width: 0px;
  max-width: 100%;
}

.setting {
  position: relative;
  grid-column: span 6;
  min-width: 0px;
  max-width: 100%;
}

.setting_loader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
}

.setting_loader__text {
  margin-left: 15px;
  font-size: 0.875em;
}

.setting_two_thirds {
  grid-column: span 4;
}

.setting_half {
  grid-column: span 3;

  @media all and (max-width: 900px) {
    grid-column: span 6;
  }
}

.setting_third {
  grid-column: span 2;

  @media all and (max-width: 900px) {
    grid-column: span 3;
  }
}

.toggle_buttons {
  display: flex;
  gap: 5px;
}

.toggle_button {
  flex-grow: 1;
}

.embed_toggle {
  display: inline-flex;
  margin: 0 0 10px;
  background-color: var(--color-green-grey-dark);
  border-radius: var(--border-radius-large);
}

.size_toggle {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column: span 6;
  min-width: 0px;
  max-width: 100%;
}

.size_toggle__button {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  height: 50px;
  border-radius: var(--border-radius-small);
  width: calc(50% - 5px);
  background-color: var(--color-green-grey-darker);
  transition: all 0.3s var(--ease);

  &.is_current:not(:disabled) {
    background-color: var(--color-dark-green);
    cursor: default;

    svg path {
      fill: #fff;
    }
  }

  &:hover:not(.is_current) {
    background-color: hsl(60, 9%, 85%);
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  svg {
    height: 24px;
    width: 24px;
  }
}

.size_toggle__button_inner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.size_toggle__link {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 10px;

  &::before {
    content: '';
    position: absolute;
    top: 0%;
    left: -15px;
    height: 100%;
    width: calc(100% + 30px);
    border-radius: 12px;
    background-color: var(--color-green-grey);
    transition: all 0.3s var(--ease);
  }

  &::after {
    content: '';
    position: absolute;
    top: -13px;
    left: -15px;
    height: 50px;
    width: calc(100% + 30px);
  }

  &:hover {
    &::before {
      background-color: #fff;
    }
  }

  svg {
    position: relative;
    z-index: 1;
    height: 22px;
    width: 22px;
    flex-shrink: 0;

    path {
      transition: all 0.3s var(--ease);
    }
  }
}

.is_unlinked :global(.link_svg__middle-link) {
  display: none !important;
}

.size_toggle__tooltip {
  @include mixins.tooltip('small');
  text-align: center;
}

.switch_input {
  display: flex;

  .switch_input__input {
    flex-grow: 1;
  }

  .switch_input__switch {
    flex-grow: 0;
    width: auto;
    margin-left: 20px;
  }
}

.label {
  width: 100%;
  grid-column: span 6;
  margin-bottom: 10px;
  font-size: var(--form-label-size);
  font-weight: 600;
}

.hashtags_label {
  margin-bottom: 20px;
}

.tooltip {
  @include mixins.tooltip;
}

.description {
  grid-column: span 6;
  padding: 40px;
  line-height: 1.5;
  border-radius: var(--border-radius-large);
  background: var(--color-green-grey-dark);

  &:first-child {
    margin-top: 0;
  }

  a,
  button {
    @include mixins.focusable;
    text-decoration: underline;
    color: var(--color-text-green);
    font-weight: 600;
  }
}

.description_narrow {
  padding: 30px;
}

.description_narrower {
  padding: 15px 20px;
}

.description_no_padding {
  padding: 0;
}

.description_alert {
  background: var(--color-light-yellow);

  a,
  button {
    color: var(--color-terracotta);
  }
}

.description_darker {
  background: var(--color-green-grey-darker);
}

.load_posts_notice__wrapper {
  grid-column: span 6;
}

.load_posts_notice {
  padding-top: 20px;
}

.breakpoint_label {
  @include mixins.title('h5');
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
}

.breakpoint_toggle {
  position: absolute;
  top: -20px;
  left: -20px;
  height: calc(100% + 40px);
  width: calc(100% + 40px);
}

.breakpoint_edit_container {
  display: flex;
  align-items: center;
}

.breakpoint_actions {
  position: relative;
  z-index: 1;
}

.breakpoint_edit {
  @include mixins.button('grey', 'small');
  padding: 0 10px;
  margin-left: 5px;

  &.hovered {
    background-color: #e6e6e6;
  }

  svg {
    margin: 0;
  }
}

.breakpoint_edit_cancel {
  @include mixins.button('grey', 'input');
  margin-left: 5px;

  svg {
    height: 16px;
    width: 16px;
    margin: 0;
  }
}

.breakpoint {
  grid-column: span 6;
  display: grid;
  grid-template-columns: repeat(6, minmax(0px, 1fr));
  gap: 0 30px;
  padding: 20px;
  border: 2px solid var(--color-borders);
  border-radius: var(--border-radius-large);
}

.breakpoint__inner {
  margin-top: 20px;
}

.add_breakpoint {
  @include mixins.button('grey', 'xlarge');
  width: 100%;
}

.footer {
  position: sticky;
  bottom: 0;
  z-index: 2;
  grid-column: span 6;
  width: calc(100% + 60px);
  padding: 20px 30px;
  margin: -50px -30px 0;
  background-color: var(--color-background);

  @media all and (min-width: 601px) {
    &.shadow {
      box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.075);
    }
  }

  @media all and (max-width: 700px) {
    margin: -40px -30px 0;
  }

  @media all and (max-width: 600px) {
    position: relative;
    z-index: 0;
  }
}

.drawers {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  grid-column: span 6;
  width: calc(100% + 60px);
  margin: 0 -30px;
}

.widget_type_select {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
}

.widget_type_select__premium {
  padding-bottom: 20px;
}

.widget_type_select__icon {
  display: flex;
  margin-right: 30px;

  svg {
    height: 35px;
    max-width: 35px;

    path {
      fill: var(--color-dark-green);
    }
  }
}

.widget_type_select__title {
  width: 100%;
  font-size: 18px;
  margin-bottom: 7px;
  font-weight: 600;
}

.widget_type_select__text {
  opacity: 0.8;
  font-size: 14px;
  line-height: 1.2;
}

.demo_embed_overlay {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 20px;
  background-color: rgba(12, 41, 24, 0.5);
  backdrop-filter: blur(4px);
  border-radius: var(--border-radius-small);
  color: #fff;
  text-align: center;
  line-height: 1.25;

  a {
    display: inline;
    color: #72d69b;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }
  }
}

.klaviyo_code_lit {
  height: 200px;
}

.klaviyo_code_upgrade {
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius-large);
}

.klaviyo_code_upgrade__inner {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18px;
  background-image: linear-gradient(
    to bottom,
    hsl(145 55% 10% / 50%),
    hsl(145 55% 10% / 0%)
  );

  p {
    max-width: 300px;
    text-align: center;
    line-height: 1.5;
    margin-bottom: 20px;
  }

  b {
    color: hsl(145, 33%, 60%);
  }

  a {
    @include mixins.button('green');
  }

  svg {
    height: 24px;
    width: 24px;
  }
}

.klaviyo_code_upgrade__lit {
  height: 200px;
  user-select: none;
  pointer-events: none;

  code {
    filter: blur(3px);
    opacity: 0.5;
  }
}
