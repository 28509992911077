@use '~Sass/mixins';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  padding-bottom: 10vh;

  svg {
    height: 40px;
    margin-bottom: 60px;
  }
}

.title {
  @include mixins.title('h1');
}

.subtitle {
  max-width: 500px;
  margin-top: 30px;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  line-height: 1.35;
  opacity: 0.8;
}
