@use '~Sass/mixins';

.container {
  @include mixins.focusable('light', 'focus-within');
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  border-radius: 10px;
  color: var(--color-text);

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  :global(.keys) &:focus .title {
    text-decoration: underline;
  }
}

.container + .container {
  margin-top: 15px;

  @media all and (max-width: 550px), (max-height: 480px) {
    margin-top: 10px;
  }
}

.label {
  opacity: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.input {
  opacity: 0;
  pointer-events: none;
  width: 0;
  height: 0;
}

.button {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.toggle + .title {
  margin-right: 0px;
  margin-left: 15px;
}

.before,
.after {
  position: relative;
  z-index: 1;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  transition: all 0.3s ease;
  transition-delay: 0s;
  color: var(--color-text);
  font-size: var(--form-label-size);
}

.before {
  margin-right: 15px;
}

.after {
  margin-left: 15px;
}

.before svg,
.after svg {
  margin-right: 15px;
  height: 18px;
  width: 18px;

  path {
    transition: all 0.3s ease;
    stroke: var(--color-borders);
  }

  @media all and (max-width: 900px), (max-height: 580px) {
    height: 16px;
    width: 16px;
    margin-right: 16px;
  }
}

.graphic {
  position: relative;
  display: block;
  height: 20px;
  width: 36px;
  border-radius: 10px;
  background-color: hsl(0 0% 0% / 0.125);
  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden;
  flex-shrink: 0;

  .toggle & {
    background-color: var(--color-dark-green);
  }
}

.graphic::after {
  content: '';
  position: absolute;
  display: block;
  top: 2px;
  left: 2px;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(6, 41, 56, 0.125);

  .toggle & {
    background-color: #fff;
  }
}

.container:not(.toggle).checked .graphic {
  background-color: var(--color-green);
}

.container.checked .graphic::after {
  left: 18px;
}

.loading_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  justify-self: flex-start;
}
