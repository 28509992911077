@use '~Sass/mixins';

.container {
  position: relative;
  display: flex;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: flex-start;
}

.inner {
  position: relative;
  padding-top: 100px;
  max-width: calc(100vw - 60px);

  @media all and (max-width: 600px) {
    padding-top: 40px;
  }
}

.logo {
  margin-bottom: 80px;
  text-align: center;

  svg {
    height: 36px;
    vertical-align: middle;
  }

  @media all and (max-width: 600px) {
    margin-top: 10px;
    margin-bottom: 60px;
    text-align: left;

    svg {
      height: 30px;
    }
  }

  @media all and (max-height: 650px) {
    margin-top: 0;
    margin-bottom: 40px;

    svg {
      height: 30px;
    }
  }
}

.logo {
  margin-bottom: 80px;
  text-align: center;

  svg {
    height: 36px;
    vertical-align: middle;
  }

  @media all and (max-width: 600px) {
    margin-top: 10px;
    margin-bottom: 60px;
    text-align: left;

    svg {
      height: 30px;
    }
  }

  @media all and (max-height: 650px) {
    margin-top: 0;
    margin-bottom: 40px;

    svg {
      height: 30px;
    }
  }
}

.error {
  padding: 20px;
  margin-bottom: 20px;
  border-radius: var(--border-radius-small);
  text-align: center;
  background-color: var(--color-light-yellow);
  font-size: 18px;
  line-height: 1.5;
  border: 1px solid var(--color-yellow);

  p + p {
    margin-top: 30px;
  }

  @media all and (max-width: 600px) {
    font-size: 16px;
    line-height: 1.35;
  }
}

.section_title {
  margin-bottom: 35px;
  font-size: 18px;

  @media all and (max-width: 600px) {
    margin-bottom: 30px;
  }
}

.email {
  width: 350px;
  max-width: 100%;
  padding: 40px;
  background-color: #fff;
  border-radius: var(--border-radius-large);
  box-shadow: var(--shadow1);

  @media all and (max-width: 400px) {
    padding: 30px;
  }
}

.form_item {
  display: block;
  margin-bottom: 30px;
}

.form_item__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.label {
  @include mixins.label;
  font-size: 12px;
}

.message {
  padding: 40px;
  border-radius: var(--border-radius-large);
  text-align: center;
  background-color: #fff;
  font-size: 20px;
  line-height: 1.5;
  box-shadow: var(--shadow1);

  p + p {
    margin-top: 30px;
  }

  .button {
    margin-top: 0;
  }

  @media all and (max-width: 400px) {
    padding: 30px;
  }
}

.button {
  @include mixins.button('dark');
  margin-top: 30px;

  svg {
    margin-left: 15px;
    margin-right: 0;
  }
}
.footer {
  margin-top: 40px;
  padding-bottom: 40px;
  text-align: center;
  font-size: 18px;

  a {
    @include mixins.button('subtle');
    border-color: transparent;

    svg {
      height: 10px;
    }
  }

  @media all and (max-width: 600px) {
    margin-top: 35px;
    font-size: 16px;
  }
}
