@use './mixins';

@import url('https://use.typekit.net/ivv6kun.css');
@import './normalize';
@import './variables';
@import './prism-theme';

::-moz-selection {
  background-color: rgba(8, 27, 16, 0.99);
  color: #fff;
}

::selection {
  background-color: rgba(8, 27, 16, 0.99);
  color: #fff;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  -webkit-tap-highlight-color: rgba(6, 41, 56, 0);
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: var(--font-body);
  font-size: 16px;
  line-height: 1;
  color: var(--color-text);
  background-color: var(--color-background);
  font-weight: 400;
  overflow: hidden;

  @media all and (max-width: 600px) {
    overflow: auto;
    font-size: 15px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-body);
  font-weight: 400;
  margin: 0;
  padding: 0;
  font-size: 1em;
}

a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
}

p {
  margin: 0;
}

p + p {
  margin-top: 1em;
}

em {
  font-style: italic;
}

button,
a[type='button'] {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: var(--color-text);
}

input,
textarea {
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 44px;
  color: var(--color-text);
  border: none;
  outline: none;
  border-radius: var(--border-radius-small);
  font-size: 1em;
}

textarea {
  height: 100px;
}

hr {
  border: none;
  height: 1px;
  width: 100%;
  margin: 0;
  background-color: var(--color-borders);
}
