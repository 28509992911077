@use 'Sass/mixins';

.input[type='text'],
.input[type='number'],
.input[type='email'],
.input[type='url'],
.input[type='password'] {
  width: 100%;
  outline: none;
  vertical-align: middle;
  -webkit-appearance: none;

  &:not(.unstyled) {
    height: var(--input-height);
    box-shadow:
      0 0 0 1px var(--color-input-border),
      0 0 0 2px var(--color-input-border-shadow);
    background-color: #fff;
    transition: box-shadow 0.3s var(--ease);

    &:hover {
      box-shadow:
        0 0 0 1px var(--color-input-border-hover),
        0 0 0 2px var(--color-input-border-shadow);

      .container & {
        box-shadow: none;
      }
    }

    &:focus {
      box-shadow:
        0 0 0 1px #326647,
        0 0 0 4px rgba(0, 0, 0, 0.035);

      .container & {
        box-shadow: none;
      }
    }
  }

  &.size_small {
    height: 34px;
  }

  &.size_large {
    height: 50px;
  }

  .container & {
    box-shadow: none;
  }

  &::-ms-clear {
    display: none;
  }

  &:disabled {
    opacity: 0.5;
  }

  &:disabled + .button {
    opacity: 0.4;
  }
}

.input[type='color'] {
  padding: 5px;
  width: 100%;
  border-radius: var(--border-radius-small);
  background-color: var(--color-borders);

  &:disabled {
    opacity: 0.5;
  }
}

.container {
  display: flex;
  position: relative;
  box-shadow:
    0 0 0 1px var(--color-input-border),
    0 0 0 2px var(--color-input-border-shadow);
  border-radius: var(--border-radius-small);
  transition: all 0.3s var(--ease);
  background-color: #fff;

  &:hover {
    box-shadow:
      0 0 0 1px var(--color-input-border-hover),
      0 0 0 2px var(--color-input-border-shadow);
  }

  &.focused {
    box-shadow:
      0 0 0 1px #326647,
      0 0 0 4px rgba(0, 0, 0, 0.035);
  }
}

.button {
  @include mixins.focusable;
  display: flex;
  align-items: center;
  height: 32px;
  margin: 4px 4px 0 0;
  padding: 0 15px;
  margin-left: 2px;
  background-color: var(--color-green);
  border-radius: var(--border-radius-small);
  transition: all 0.3s var(--ease);
  color: #fff;

  &.size_small {
    height: 26px;
  }

  .size_large & {
    height: 42px;
  }

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
    background-color: #eee;
  }

  &:hover,
  :global(.keys) &:focus {
    background-color: #3d835a;
  }
}

.reveal {
  @include mixins.focusable;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 45px;
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  opacity: 0.5;
  transition: 0.4s var(--ease);

  &::after {
    content: '';
    position: absolute;
    top: calc(50% + 0px);
    right: calc(50% - 2px);
    height: 24px;
    width: 2px;
    transform: translate(-50%, -50%) rotate(45deg) scaleY(0);
    transition: all 0.4s var(--ease);
    background-color: var(--color-text);
  }

  &.hide {
    &::after {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  &:hover,
  :global(.keys) &:focus {
    opacity: 1;
  }

  svg {
    width: 18px;
    height: 18px;
    vertical-align: middle;

    path {
      fill: #081b10;
    }
  }
}
