@use '~Sass/mixins';

.container {
  padding: 0 20px;

  @media all and (max-height: 950px) {
    display: none;
  }
}

.inner {
  padding: 30px 25px 40px;
  background: linear-gradient(180deg, hsl(69, 33%, 83%), hsl(69, 33%, 83%, 0%));
  border-radius: var(--border-radius-large);
  text-align: center;
  font-size: 15px;
}

.title {
  @include mixins.title('h3');
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;

  svg {
    height: 22px;
    width: 22px;
    margin-right: 10px;
  }
}

.description {
  line-height: 1.35;
}

.button {
  @include mixins.button('dark-green', 'medium');
  margin-top: 20px;
}
