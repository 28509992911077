@use '~Sass/mixins';

.container {
  @include mixins.focusable('light');
  display: inline-flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: var(--color-green-grey-dark);
  border-radius: var(--border-radius-small);
  border: none;
  transition: all 0.2s var(--ease);
  mix-blend-mode: multiply;

  &.medium {
    font-size: var(--form-label-size);
  }

  &.large {
    padding: 15px;
  }

  svg {
    height: 16px;
    width: 16px;
    flex-shrink: 0;

    path {
      fill: var(--color-text);
      transition: all 0.2s var(--ease);
    }
  }

  &.icon_only {
    svg {
      height: 16px;
      width: 16px;
    }
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:hover {
    background-color: var(--color-green-grey-darker);
  }

  &.checked {
    background-color: var(--color-dark-green);
    color: #fff;

    svg {
      path {
        fill: #fff;
      }
    }

    &:hover {
      background-color: var(--color-text);
    }
  }
}

.tooltip_trigger {
  flex-grow: 1;

  .container {
    width: 100%;
  }
}

.tooltip {
  @include mixins.tooltip('small');
  text-align: center;
}
