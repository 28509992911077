@use '~Sass/mixins';

.container {
  position: relative;
  display: flex;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: flex-start;
}

.inner {
  position: relative;
  width: 480px;
  padding-top: 100px;
  max-width: calc(100vw - 60px);
}

.logo {
  margin-bottom: 80px;
  text-align: center;

  svg {
    height: 45px;
    width: 160px;
    vertical-align: middle;
  }

  @media all and (max-width: 600px) {
    margin-top: 10px;
    margin-bottom: 60px;

    svg {
      height: 40px;
    }
  }
}

.error {
  padding: 30px;
  margin-bottom: 20px;
  border-radius: var(--border-radius-large);
  text-align: center;
  font-size: 18px;
  line-height: 1.5;
  background-color: var(--color-lighter-terracotta);

  p + p {
    margin-top: 30px;
  }
}

.message {
  padding: 40px;
  border-radius: var(--border-radius-large);
  text-align: center;
  background-color: var(--color-green-grey-dark);
  font-size: 18px;
  line-height: 1.5;
  opacity: 0;
  transform: scale(0.95);
  transition: all 0.6s var(--ease);

  .loaded & {
    opacity: 1;
    transform: none;
  }

  svg {
    height: 50px;
    width: 60px;
    vertical-align: middle;
  }

  p + p {
    margin-top: 30px;
  }
}

.message__title {
  @include mixins.title('h2');
  margin: 30px 0 20px;
  font-weight: 600;
}

.message__button {
  @include mixins.button('green');
  margin-top: 20px;
}

.send_again {
  text-decoration: underline;
  color: var(--color-text-green);
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}
