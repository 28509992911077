@use 'Sass/mixins';

.container {
  position: relative;
  height: 100%;
  min-width: 0px;
  border-radius: var(--border-radius-large);
  cursor: pointer;
  will-change: transform;
  transform-origin: center bottom;
  background-color: var(--color-text);
  color: #fff;
  transition: all 0.35s var(--ease);

  @media all and (hover: hover) {
    &:hover {
      .background_image__inner {
        opacity: 0.65;
        transform: scale(1.035);
      }
    }
  }
}

.menu {
  position: absolute;
  z-index: 6;
  top: 10px;
  right: 10px;
}

.toggle_button {
  @include mixins.focusable;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: var(--border-radius-large);
  mix-blend-mode: multiply;
  transition: all 0.3s var(--ease);

  :global(.keys) & {
    display: none;
  }
}

.top {
  position: absolute;
  z-index: 6;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  pointer-events: none;
}

.title {
  max-width: calc(100% - 35px);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.25;
  word-wrap: break-word;
}

.title_inner {
  @include mixins.clippedText;
  padding-left: 4px;
}

.title__input {
  height: 34px;
  margin: -7.5px 0 0 -6px;
  resize: none;
  background-color: transparent;
  color: #fff;
  overflow: hidden;
  transition: all 0.3s var(--ease);
  pointer-events: all;

  &:hover,
  &:focus {
    background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(4px);
    box-shadow: var(--shadow0);
  }

  :global(.keys) &:focus {
    box-shadow: 0 0 0 2px var(--color-focus-outline-light);
  }
}

.bottom {
  position: absolute;
  z-index: 4;
  bottom: 10px;
  left: 10px;
  width: calc(100% - 20px);
  padding: 10px;
  border-radius: var(--border-radius-large);
  pointer-events: none;

  svg {
    path {
      fill: var(--icon-color);
      transition: all 0.3s var(--ease);
    }
  }
}

.stat {
  display: flex;
  align-items: center;
  padding-top: 11px;
  pointer-events: all;

  svg {
    height: 18px;
    width: 18px;
    margin-right: 10px;
  }
}

.tooltip {
  background-color: #fff;
  padding: 7px 5px;
  text-align: center;
  font-size: 0.875em;
}

.background_image {
  position: relative;
  z-index: 1;
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: var(--border-radius-large);
  transition: all 0.4s var(--ease);
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center top;
    vertical-align: middle;
  }

  &::before {
    content: '';
    height: 60%;
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background: linear-gradient(
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.25),
      rgba(0, 0, 0, 0)
    );
  }

  &::after {
    content: '';
    height: 80%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      195deg,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.45),
      rgba(0, 0, 0, 0.8)
    );
  }
}

.background_image__inner {
  width: 100%;
  height: 100%;
  transition: all 0.4s var(--ease);
}

.photo {
  width: 100%;
  height: 100%;
}

.video {
  width: 100%;
  height: 100%;
}

.error {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 30px 20px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(3px);
  border-radius: var(--border-radius-large);
  cursor: default;
  font-size: 15px;

  @supports not (backdrop-filter: blur(3px)) {
    background-color: rgba(0, 0, 0, 0.85);
  }

  p {
    line-height: 1.35;
  }

  b {
    font-size: 18px;
  }

  a{
    text-decoration: underline;
  }

  button {
    @include mixins.button('light-blue', 'medium');
    margin-top: 5px;
  }
}
