.container {
  position: relative;
  width: 300px;
  height: 100%;
  transition: all 0.25s var(--ease);
  overflow: hidden;
  will-change: transform;
}

.base_panel {
  position: relative;
  height: 100%;
  padding-bottom: 60px;
  overflow: auto;
  transition: all 0.25s var(--ease);

  &.hidden {
    transform: translateX(-100%);
  }

  @media all and (max-width: 700px) {
    padding-bottom: 0;
  }
}

.panel {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #fff;
  transition: all 0.25s var(--ease);
  overflow: auto;
  scrollbar-width: none;

  @media all and (min-width: 601px) {
    &::after {
      content: '';
      position: fixed;
      z-index: 1;
      bottom: 0;
      left: 0;
      height: 50px;
      width: 100%;
      background-image: linear-gradient(
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.08),
        rgba(0, 0, 0, 0.15)
      );
      transition: all 0.3s var(--ease);
      opacity: 0;
      pointer-events: none;
    }

    &.bottom_shadow::after {
      opacity: 1;
    }
  }

  &::-webkit-scrollbar {
    width: 0 !important;
  }
}
