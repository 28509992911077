@use '~Sass/mixins';

.container {
  width: 450px;
  max-width: 100%;
  padding: 40px 0;
  background-color: #fff;
  border-radius: var(--border-radius-large);

  @media all and (max-width: 400px) {
    padding: 30px 0;
  }
}

.header {
  padding: 0 0 40px;

  @media all and (max-width: 400px) {
    padding: 0 0 30px;
  }
}

.title {
  @include mixins.title('h2');
  display: flex;
  align-items: center;
  padding: 0 40px;

  @media all and (max-width: 400px) {
    padding: 0 30px;
  }

  svg {
    height: 22px;
    margin-right: 15px;

    path {
      fill: var(--color-text-light);
    }
  }
}

.intro {
  padding: 20px 40px;
  background-color: var(--color-green-grey);
  line-height: 1.35;

  @media all and (max-width: 400px) {
    padding: 20px 30px;
  }
}

.form {
  padding: 30px 40px 0px;

  @media all and (max-width: 400px) {
    padding: 20px 30px 0px;
  }
}

.auth_types {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;
}

.auth_type {
  width: 100%;
  padding: 0 15px;
  border-radius: var(--border-radius-large);
  border: 1px solid var(--color-borders-dark);

  &.is_active {
    border: 1px solid var(--color-borders-dark);
  }
}

.auth_type__inner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 60px;
  min-width: 0px;
}

.auth_type__title {
  display: flex;
  align-items: center;
  min-width: 0px;

  svg {
    height: 20px;
    width: 20px;
    margin-right: 10px;
    flex-shrink: 0;
  }
}

.auth_type__actions {
  display: flex;
  gap: 5px;
  margin-left: auto;
  flex-shrink: 0;
  padding-left: 7px;
}

.trash_button,
.edit_button {
  @include mixins.focusable;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: var(--border-radius-small);
  transition: all 0.3s var(--ease);
  background-color: var(--color-green-grey);

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  svg {
    height: 16px;
    width: 16px;
  }
}

.edit_button {
  &:hover,
  :global(.keys) &:focus {
    background-color: #fff;
    box-shadow:
      0 0 0 1px #276841,
      0 0 0 4px rgba(0, 0, 0, 0.04),
      0px 33px 72px rgba(92, 87, 67, 0.05),
      0px 13.7866px 30.0799px rgba(145, 132, 81, 0.036),
      0px 7.37098px 16.0821px rgba(92, 87, 67, 0.0298054),
      0px 4.13211px 9.01552px rgba(92, 87, 67, 0.025),
      0px 2.19453px 4.78807px rgba(92, 87, 67, 0.0201946),
      0px 0.913195px 1.99242px rgba(92, 87, 67, 0.0140573);
  }
}

.trash_button {
  &:hover,
  :global(.keys) &:focus {
    background-color: #fff;
    box-shadow:
      0 0 0 1px var(--color-terracotta),
      0 0 0 4px rgba(0, 0, 0, 0.04),
      0px 33px 72px rgba(92, 87, 67, 0.05),
      0px 13.7866px 30.0799px rgba(145, 132, 81, 0.036),
      0px 7.37098px 16.0821px rgba(92, 87, 67, 0.0298054),
      0px 4.13211px 9.01552px rgba(92, 87, 67, 0.025),
      0px 2.19453px 4.78807px rgba(92, 87, 67, 0.0201946),
      0px 0.913195px 1.99242px rgba(92, 87, 67, 0.0140573);

    svg {
      path {
        fill: var(--color-terracotta);
      }
    }
  }
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: var(--border-radius-large);
  background-color: hsl(0, 0%, 100%, 0.9);
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 0 40px;

  @media all and (max-width: 400px) {
    padding: 0 30px;
  }
}

.close_button {
  @include mixins.button('grey');
}

.tooltip {
  @include mixins.tooltip('small');
  text-align: center;
}

.label,
.label_inactive {
  @include mixins.label('small');
  display: flex;
  align-items: center;
  height: 22px;
  padding: 0 8px;
  margin-left: 10px;
  background-color: var(--color-dark-green);
  border-radius: 11px;
  color: #fff;

  @media all and (max-width: 500px) {
    display: none;
  }
}

.label_inactive {
  background-color: var(--color-green-grey);
  color: var(--color-text);
}

.ellipsis_overflow {
  flex-grow: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.1;
}

.new_email_login__footer {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
}

.submit_button {
  @include mixins.button('green', 'medium');
}

.note {
  padding: 20px;
  margin-bottom: 20px;
  border-radius: var(--border-radius-large);
  background-color: #f6f6f6;
  font-size: 0.875em;
  line-height: 1.35;
}
