@use '~Sass/mixins';

.container {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 30px;

  @media all and (max-width: 1200px) {
    padding: 0 20px 20px;
  }

  @media all and (max-height: 700px) {
    display: none;
  }
}

.stat {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 20px;
  border-radius: var(--border-radius-small);

  @media all and (max-width: 600px) {
    padding-left: 10px;
  }
}

.stat + .stat{
  margin-top: 20px;

  @media all and (max-width: 600px) {
      margin-top: 15px;
    }
}

.stat__inner {
  position: relative;
  opacity: 0;
  transform: translateX(-10px);
  transition: all 0.4s var(--ease);

  .stat_loaded & {
    opacity: 1;
    transform: none;
  }
}

.stat__loader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .stat_loaded & {
    display: none;
  }
}

.stat__title {
  @include mixins.label;
  margin-bottom: 5px;

  @media all and (max-width: 600px) {
    margin-bottom: 3px;
  }
}

.stat__text {
  display: flex;
  align-items: center;
  font-size: 18px;

  a {
    @include mixins.focusable;
  }

  @media all and (max-width: 900px) {
    font-size: 18px;
  }

  @media all and (max-width: 650px) {
    font-size: 15px;
  }
}
