@use '~Sass/mixins';

.container {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: fixed;
  z-index: 11;
  top: 20px;
  left: 20px;
  height: calc(100vh - 40px);
  width: calc(var(--sidebar-width) - 20px);
  background-color: var(--color-green-grey-dark);
  border-radius: var(--border-radius-xlarge);
  overflow: auto;

  hr {
    flex-shrink: 0;
    background-color: var(--color-green-grey-darker);
  }
}

.nav {
  display: flex;
  flex-direction: column;
  padding: 54px 0 0 0;
  margin-bottom: auto;

  @media all and (max-width: 1200px) {
    padding: 49px 0 0 0px;
  }
}

.logo {
  height: 34px;
  margin: 0 0 50px 33px;

  svg {
    height: 100%;
  }

  @media all and (max-width: 1200px) {
    margin: 0 0 50px 35px;
  }
}

.nav__item {
  @include mixins.focusable('light', 'focus', 'inside');
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 70px;
  padding: 0 40px;

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 5px;
    left: 20px;
    height: calc(100% - 10px);
    width: calc(100% - 40px);
    background-color: var(--color-green-grey-darker);
    border-radius: var(--border-radius-large);
    opacity: 0;
    transition: all 0.3s var(--ease);
  }

  &.current {
    &::after {
      opacity: 1;
    }
  }

  &:hover:not(.current) {
    &::after {
      opacity: 0.5;
    }
    .count {
      background-color: var(--color-borders-dark);
    }
    .live_chat__indicator {
      box-shadow: 0 0 0 3px var(--color-green-grey-darker);
    }
  }

  svg {
    height: 20px;
    width: 20px;
    margin-right: 20px;

    path {
      fill: var(--color-text);
    }
  }

  @media all and (max-width: 1200px) {
    padding-left: 42px;

    svg {
      margin-right: 10px;
    }
  }
}

.live_chat__indicator {
  position: absolute;
  top: calc(50% - 11px);
  left: 43px;
  display: block;
  height: 6px;
  width: 6px;
  margin-left: 12px;
  border-radius: 50%;
  background-color: #3ca566;
  box-shadow: 0 0 0 3px var(--color-green-grey);
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s var(--ease);

  .current & {
    box-shadow: 0 0 0 3px var(--color-green-grey-darker);
  }

  &.live_chat__online {
    @include mixins.shadowPulse(2.75, 2.75, 1, 2.25s);
    position: absolute;
    transform: none;
    opacity: 1;
  }

  @media all and (max-width: 1200px) {
    left: 45px;
  }
}

.count {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 21px;
  min-width: 21px;
  padding: 0 5px;
  margin-left: auto;
  border-radius: 10.5px;
  color: var(--color-text-light);
  font-size: 12px;
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0.075);
  transition: all 0.3s var(--ease);

  .current & {
    background-color: rgba(0, 0, 0, 0.075);
  }
}

.notifications {
  height: 70px;
  padding: 0 35px 0 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;

  &:hover {
    background-color: hsl(60, 12%, 92%);
  }

  svg {
    height: 20px;
    width: 20px;
    margin-right: 20px;
  }
}

.account {
  @include mixins.focusable('light', 'focus', 'inside');
  position: relative;
  display: flex;
  height: 90px;
  width: 100%;
  padding: 0 30px;
  border-radius: 0;
  align-items: center;
  justify-content: flex-start;
  font-size: 15px;
  line-height: 1;
  border-radius: 0 0 20px 20px;

  @media all and (max-width: 1200px) {
    padding: 0 20px;
  }

  svg {
    position: relative;
    z-index: 1;
    height: 15px;
    width: 15px;
    flex-shrink: 0;
    margin: 2px 0 0 auto;
  }

  &:hover {
    background-color: hsl(60, 12%, 92%);
  }
}

.avatar {
  position: relative;
  z-index: 1;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: var(--color-text);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.account__email {
  @include mixins.clippedText;
  position: relative;
  z-index: 1;
}
