@use '~Sass/mixins';

.container {
  width: 100%;
  max-width: var(--layout-width);
}

.section {
  max-width: 500px;
  margin-bottom: 50px;
}

.section_title {
  @include mixins.title('h5');
  margin-bottom: 20px;
}

.docs {
  background-color: #fff;
  box-shadow: var(--shadow1);
  border-radius: var(--border-radius-large);
  overflow: hidden;
}

.docs__link {
  @include mixins.focusable('light', 'focus', 'inside');
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  padding: 22px 25px;
  transition: all 0.3s var(--ease);

  &::after {
    content: '';
    height: 8px;
    width: 8px;
    margin-left: 15px;
    background-image: url(~Images/icons/caret-right.svg);
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 0.3s var(--ease);
  }

  &:hover {
    background-color: #f8f9fa;

    &::after {
      transform: translateX(3px);
    }
  }
}

.docs__link + .docs__link {
  border-top: 1px solid var(--color-borders);
}

.news__link_title {
  display: flex;
  flex-direction: column;
  font-size: 1.125em;
}

.news__link_meta {
  display: block;
  margin-bottom: 10px;
  font-size: 0.725em;

  em {
    font-style: normal;
    color: var(--color-text-green);
    font-weight: 600;
  }
}

.text {
  a {
    color: var(--color-blue);
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
}
