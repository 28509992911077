.trigger {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  flex-shrink: 0;
  transition: all 0.3s var(--ease);

  &:not(.unstyled) svg {
    position: relative;
    height: 4px;
    flex-shrink: 0;
    opacity: 0.75;

    path,
    circle {
      fill: var(--color-text);
    }
  }

  &:not(.unstyled).white {
    svg {
      opacity: 1;

      path,
      circle {
        fill: #fff;
      }
    }

    &::before {
      background-color: rgba(255, 255, 255, 0.15);
      backdrop-filter: blur(4px);
    }
  }

  &:not(.unstyled)::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.05);
    transform: scale(0);
    transition: all 0.3s var(--ease);
  }

  &:not(.unstyled)::after {
    content: '';
    position: absolute;
    top: -25%;
    left: -25%;
    height: 150%;
    width: 150%;
    border-radius: 50%;
  }

  &:not(.unstyled):hover {
    svg {
      opacity: 1;
    }

    &::before {
      transform: none;
    }
  }

  :global(.keys) &:not(.unstyled):focus {
    outline: 2px solid var(--color-focus-outline);
    transition: none;

    svg {
      opacity: 1;
    }

    &::before {
      opacity: 0;
    }

    &.white {
      outline: none;

      path,
      circle {
        fill: #fff;
      }

      &::before {
        transform: none;
        opacity: 1;
        background-color: var(--color-focus-outline);
      }
    }
  }

  &.small {
    height: 32px;
    width: 32px;
    min-width: 32px;

    svg {
      height: 3.5px;
    }
  }
}

.popup {
  padding: 5px;
  position: relative;
  border-radius: var(--border-radius-large);
  background-color: white;
  overflow: hidden;

  hr {
    width: calc(100% + 10px);
    margin: 5px -5px;
  }
}
