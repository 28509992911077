@use '~Sass/mixins';

.container {
  width: 100%;
  max-width: 600px;
  container-type: inline-size;
}

.uploader {
  @include mixins.focusable;
  @include mixins.dashedBorder;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 100%;
  text-align: center;
  transition: background-color 0.4s var(--ease);

  &.is_dragged_over {
    @include mixins.dashedBorder(#4e9c6e);
    background-color: #fff;
  }

  @container (max-width: 500px) {
    flex-direction: column;
  }
}

.uploader__button {
  @include mixins.button('grey', 'medium');
  position: relative;
  margin-right: 10px;
  transition: background-color 0.3s var(--ease);

  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }

  :global(.keys) & input:focus + .button__focus {
    opacity: 1;
  }
}

.button__focus {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: var(--border-radius-large);
  border: 2px solid var(--color-focus-outline);
  border-radius: var(--border-radius-small);
  pointer-events: none;
  opacity: 0;
}

.accepts {
  padding: 0 0 0 20px;
  margin-left: 20px;
  border-left: 1px solid #bfbfbf;
  color: var(--color-text-light);
  font-size: 0.875em;

  @container (max-width: 500px) {
    margin-left: 0;
    padding-left: 0;
    margin-top: 20px;
    border-left: 0;
  }
}

.uploader__cancel {
  @include mixins.button('subtle', 'small');
  position: absolute;
  top: 10px;
  right: 10px;

  svg {
    height: 12px;
    width: 12px;
  }
}

.preview {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.preview__image_container {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-color: #fff;
  box-shadow: var(--shadow1);
  border-radius: var(--border-radius-large);
}

.preview__image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;

  img {
    width: 100%;
    height: auto;
    min-width: 0px;
    min-height: 0px;
    max-height: 70px;
    object-fit: contain;
  }
}

.preview__buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-left: 20px;
}

.preview__button {
  &:first-child {
    @include mixins.button('algae', 'medium');

    svg {
      height: 15px;
      width: 15px;
    }
  }
  &:last-child {
    @include mixins.button('grey', 'medium');

    svg {
      height: 15px;
      width: 15px;
    }
  }
}

.loader {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: var(--border-radius-large);
}

.success {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 100px;
  background-color: #fff;
  opacity: 0;
  transform: scale(0);
  transition: all 0.4s var(--ease);

  &.is_visible {
    opacity: 1;
    transform: none;
  }

  svg {
    height: 20px;
    width: 20px;

    path {
      fill: var(--color-green);
    }
  }
}

.error {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 15px;
  margin-bottom: 15px;
  background-color: var(--color-light-terracotta);
  border-radius: var(--border-radius-large);
  color: var(--color-terracotta);
  line-height: 1.35;
}

.error__close {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1em;
  width: 1em;
  margin-left: 20px;

  svg {
    height: 12px;
    width: 12px;

    path {
      fill: var(--color-terracotta);
    }
  }
}

.current {
  display: flex;
  align-items: center;
  gap: 20px;
}

.current__image_container {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-color: #fff;
  box-shadow: var(--shadow1);
  border-radius: var(--border-radius-large);
}

.current__image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;

  img {
    width: 100%;
    height: auto;
    min-width: 0px;
    min-height: 0px;
    max-height: 70px;
    object-fit: contain;
  }
}

.current__actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.current__action {
  @include mixins.button('grey', 'medium');
}
