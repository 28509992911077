.loading_button__text {
  display: flex;
  height: 36px;
  flex-direction: column;
  overflow: hidden;
}

.loading_button__text_inner {
  height: 300%;
  transition: transform 0.3s var(--ease);

  .loading & {
    transform: translateY(-33.333%);
    transition: transform 0.3s var(--ease);
  }
  .success & {
    transform: translateY(-66.666%);
    transition: transform 0.3s var(--ease);
  }
}

.loading_button__message {
  display: flex;
  align-items: center;
  height: 36px;
  opacity: 0;
  transform: scale(0.9);
  transition: all 0.3s var(--ease);

  &:nth-child(1) {
    opacity: 1;
    transform: none;
  }

  .loading &:nth-child(1),
  .success &:nth-child(1) {
    opacity: 0;
    transform: scale(0.75);
  }

  .loading &:nth-child(2) {
    opacity: 1;
    transform: none;
  }
  .success &:nth-child(3) {
    opacity: 1;
    transform: none;
  }
}
