@use '~Sass/mixins';

.container {
  --height: 35px;
  --border-radius: var(--border-radius-small);
  position: relative;
  display: inline-block;
  vertical-align: middle;
  height: calc(var(--height) + 10px);
  padding: 5px 0;
  width: 100%;
  overflow: hidden;
  user-select: none;
  touch-action: pan-x;
  outline: none;
  transition: all 0.3s var(--ease);
  border-radius: var(--border-radius);

  :global(.keys) &:focus {
    box-shadow:
      0 0 0 2px var(--color-focus-outline),
      0 0 0 3px #fff;
  }
}

.inner {
  position: relative;
  z-index: 1;
  display: inline-flex;
  justify-content: stretch;
  align-items: stretch;
  height: 100%;
  min-width: 100%;
  padding: 0 5px;

  .animate & {
    transition: all 0.3s var(--ease);
  }
}

.button {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 12px;
  font-size: 15px;
  line-height: 1;
  color: var(--color-text);
  transition: all 0.2s var(--ease);
  white-space: nowrap;
  border-radius: var(--border-radius);

  :global(.keys) &:focus {
    background-color: rgba(165, 99, 251, 0.5);
    border: none;
    outline: none;
  }

  svg {
    path {
      fill: var(--color-text);
      transition: all 0.3s var(--ease);
    }
  }

  // &.current {
  //   color: #fff;

  //   path {
  //     fill: #fff;
  //   }
  // }
}

.background {
  position: absolute;
  top: 0px;
  left: 0px;
  height: var(--height);
  background-color: #fff;
  width: 100px;
  border-radius: var(--border-radius);
  will-change: left, width;
  box-shadow:
    0px 0px 0px 1px rgba(117, 69, 69, 0.075),
    0px 0.2px 0.5px rgba(0, 0, 0, 0.028),
    0px 0.5px 1.3px rgba(0, 0, 0, 0.04),
    0px 1.2px 3px rgba(0, 0, 0, 0.052),
    0px 4px 10px rgba(0, 0, 0, 0.08);

  .animate & {
    transition: all 0.3s var(--ease);
  }
}
