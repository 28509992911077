.outer {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: auto;

  &.hidden {
    pointer-events: none;
  }
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: hsl(0, 0%, 0%, 0.35);
  opacity: 0;
  transition: all 0.25s var(--ease);
  pointer-events: none;

  &.visible {
    transition: all 0.4s var(--ease);
    opacity: 1;
    pointer-events: initial;
  }

  &.hidden {
    transition: all 0.3s var(--ease);
    opacity: 0;
    pointer-events: none;
  }
}

.container {
  position: absolute;
  z-index: 999;
  top: 100px;
  left: 50%;
  max-height: calc(100vh - 170px);
  max-width: calc(100% - 40px);
  opacity: 0;
  transform: translateX(-50%) translateY(5px) scale(0.95);
  will-change: transform, opacity;
  box-shadow: var(--shadow0);
  border-radius: var(--border-radius-large);

  &.visible {
    opacity: 1;
    transform: translateX(-50%);
    transition:
      opacity 0.3s var(--ease),
      transform 0.3s var(--ease);
  }

  &.hidden {
    transition:
      opacity 0.3s var(--ease),
      transform 0.3s var(--ease);
    opacity: 0;
    transform: translateX(-50%) scale(1.02);
  }

  @media all and (max-height: 850px) {
    top: 60px;
    max-height: calc(100vh - 80px);
  }

  @media all and (max-width: 700px) {
    top: 20px;
    max-height: calc(100vh - 30px);
  }
}

.close_button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1000;
  top: 0px;
  right: 0px;
  height: 60px;
  width: 60px;
  transition: transform 0.3s var(--ease);
  transform: scale(0.5);

  :global(.keys) &:focus {
    box-shadow:
      0 0 0 2px var(--color-focus-outline),
      0 0 0 3px #fff;
  }

  svg {
    position: relative;
    height: 14px;
    width: 14px;
    transform: scale(0.5);
    transition: all 0.4s 0.1s var(--ease);
    opacity: 0;

    path {
      fill: var(--color-text);
    }
  }

  &.visible {
    opacity: 1;
    transform: none;

    svg {
      transform: none;
      opacity: 1;
    }
  }

  &.hidden {
    transform: scale(0.5);
    opacity: 0;
  }

  @media all and (max-width: 900px) {
    height: 40px;
    width: 40px;
  }
}
