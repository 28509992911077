@use '~Sass/mixins';

.container {
  display: inline-flex;
  width: 100%;
  align-items: stretch;
  padding: 0;
  border-radius: var(--border-radius-small);
  background-color: white;
  padding: 2px;
  cursor: pointer;
  transition: all 0.3s var(--ease);
  box-shadow: 0 0 0 1px var(--color-input-border),
    0 0 0 2px var(--color-input-border-shadow);

  &:hover {
    box-shadow: 0 0 0 1px #326647, 0 0 0 4px rgba(0, 0, 0, 0.035);
  }

  input {
    border: none;
    flex-grow: 1;
    background-color: white;
    border-radius: var(--border-radius-small);
    cursor: pointer;
    transition: all 0.3s var(--ease);
    padding: 0 10px 0 0;

    :global(.keys) &:focus {
      border: 2px solid var(--color-focus-outline);
    }
  }

  button {
    @include mixins.focusable;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;

    &:hover {
      svg {
        opacity: 1;
      }
    }
  }

  svg {
    height: 20px;
    width: 20px;
    opacity: 0.75;
    transition: all 0.3s var(--ease);
  }
}
