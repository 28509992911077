.container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding-bottom: 10vh;
  background-color: var(--color-background);
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 1.2s var(--ease);

  svg {
    height: 45px;
    width: 45px;
    margin-bottom: 30px;
    transition: all 1.2s var(--ease);
    transform: scale(0.8);
  }

  .active & {
    opacity: 1;

    svg {
      transform: none;
    }
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8px;
  width: 100%;
  opacity: 0;
  transition: all 0.3s var(--ease);

  .delayed & {
    opacity: 1;
  }
}
