@use '~Sass/mixins';

.details {
  width: 280px;
  flex-shrink: 0;
  padding: 30px;
  background-color: #fff;
  border-radius: var(--border-radius-large);
  box-shadow: var(--shadow1);
}

.details_loader {
  @include mixins.content-placeholder();
  width: 280px;
  height: 319px;
  flex-shrink: 0;
  padding: 40px;
  margin-right: 60px;
  background-color: #fff;
  border-radius: var(--border-radius-large);
  box-shadow: var(--shadow1);

  @media all and (max-width: 900px) {
    padding: 30px;
    margin-right: 40px;
  }

  @media all and (max-width: 800px) {
    display: none;
  }
}

.details__title {
  @include mixins.title('h4');
  padding-bottom: 10px;

  @media all and (max-width: 600px) {
    padding-bottom: 0;
  }
}

.details__list {
  list-style-type: none;
  padding: 0;
  margin: 25px 0 0;
  font-size: 18px;
  font-weight: 400;
  
  p {
    margin: 0;
    font-size: 15px;
    font-style: italic;
    line-height: 1.35;
  }

  a{
    text-decoration: underline;
  }

  strong {
    display: inline;
    color: var(--color-text-green);

    &.x {
      display: inline-block;
      color: var(--color-terracotta);
      font-size: 1.5em;
      line-height: 10px;
      transform: translateY(3px);
    }
  }

  li + li {
    margin-top: 22px;
  }

  @media all and (max-width: 600px) {
    font-size: 16px;

    li + li {
      margin-top: 15px;
    }
  }
}

.red {
  color: var(--color-terracotta);
}

.tooltip_text {
  @include mixins.tooltip;
}
