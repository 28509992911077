.container {
  --height: var(--input-height);

  &.size_small {
    --height: 36px;
  }

  position: relative;
  display: block;
  height: var(--height);

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.select {
  padding: 0 52px 0 12px;
  height: var(--height);
  width: 100%;
  background-color: #fff;
  box-shadow:
    0 0 0 1px var(--color-input-border),
    0 0 0 2px var(--color-input-border-shadow);
  border: none;
  border-radius: var(--border-radius-small);
  outline: none;
  font-size: 16px;
  -webkit-appearance: none;
  line-height: var(--height);
  color: var(--color-text);
  transition: box-shadow 0.3s var(--ease);

  &:hover {
    box-shadow:
      0 0 0 1px var(--color-input-border-hover),
      0 0 0 2px var(--color-input-border-shadow);
  }

  &:hover:not(:disabled) + .caret {
    box-shadow: -1px 0 0 var(--color-input-border-hover);
  }

  &:focus:not(:disabled) {
    box-shadow:
      0 0 0 1px #326647,
      0 0 0 4px rgba(0, 0, 0, 0.035);
  }

  &:focus:not(:disabled) + .caret {
    box-shadow:
      -1px 0 0 #326647,
      -1px 0 0 rgba(0, 0, 0, 0.035);
  }
}

.caret {
  position: absolute;
  top: 0px;
  right: 0;
  display: inline-flex;
  height: 100%;
  width: calc(var(--height) - 9px);
  padding: 1px 0 0 0;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  border-radius: 0 var(--border-radius-small) var(--border-radius-small) 0;
  box-shadow: -1px 0 0 var(--color-input-border);
  pointer-events: none;
  transition: box-shadow 0.3s var(--ease);

  svg {
    height: 14px;
    width: 14px;
    vertical-align: middle;

    path {
      fill: var(--color-text);
    }
  }
}
