@use '~Sass/mixins';

.container {
  width: 100%;
  max-width: var(--layout-width);
}

.sources_loading {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 114px;
  padding: 30px;
  background-color: var(--color-green-grey);
  border-radius: var(--border-radius-large);
}

.sources_loading__loader {
  @include mixins.content-placeholder(var(--color-green-grey));
  height: 100%;
  width: 100%;
}

.column_titles {
  display: grid;
  grid-template-columns: 230px 150px 1fr;
  width: 100%;

  @media all and (max-width: 850px) {
    grid-template-columns: 180px 120px 1fr;
  }

  @media all and (max-width: 600px) {
    grid-template-columns: 180px 1fr 45px;
  }

  @media all and (max-width: 450px) {
    margin-top: 10px;
  }

  @media all and (max-width: 350px) {
    grid-template-columns: 150px 1fr 45px;
  }
}

.column_title {
  @include mixins.focusable;
  display: flex;
  padding: 0 0 15px;
  font-weight: bold;

  &:hover .column_title__caret {
    opacity: 1;
  }

  &:first-child {
    margin-left: 15px;
  }
}

.column_title__caret {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  margin: 1px 0 0 5px;
  border-radius: 4px;
  opacity: 0;
  transition:
    opacity 0.1s var(--ease),
    background 0.2s var(--ease),
    box-shadow 0.2s var(--ease);

  svg {
    height: 13px;
    width: 13px;
    margin-right: 0;

    path {
      fill: var(--color-text-light);
    }
  }

  &.sort_asc {
    transform: rotateX(180deg);
  }

  &.current {
    opacity: 1;
    background-color: hsl(60, 12%, 92%);
  }
}

.type {
  @media all and (max-width: 1005px) {
    display: none;
  }
}

.sources {
  position: relative;
  z-index: 2;
}

.compact {
  margin-bottom: 30px;
  background-color: #fff;
  box-shadow: var(--shadow1);
  border-radius: var(--border-radius-large);
  overflow: hidden;
}

.add_source {
  @include mixins.focusable;
  @include mixins.dashedBorder;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 60px;
  width: 100%;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  will-change: transform;
  transform-origin: center center;
  color: var(--color-text);
  border-radius: var(--border-radius-large);
  transition:
    box-shadow 0.35s var(--ease),
    background-color 0.35s var(--ease);

  @media (hover: hover) {
    &:hover {
      @include mixins.dashedBorder(#9e9e9e);
      background-color: #fff;
      box-shadow: var(--shadow1);
      z-index: 1;

      .add_source__inner {
        transform: scale(1.05);
      }
    }
  }

  svg {
    height: 20px;
    width: 20px;
    margin-right: 10px;

    path {
      fill: var(--color-text);
      transition: all 0.35s var(--ease);
    }
  }
}

.add_source__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.35s var(--ease);
  will-change: transform;
}

.empty_message {
  @include mixins.focusable;
  @include mixins.dashedBorder;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
  width: 100%;
  padding: 0 40px;
  font-size: 20px;
  cursor: pointer;
  background-color: transparent;
  transition: all 0.35s var(--ease);

  svg {
    width: 48px;
    height: auto;
    margin-right: 20px;
  }

  u {
    text-decoration: underline;
  }

  @media (hover: hover) {
    &:hover {
      background-color: #fff;
      box-shadow: var(--shadow1);

      .empty_message__inner {
        transform: scale(1.02);
      }
    }
  }

  @media all and (max-width: 1080px) {
    font-size: 16px;

    svg {
      margin-right: 15px;
    }
  }

  @media all and (max-width: 350px) {
    padding: 0 20px;
  }
}

.empty_message__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  transition: all 0.35s var(--ease);
  will-change: transform;
  text-align: center;
  line-height: 1.5;

  @media all and (max-width: 1080px) {
    flex-direction: column;
    gap: 20px;
  }
}

.no_feeds_message {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  margin-top: 40px;
  border-radius: var(--border-radius-large);
  background-color: var(--color-text);
  font-size: 20px;
  color: #fff;

  @media all and (max-width: 1080px) {
    font-size: 16px;
  }

  a {
    @include mixins.button('green', 'medium');
    margin-left: 10px;
  }

  @media all and (max-width: 1080px) {
    flex-direction: column;
    gap: 20px;
    text-align: center;
  }
}
