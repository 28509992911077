@use 'Sass/mixins';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  padding-bottom: 30px;
}

.nav {
  display: flex;
  width: 100%;
}

.nav__button {
  @include mixins.focusable;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 0 20px;
  margin: 15px 15px 0;
  position: relative;
  background-color: rgba(0, 0, 0, 0.05);
  transition: all 0.3s var(--ease);
  border-radius: 100px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.back_button {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 0.4s var(--ease);

  .loaded & {
    transform: none;
    opacity: 1;
  }

  svg {
    height: 10px;
    width: 10px;
    margin-right: 10px;
  }

  .hotkey {
    @include mixins.hotkey(true);
    font-size: 7px;
    margin-left: 15px;
    position: relative;
    z-index: 1;
  }
}

.close_button {
  margin-left: auto;
  transform: translateX(100%);
  opacity: 0;
  transition: all 0.4s var(--ease);

  .loaded & {
    transform: none;
    opacity: 1;
  }

  svg {
    height: 10px;
    width: 10px;
  }

  &:hover::before {
    background-color: rgba(0, 0, 0, 0.075);
  }

  .hotkey {
    @include mixins.hotkey;
    margin-right: 15px;
    position: relative;
    z-index: 1;
  }
}

.title {
  @include mixins.title(h1);
  margin-top: 25px;
  padding: 0 30px;
  text-align: center;
  transform: translateY(-10px);
  opacity: 0;
  transition: all 0.2s var(--ease);
  line-height: 1.35;

  &.loaded {
    transform: none;
    opacity: 1;
    transition: all 0.4s var(--ease);
  }

  @media all and (max-width: 800px) {
    margin-top: 40px;
  }

  @media all and (max-width: 450px) {
    margin-top: 30px;
  }
}

.options {
  @include mixins.center-column;
  display: flex;
  justify-content: center;
  gap: 0 50px;
  margin-top: 80px;

  @media all and (max-width: 800px) {
    gap: 0 20px;
    margin-top: 40px;
  }

  @media all and (max-width: 450px) {
    flex-direction: column;
    gap: 20px 0;
    margin-top: 30px;
  }
}

.options_column {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transform: translateY(15px);
  opacity: 0;
  transition: all 0.2s var(--ease);

  .loaded & {
    transform: none;
    opacity: 1;
    transition: all 0.4s var(--ease);

    &:nth-child(2) {
      transition-delay: 0.1s;
    }
  }
}

.option {
  @include mixins.focusable;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 175px;
  width: 300px;
  margin-bottom: 30px;
  border-radius: var(--border-radius-large);
  font-size: 22px;
  font-weight: 600;
  color: var(--color-text);
  transition: all 0.3s var(--ease);

  @media all and (max-width: 800px) {
    width: 200px;
    height: 125px;
    font-size: 18px;
  }

  @media all and (max-width: 450px) {
    width: 300px;
    margin-bottom: 20px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: var(--border-radius-large);
    background-color: var(--color-green-grey);
    transition: all 0.3s var(--ease);
  }

  svg {
    height: 20px;
    width: 20px;
    margin-right: 18px;

    path {
      fill: var(--color-text);
    }

    @media all and (max-width: 800px) {
      height: 16px;
      width: 16px;
    }
  }

  svg:last-of-type {
    height: 10px;
    width: auto;
    margin-right: 0;
    margin-left: 18px;
    transition: all 0.3s var(--ease);
  }

  .code_icon {
    width: 24px;
  }

  &:hover {
    svg:last-of-type {
      transform: translateX(5px);
    }

    &::before {
      transform: scale(1.075);
      background-color: var(--color-algae);
    }
  }
}

.option__highlight {
  &::before {
    background-color: #efefe4;
  }
}

.option__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  text-align: center;
}

.option_footer {
  width: 100%;
  text-align: center;
  line-height: 1.5;
  transition: all 0.3s var(--ease);

  @media all and (max-width: 800px) {
    font-size: 14px;
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 0;
    text-align: left;
    list-style-type: none;
  }

  li {
    position: relative;
    width: 100%;
    max-width: 260px;
    padding: 0 0 0 20px;
    margin-bottom: 20px;
    line-height: 1.5;

    @media all and (max-width: 800px) {
      max-width: 230px;
    }

    @media all and (max-width: 450px) {
      margin-bottom: 7px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 11px;
      left: 0;
      height: 4px;
      width: 4px;
      border-radius: 50%;
      background-color: var(--color-text-green);
    }
  }
}

.option:hover + .option_footer {
  transform: translateY(7px);
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  transform: translateY(15px);
  opacity: 0;
  transition: all 0.4s var(--ease);

  &.loaded {
    transform: none;
    opacity: 1;
  }

  a {
    @include mixins.button('subtle');
  }

  @media all and (max-width: 450px) {
    margin-top: 30px;
  }
}

.auth_link {
  display: flex;
  justify-content: center;
  width: 450px;
  max-width: 100%;
  margin-bottom: 50px;
}

.basic,
.advanced {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 80px;
  padding: 0 30px;
  transform: translateY(15px);
  opacity: 0;
  transition: all 0.4s var(--ease);

  &.loaded {
    transform: none;
    opacity: 1;
  }

  @media all and (max-width: 600px) {
    margin-top: 40px;
  }
}

.intro {
  @include mixins.title(h4);
  margin-bottom: 40px;
  text-align: center;
  line-height: 1.5;
  font-weight: 400;
}

.button {
  @include mixins.button('dark-green', 'large');
  font-weight: 400;
  margin-left: 10px;
  white-space: nowrap;

  svg {
    height: 10px;
    width: 10px;
    margin-left: 10px;
    margin-right: 0;
  }

  @media all and (max-width: 600px) {
    display: none;
  }
}

.hint {
  width: 100%;
  max-width: 450px;
  padding: 30px;
  border-radius: var(--border-radius-large);
  text-align: center;
  line-height: 1.5;
  color: var(--color-faded-text);
  background-color: var(--color-green-grey-dark);

  a {
    font-weight: 600;
    text-decoration: underline;
    color: var(--color-dark-green);
  }
}

.copy_text {
  width: 100%;
}

.toggle {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  gap: 30px;
}

.toggle__input {
  border-radius: var(--border-radius-large);
}

.toggle__label {
  font-size: 1.25em;
  white-space: nowrap;
}
