body {
  --font-titles: 'freight-neo-pro', sans-serif;
  --font-body: 'proxima-nova', sans-serif;
  --font-code: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;

  --color-background: #fbfaf9;
  --color-text: #0c2918;
  --color-text-light: #49534d;
  --color-text-green: #388156;
  --color-text-blue: #284765;
  --color-borders: #eee;
  --color-borders-dark: rgb(225, 225, 225);
  --color-focus-outline: RoyalBlue;
  --color-focus-outline-light: rgb(123, 156, 255);

  --color-green-grey: #f4f4f0;
  --color-green-grey-dark: #f1f1ed;
  --color-green-grey-darker: #e7e7e2;
  --color-algae: #e8ebd7;
  --color-algae-dark: #dee2c6;
  --color-algae-darker: #d5dab9;
  --color-green: #4e9c6e;
  --color-dark-green: #276841;
  --color-yellow: #ebba70;
  --color-light-yellow: #fbe8cc;
  --color-terracotta: #733229;
  --color-light-terracotta: #f0e6db;
  --color-lighter-terracotta: #f4f1ed;
  --color-lighter-blue: #ebeef0;
  --color-light-blue: #e2e6ea;
  --color-blue: #335575;

  --color-input-border: #ccccc3;
  --color-input-border-hover: #b0b0a5;
  --color-input-border-shadow: #f5f5f5;

  --shadow0: 0px 0px 0px 1px rgba(117, 69, 69, 0.075),
    0px 1.70513px 5.60776px rgba(0, 0, 0, 0.0296673),
    0px 0.951093px 4.00749px rgba(0, 0, 0, 0.0226331),
    0px 0.574125px 2.99925px rgba(0, 0, 0, 0.0200546),
    0px 0.328581px 2.07595px rgba(0, 0, 0, 0.0165785),
    0px 0.14651px 1.09256px rgba(0, 0, 0, 0.0102214);

  --shadow1: 0px 0px 0px 1px rgba(117, 69, 69, 0.05),
    0px 33px 72px rgba(92, 87, 67, 0.05),
    0px 13.7866px 30.0799px rgba(145, 132, 81, 0.036),
    0px 7.37098px 16.0821px rgba(92, 87, 67, 0.0298054),
    0px 4.13211px 9.01552px rgba(92, 87, 67, 0.025),
    0px 2.19453px 4.78807px rgba(92, 87, 67, 0.0201946),
    0px 0.913195px 1.99242px rgba(92, 87, 67, 0.0140573);

  --shadow1-hidden: 0px 0px 0px 1px rgba(117, 69, 69, 0),
    0px 33px 72px rgba(92, 87, 67, 0),
    0px 13.7866px 30.0799px rgba(145, 132, 81, 0),
    0px 7.37098px 16.0821px rgba(92, 87, 67, 0),
    0px 4.13211px 9.01552px rgba(92, 87, 67, 0),
    0px 2.19453px 4.78807px rgba(92, 87, 67, 0),
    0px 0.913195px 1.99242px rgba(92, 87, 67, 0);

  --shadow2: 0px 0px 0px 1px rgba(117, 69, 69, 0.075),
    0px 0px 0px 4px rgba(117, 69, 69, 0.01),
    0px 119px 179px rgba(92, 87, 67, 0.11),
    0px 49.7154px 74.7819px rgba(92, 87, 67, 0.079074),
    0px 26.5802px 39.982px rgba(92, 87, 67, 0.0655718),
    0px 14.9006px 22.4136px rgba(92, 87, 67, 0.055),
    0px 7.91362px 11.9037px rgba(92, 87, 67, 0.0444282),
    0px 3.29304px 4.95339px rgba(92, 87, 67, 0.030926);

  --border-radius-small: 8px;
  --border-radius-large: 12px;
  --border-radius-xlarge: 20px;
  --sidebar-width: 320px;
  --layout-width: 900px;

  --form-label-size: 0.875em;
  --input-height: 40px;

  @media all and (max-width: 1200px) {
    --sidebar-width: 270px;
  }

  --ease: cubic-bezier(0.215, 0.61, 0.355, 1);
  --ease-out-back: cubic-bezier(0.085, 1.735, 0.285, 0.995);
  --ease-out-back-subtle: cubic-bezier(0.085, 1.335, 0.285, 0.995);
}
