.container {
  position: absolute;
  bottom: 80px;
  left: 50%;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 12px 20px 12px 12px;
  border-radius: 23px;
  background-color: var(--color-text);
  opacity: 0;
  transform: translateX(-50%) translateY(150%);
  transition: all 0.3s cubic-bezier(0.36, 0, 0.66, -0.56), width 0s;
  transform-origin: bottom left;
  color: #fff;
  box-shadow: 0 0 0 2px #fff;

  &.visible {
    opacity: 1;
    transform: translateX(-50%);
    transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1),
      width 0.3s var(--ease);
  }

  @media all and (max-width: 900px) {
    bottom: 120px;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 22px;

  svg {
    height: 18px;
    width: 18px;
    position: absolute;
    transition: all 0.3s var(--ease);

    path {
      fill: #3fe082;
    }
  }

  .loader {
    transition: all 0.3s var(--ease);
  }
}

.success {
  .icon svg {
    opacity: 1;
    transform: none;
    transition-delay: 0.1s;
  }
  .loader {
    opacity: 0;
    transform: scale(0);
  }
}

.loading {
  .icon svg {
    opacity: 0;
    transform: scale(0);
  }
  .loader {
    opacity: 1;
    transform: none;
  }
}

.children {
  overflow: hidden;
  transition: all 0.3s var(--ease);
  will-change: width, margin, opacity;
  white-space: nowrap;
  line-height: 1.1;

  .success & {
    opacity: 0;
  }
}
