@use '~Sass/mixins';

.container {
  width: 100%;
  max-width: var(--layout-width);
  container-type: inline-size;
}

.inner {
  display: grid;
  gap: 70px 0;
  margin-top: 40px;

  @container (max-width: 1000px) {
    gap: 50px;
  }

  @container (max-width: 600px) {
    margin-top: 50px;
  }
}

.secrets {
  min-width: 0px;
}

.secrets__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.branding__header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .section_label {
    margin: 0;
  }
  .setting_inline {
    margin: 0;
  }
}

.add_secret_link {
  @include mixins.focusable;
  position: relative;
  padding: 0 10px;
  margin-bottom: 15px;
  color: var(--color-text-light);
  font-size: 15px;
  font-weight: normal;
  transition: all 0.3s var(--ease);

  span {
    position: relative;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: calc(100% + 16px);
    width: 100%;
    transform: translate(-50%, -50%) scale(0.9);
    opacity: 0;
    border-radius: var(--border-radius-small);
    background-color: var(--color-algae);
    transition: all 0.3s var(--ease);
    transform-origin: center center;
  }

  &:hover {
    color: var(--color-text);

    &::after {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
}

.section_label {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 600;

  @container (max-width: 600px) {
    margin-bottom: 10px;
  }
}

.secrets__empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 100%;
  border-radius: var(--border-radius-large);
  background-color: var(--color-green-grey);
  text-align: center;
}

.add_secret_button {
  @include mixins.button('grey', 'large');

  svg {
    height: 26px;
    width: 26px;
    transform: translateY(-2px);
    opacity: 0.75;
    transition: opacity 0.3s var(--ease);
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
}

.secrets__secrets {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

.secret {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 0px;
  gap: 30px;
  padding: 15px 10px 15px 20px;
  background-color: #fff;
  border-radius: var(--border-radius-large);
  box-shadow: var(--shadow1);
  overflow: hidden;
  transition: opacity 0.4s var(--ease);

  &.is_loading {
    pointer-events: none;
  }

  @container (max-width: 700px) {
    padding: 10px 5px 10px 15px;
    gap: 10px;
  }
}

.secret__loader {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: hsl(0, 100%, 100%, 0.97);
  border-radius: var(--border-radius-large);
}

.secret__secret {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 0px;

  input {
    height: 40px;
    font-size: 0.8em;
    font-family: var(--font-code);
    text-indent: 3px;
    width: 100%;
    background-color: #fff;
    box-shadow:
      0 0 0 1px var(--color-input-border-hover),
      0 0 0 4px var(--color-input-border-shadow);
  }
}

.secret__secret_inner {
  @include mixins.clippedText;
  line-height: 40px;
  font-family: var(--font-code);
  font-size: 0.875em;
}

.secret__active {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-large);
  height: 20px;
  padding: 0 8px 1px;
  margin-left: 20px;
  background-color: var(--color-dark-green);
  color: #fff;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.025em;
}

.secret__controls {
  display: flex;
  align-items: center;
  gap: 5px;
}

.secret__control {
  @include mixins.button('grey', 'small');
  padding: 0 10px;

  svg {
    margin: 0;
  }

  &::after {
    content: '';
    position: absolute;
    top: calc(50% + 0px);
    right: calc(50% - 1px);
    height: 20px;
    width: 1px;
    transform: translate(-50%, -50%) rotate(45deg) scaleY(0);
    transition: all 0.3s var(--ease);
    background-color: var(--color-text);
  }

  &.secret_is_visible {
    &::after {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
}

.tooltip {
  @include mixins.tooltip('small');
  text-align: center;
}

.secrets__placeholder {
  @include mixins.content-placeholder(var(--color-green-grey));
  height: 70px;
  width: 100%;
  border-radius: var(--border-radius-large);
}

.setting__label {
  margin: 0 0 10px;
}

.setting__description{
  margin: 15px 0;
  font-style: italic;
  color: var(--color-text-light);
  font-size: 0.9em;
}

.setting {
  margin: 30px 0;
  --form-label-size: 1em;
}

.inline {
  display: inline-flex;
  min-width: 200px;
}

.error {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 15px;
  margin-bottom: 15px;
  background-color: var(--color-light-terracotta);
  border-radius: var(--border-radius-large);
  color: var(--color-terracotta);
  line-height: 1.35;
}

.error__close {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1em;
  width: 1em;
  margin-left: 20px;

  svg {
    height: 12px;
    width: 12px;

    path {
      fill: var(--color-terracotta);
    }
  }
}

.webhook_subs {
  display: flex;
  gap: 40px;
  margin: 30px 0 0;
  --form-label-size: 1em;

  @container (max-width: 350px) {
    flex-direction: column;
    gap: 20px;
  }
}
