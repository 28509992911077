@use 'Sass/mixins';

.preview {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
  width: 100%;
  padding: 0 60px 60px;

  @media all and (max-width: 1100px) {
    padding: 0 20px 20px;
  }

  @media all and (max-width: 700px) {
    padding: 0;
  }
}

.preview__tabs {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.preview__tab {
  @include mixins.focusable;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 150px;
  transition: background 0.3s var(--ease);
  pointer-events: initial;
  background-color: rgba(244, 244, 240, 0.3);
  border-radius: var(--border-radius-small);

  &.current,
  &:hover {
    background-color: rgba(244, 244, 240, 1);

    svg {
      opacity: 1;
    }
  }

  svg {
    height: 20px;
    vertical-align: middle;
    opacity: 0.5;
    transition: opacity 0.3s var(--ease);
    margin-right: 10px;

    path {
      fill: var(--color-text);
    }
  }
}

.preview__tab + .preview__tab {
  margin-left: 10px;
}

.preview__photos {
  display: none;
  align-self: center;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  overflow: auto;
  scrollbar-width: none;
  pointer-events: initial;
  gap: 10px;
  border-radius: var(--border-radius-large);

  &.active {
    display: grid;
  }

  &::-webkit-scrollbar {
    width: 0 !important;
  }

  @media all and (max-width: 700px) {
    max-height: unset;
    max-width: 100%;
  }
}

.preview__photos_column {
  display: flex;
  flex-direction: column;
  gap: 10px;

  img,
  video {
    width: 100%;
    height: auto;
    vertical-align: middle;
    flex-shrink: 0;
  }
}

.preview__json {
  display: none;
  flex-shrink: 1;
  align-self: stretch;
  min-width: 0;
  height: 100%;
  min-height: 0;
  min-height: 160px;
  overflow: auto;
  border-radius: var(--border-radius-large);

  &.active {
    display: block;
  }
}
