@use 'Sass/mixins';

.container {
  position: fixed;
  z-index: 1000;
  width: 380px;
  bottom: 0px;
  right: 10px;
  max-height: calc(100vh - 30px);
  display: flex;
  flex-direction: column-reverse;

  @media all and (max-width: 450px) {
    width: calc(100vw - 20px);
    right: 10px;
  }
}

.message {
  position: relative;
  opacity: 0;
  height: 0;
  margin-bottom: 0px;
  line-height: 1.25;
  overflow: hidden;
  transform: scale(1);
  transform-origin: bottom right;
  box-shadow: var(--shadow2);
  border-radius: var(--border-radius-large);
  background-color: var(--color-text-blue);
  color: #fff;

  &.visible {
    margin-bottom: 10px;
    opacity: 1;
    transform: none;
    transition: all 0.4s var(--ease);

    @media all and (max-width: 450px) {
      margin-bottom: 10px;
    }
  }

  &.hidden {
    height: 0;
    margin-bottom: 0px;
    opacity: 0;
    transform: scale(1);

    @media all and (max-width: 450px) {
      margin-bottom: 0px;
    }
  }
}

.inner {
  padding: 20px 50px 20px 20px;
}

.title {
  @include mixins.title('h5');
  line-height: 1.15;
}

.title + .text {
  margin-top: 5px;
}

.text {
  button,
  a {
    color: var(--color-light-yellow);
    text-decoration: underline;
  }

  svg {
    height: 20px;
    width: 20px;
    margin-right: 7px;
    vertical-align: baseline;
    transform: translateY(2px);

    path {
      fill: #fff;
    }
  }

  l-squircle {
    margin-right: 15px;
  }
}

.close {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  opacity: 0.8;
  transition: all 0.3s var(--ease);

  &:hover {
    opacity: 1;
  }
}

.close__x {
  height: 12px;
  width: 12px;

  path {
    fill: #fff;
  }
}

.progress {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  height: 24px;
  width: 24px;

  path {
    stroke: #6d98c1;
    stroke-dasharray: 75;
    stroke-dashoffset: 0;
    fill: transparent;
    animation-name: progress;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }
}

@keyframes progress {
  to {
    stroke-dashoffset: -75;
  }
}
