@use 'Sass/mixins';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.nav {
  display: flex;
  width: 100%;
}

.nav__button {
  @include mixins.focusable;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 0 20px;
  margin: 15px 15px 0;
  position: relative;
  background-color: rgba(0, 0, 0, 0.05);
  transition: all 0.3s var(--ease);
  border-radius: 100px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.back_button {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 0.4s var(--ease);

  .loaded & {
    transform: none;
    opacity: 1;
  }

  svg {
    height: 10px;
    width: 10px;
    margin-right: 10px;
  }

  .hotkey {
    @include mixins.hotkey(true);
    font-size: 7px;
    margin-left: 15px;
    position: relative;
    z-index: 1;
  }
}

.close_button {
  margin-left: auto;
  transform: translateX(100%);
  opacity: 0;
  transition: all 0.4s var(--ease);

  .loaded & {
    transform: none;
    opacity: 1;
  }

  svg {
    height: 10px;
    width: 10px;
  }

  &:hover::before {
    background-color: rgba(0, 0, 0, 0.075);
  }

  .hotkey {
    @include mixins.hotkey;
    margin-right: 15px;
    position: relative;
    z-index: 1;
  }
}

.title {
  @include mixins.title(h1);
  margin-top: 25px;
  padding: 0 30px;
  text-align: center;
  transform: translateY(-10px);
  opacity: 0;
  transition: all 0.2s var(--ease);
  line-height: 1.35;

  &.loaded {
    transform: none;
    opacity: 1;
    transition: all 0.4s var(--ease);
  }

  @media all and (max-width: 800px) {
    margin-top: 40px;
  }
}

.subtitle {
  margin-top: 20px;
  padding: 0 30px;
  text-align: center;
  line-height: 1.35;
  transform: translateY(-10px);
  opacity: 0;
  transition: all 0.2s var(--ease);
  line-height: 1.35;
  will-change: transform, opacity;

  &.loaded {
    transform: none;
    opacity: 1;
    transition: all 0.4s var(--ease);
  }
}

.options {
  @include mixins.center-column;
  display: flex;
  justify-content: center;
  gap: 0 50px;
  margin-top: 80px;

  @media all and (max-width: 1000px) {
    gap: 0 20px;
    margin-top: 40px;
  }

  @media all and (max-width: 800px) {
    flex-direction: column;
    gap: 30px 0;
    margin-top: 30px;
  }
}

.options_column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0px;
  transform: translateY(15px);
  opacity: 0;
  transition: all 0.2s var(--ease);

  .loaded & {
    transform: none;
    opacity: 1;
    transition: all 0.4s var(--ease);

    &:nth-child(2) {
      transition-delay: 0.1s;
    }
    &:nth-child(3) {
      transition-delay: 0.2s;
    }
  }
}

.option {
  @include mixins.focusable;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 175px;
  width: 300px;
  max-width: 100%;
  margin-bottom: 30px;
  border-radius: var(--border-radius-large);
  font-size: 22px;
  font-weight: 600;
  color: var(--color-terracotta);
  transition: all 0.3s var(--ease);

  @media all and (max-width: 800px) {
    width: 200px;
    height: 125px;
    font-size: 18px;
  }

  @media all and (max-width: 800px) {
    margin-bottom: 10px;
    width: 300px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: var(--border-radius-large);
    background-color: var(--color-lighter-terracotta);
    transition: all 0.3s var(--ease);
  }

  svg {
    height: 24px;
    width: 24px;
    margin-right: 18px;

    path {
      fill: var(--color-terracotta);
    }

    @media all and (max-width: 800px) {
      height: 16px;
      width: 16px;
    }
  }

  svg:last-of-type {
    height: 10px;
    width: auto;
    margin-right: 0;
    margin-left: 18px;
    transition: all 0.3s var(--ease);
  }

  .code_icon {
    width: 24px;
  }

  &:hover {
    svg:last-of-type {
      transform: translateX(5px);
    }

    &::before {
      transform: scale(1.075);
      background-color: #f2ebe1;
    }
  }
}

.option__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  text-align: center;
}

.option_footer {
  text-align: center;
  line-height: 1.5;
  transition: all 0.3s var(--ease);
}

.option:hover + .option_footer {
  transform: translateY(7px);
}

// Sources
.sources_container {
  position: relative;
  width: 100%;
  max-width: 360px;
  height: 100%;
  margin-top: 25px;
  overflow: hidden;
  flex-grow: 1;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100px;
    aspect-ratio: 1/1;
    flex-shrink: 0;
    position: absolute;
    bottom: 0px;
    left: 0px;
    border-radius: 50%;
    transform: translateY(100%);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
    background-color: var(--color-background);
    mix-blend-mode: multiply;
    opacity: 0;
    transition: all 0.3s var(--ease);
  }

  &.has_overflow {
    &::after {
      opacity: 1;
    }
  }
}

.sources {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 5px 20px 20px;
  gap: 10px 0;
  line-height: 1.35;
  overflow: auto;
  transform: translateY(20px);
  opacity: 0;
  transition: all 0.2s var(--ease);
  will-change: transform, opacity;

  &.loaded {
    transform: none;
    opacity: 1;
    transition: all 0.4s var(--ease);
  }
}

.source_loading {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  max-width: 300px;
  padding: 30px 20px;
  border-radius: var(--border-radius-large);
  background-color: var(--color-green-grey);
  text-align: center;
  line-height: 1.5;
}

.source_loading__loader {
  margin-bottom: 20px;
}

.source_button__container {
  width: 100%;
  max-width: 300px;
}

.source_button {
  @include mixins.focusable;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 75px;
  width: 100%;
  max-width: 300px;
  padding: 0 20px;
  border-radius: var(--border-radius-large);
  background-color: var(--color-lighter-terracotta);
  font-size: 18px;

  &::after {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: var(--border-radius-large);
    background-color: var(--color-lighter-terracotta);
    transition: all 0.3s var(--ease);
  }

  &:hover {
    &::after {
      background-color: #f2ebe1;
      transform: scale(1.05);
    }

    .source_button__caret {
      transform: translateX(3px);
    }
  }
}

.source_button__caret {
  position: relative;
  z-index: 1;
  flex-shrink: 0;
  height: 9px;
  width: 5px;
  margin-left: auto;
  transition: all 0.3s var(--ease);

  path {
    fill: var(--color-terracotta);
  }
}

.source_button__type,
.source_button__text {
  position: relative;
  z-index: 1;
}

.source_button__text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.source_button__type {
  flex-shrink: 0;
  height: 20px;
  width: 20px;
  margin-right: 15px;

  svg {
    height: 100%;
    width: 100%;

    path {
      fill: var(--color-terracotta);
    }
  }
}

.source_button__label {
  @include mixins.label;
  margin-bottom: 3px;
  color: var(--color-terracotta);
}

.source_button__text {
  @include mixins.clippedText;
  margin-right: 10px;
}

.new_source_button {
  @include mixins.focusable;
  @include mixins.dashedBorder;
  flex-shrink: 0;
  height: 62px;
  width: 100%;
  max-width: 300px;
  border-radius: var(--border-radius-large);
  transition: all 0.4s var(--ease);
  box-shadow: var(--shadow1-hidden);

  &:hover {
    @include mixins.dashedBorder(#9e9e9e);
    box-shadow: var(--shadow1);
    background-color: #fff;

    .new_source_button__inner {
      transform: scale(1.05);
    }
  }

  svg {
    height: 18px;
    width: 18px;
    margin-right: 9px;
  }
}

.new_source_button__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  transition: all 0.35s var(--ease);
  will-change: transform;
}

/*
*   Name
*/
.name {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.name__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 30px;
  transform: translateY(20px);
  opacity: 0;
  transition: all 0.2s var(--ease);

  .loaded & {
    transform: none;
    opacity: 1;
    transition: all 0.4s var(--ease);
  }
}

.input {
  max-width: 300px;
  margin-top: 40px;
  text-align: center;
}

.submit_button {
  @include mixins.button('green');
  margin-top: 30px;
}

/*
*   Hashtags
*/
.hashtags {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px;
}

.hashtags__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin-top: 50px;
  transform: translateY(20px);
  opacity: 0;
  transition: all 0.2s var(--ease);

  .loaded & {
    transform: none;
    opacity: 1;
    transition: all 0.4s var(--ease);
  }
}

.hashtags_submit_button {
  @include mixins.button;
  margin-top: 30px;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  width: 100%;
}

/*
*   Recipe
*/
.recipe {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 60%;
  width: 100%;
  min-height: 150px;
  margin: auto 0 0;
  padding: 60px 20px;
  line-height: 2.5;

  @media all and (max-width: 450px) {
    padding: 40px 20px;
    min-height: 0;
  }
}

.recipe__inner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.recipe__title {
  @include mixins.label;
  width: 100%;
  color: var(--color-text-light);
  margin-bottom: 25px;
  text-align: center;

  @media all and (max-width: 500px) {
    margin-bottom: 10px;
  }
}

.ingredient {
  position: relative;
  padding: 5px 10px 5px 8px;
  margin: 0 7px;
  border-radius: var(--border-radius-small);
  background-color: var(--color-lighter-blue);
  font-size: 15px;

  svg {
    height: 20px;
    width: 20px;
    margin: 0 2px 2px 0;
    vertical-align: middle;

    path {
      fill: var(--color-blue);
    }
  }

  @media all and (max-width: 500px) {
    padding: 3px 8px 3px 6px;
    font-size: 13px;

    svg {
      height: 11px;
      width: 11px;
    }
  }
}

.ingredient__inner {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.35;
}
