@use '~Sass/mixins';

.container {
  width: 100%;
  max-width: var(--layout-width);
}

.inner {
  position: relative;
  display: flex;
  align-items: flex-start;
}

.sidebar {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-shrink: 0;
  margin-right: 60px;

  @media all and (max-width: 1100px) {
    display: none;
  }
}

.payment_failed {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  a {
    @include mixins.button;
    margin-top: 20px;
  }
}

.info {
  width: 100%;
  flex-grow: 1;
}

.info__footer {
  margin-top: 40px;

  svg {
    margin-left: 10px;
    margin-right: 0px !important;
  }
}

.info__price_plan {
  display: grid;
  margin-bottom: 45px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 40px;

  @media all and (max-width: 500px) {
    gap: 30px;
  }
}

.info__plan {
  grid-column: span 2;
  margin-bottom: 0;

  @media all and (max-width: 700px) and (min-width: 601px) {
    grid-column: span 5;
    order: 2;
  }

  @media all and (max-width: 500px) {
    grid-column: span 5;
    order: 2;
  }
}

.info__period {
  grid-column: span 2;
  margin-bottom: 0;

  @media all and (max-width: 700px) and (min-width: 601px) {
    grid-column: span 5;
    order: 3;
  }

  @media all and (max-width: 500px) {
    grid-column: span 5;
    order: 3;
  }
}

.info__price {
  margin-bottom: 0;
  font-size: 24px;
  white-space: nowrap;

  @media all and (max-width: 700px) and (min-width: 601px) {
    grid-column: span 5;
    order: 1;
  }

  @media all and (max-width: 500px) {
    grid-column: span 5;
    order: 1;
  }
}

.info__price_period {
  display: inline-flex;
  align-items: center;
  margin-left: 3px;
  margin-top: 14px;
  font-size: 0.66em;
}

.info__price_suffix {
    display: flex;
    align-items: center;
    margin-left: 3px;
    margin-top: 3px;
    font-size: 0.66em;
}

.info__payment_method {
  margin-bottom: 0;
  grid-column: span 4;

  @media all and (max-width: 700px) and (min-width: 601px) {
    order: 4;
    grid-column: span 5;
  }

  @media all and (max-width: 500px) {
    grid-column: span 5;
    order: 4;
  }
}


.info__total {
  display: flex;
  height: 90px;
  align-items: center;
  border-top: 2px solid #e5e5e5;
  border-bottom: 2px solid #e5e5e5;
  line-height: 1;

  @media all and (max-width: 800px) {
    height: auto;
    padding: 20px 0;
    flex-wrap: wrap;
    align-items: center;
  }

  @media all and (max-width: 600px) {
    height: 70px;
  }

  @media all and (max-width: 480px) {
    height: auto;
    padding: 20px 0;
    flex-wrap: wrap;
    align-items: center;
  }
}

.info__total_title {
  white-space: nowrap;
}

.info__total_amount {
  margin-left: 30px;
  margin-right: 10px;
  font-size: 24px;
  line-height: 1;

  @media all and (max-width: 600px) {
    margin-left: 15px;
  }
}

.info__note {
  display: flex;
  height: 90px;
  align-items: center;
  border-top: 2px solid #e5e5e5;
  border-bottom: 2px solid #e5e5e5;
  line-height: 1;
  white-space: pre-wrap; // Fix for flexbox removing spaces
  font-size: 18px;

  @media all and (max-width: 500px) {
    height: auto;
    padding: 20px 0;
    font-size: 16px;
  }
}

.info__note_inner {
  max-width: 450px;
  line-height: 1.35;

  strong {
    color: var(--color-blue);
  }
}

.price_loader {
  display: flex;
  height: 20px;
  width: 20px;
}

.inline_price_loader{
  display: inline-flex;
  align-items: center;
  height: 20px;
  width: 20px;
  margin-left: 10px;
  transform: translateY(3px);
}

.price_notes {
  display: flex;
  align-items: center;

  @media all and (max-width: 800px) and (min-width: 601px), (max-width: 480px) {
    width: 100%;
    margin-top: 15px;
  }
}

.price_note {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  height: 24px;
  padding: 0 12px 1px;
  margin-left: 20px;
  background-color: rgb(224, 232, 239);
  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;

  @media all and (max-width: 800px) and (min-width: 601px) {
    margin-left: 0;
    margin-right: 20px;
  }

  @media all and (max-width: 600px) {
    height: 20px;
    font-size: 11px;
    padding: 0 8px;
    margin-left: 10px;
  }

  @media all and (max-width: 480px) {
    margin-left: 0;
    margin-right: 10px;
  }
}

.price_note_yellow {
  background-color: #f5d6a7;
}

.info_loader {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-template-areas:
    'a a a b'
    'c c c c'
    'd d . .'
    'e . . .';
  gap: 35px 50px;
  width: 100%;
  flex-grow: 1;

  div {
    @include mixins.content-placeholder;
    height: 70px;
    width: 100%;

    &:nth-child(1) {
      grid-area: a;
    }
    &:nth-child(2) {
      grid-area: b;
    }
    &:nth-child(3) {
      grid-area: c;
    }
    &:nth-child(4) {
      grid-area: d;
    }
    &:nth-child(5) {
      grid-area: e;
    }
  }

  @media all and (max-width: 500px) {
    gap: 30px;
    grid-template-areas:
      'a a . .'
      'b b b b'
      'c c c c'
      'd d d d'
      'e e e e';
  }
}

.tooltip_text {
  @include mixins.tooltip;
}

.cancel_downgrade {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--color-background);
}

.cancel_downgrade__text {
  max-width: 500px;
  margin-bottom: 30px;
  min-width: 0;
  min-width: 0;
}

.cancel_downgrade__title {
  @include mixins.title('h2');
  margin-bottom: 20px;
  min-width: 0;
  min-width: 0;
}

.cancel_downgrade__copy {
  line-height: 1.35;
  font-size: 18px;
  min-width: 0;
  min-width: 0;

  @media all and (max-width: 850px) {
    font-size: 16px;
  }
}

.success {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0 20px;
  background-color: rgba(255, 255, 255, 0);
  pointer-events: none;

  &.visible {
    background-color: rgba(255, 255, 255, 1);
    pointer-events: initial;
    z-index: 100;
  }
}

.success__inner {
  position: relative;
  width: 100%;
  max-width: 480px;
  text-align: center;

  @media all and (min-height: 600px) {
    margin-bottom: 100px;
  }
}

.success__processing {
  position: absolute;
  top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap:30px;
  width: 100%;
  text-align: center;
  opacity: 0;
  font-size: 30px;

  .upgrade & {
    transform: scale(0.98) translateY(-10px);
  }

  .downgrade & {
    transform: scale(0.98) translateY(10px);
  }

  .visible & {
    transform: none;
    opacity: 1;
    transition: all 0.6s 0.1s var(--ease);
  }

  .successful & {
    opacity: 0;
    transform: scale(0.98) translateY(-10px);
    transition: all 0.2s var(--ease);
  }
}

.success__content {
  min-height: 224px;
  transform: scale(0.98) translateY(10px);
  opacity: 0;
  transition: all 0.6s 0.3s var(--ease);

  .visible.successful & {
    transform: none;
    opacity: 1;
  }
}

.success__title {
  @include mixins.title('h2');
  line-height: 1.25;
  margin-top: 40px;

  em {
    font-style: normal;
    color: var(--color-text-green);
  }
}

.success__text {
  margin: 30px 0;
  font-size: 20px;
  line-height: 1.5;

  strong {
    font-weight: 600;
  }

  @media all and (max-width: 600px) {
    font-size: 18px;
  }

  @media all and (max-width: 380px) {
    font-size: 16px;
  }
}

.success__button {
  margin-top: 20px;

  svg {
    height: 10px !important;
    width: 10px !important;
    margin-right: 0 !important;
    margin-left: 10px !important;
    margin-bottom: -1px;
  }
}
