@use '~Sass/mixins';

.container {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  width: 100%;
  height: 100vh;
  max-width: var(--layout-width);
  padding: 40px 0;
}

.placeholder {
  @include mixins.content-placeholder(var(--color-green-grey));
  height: 100%;
  width: 100%;
}
