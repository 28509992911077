@use 'Sass/mixins';

.container {
  @include mixins.focusable;
  position: relative;
  display: inline-block;
  padding-bottom: 1px;
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  text-transform: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  transition: all 0.3s var(--ease);
  white-space: nowrap;

  &:hover,
  &.open,
  :global(.keys) &:focus {
    .icon {
      background-color: var(--color-text);
    }
  }
}

.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 14px;
  width: 14px;
  margin: -2px 0 0 7px;
  border-radius: 50%;
  font-size: 10px;
  font-weight: 600;
  line-height: 1;
  background-color: var(--color-blue);
  vertical-align: middle;
  transition: all 0.3s var(--ease);
  color: #fff;
}

.disabled {
  cursor: default;
}
